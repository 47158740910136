import React, { useState, useRef, useMemo } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { Tooltip } from '@ui-kitten/components';
import { ezTheme } from 'src/core/theme';

const TooltipCell = ({ content, showTooltipOnRight }) => {
  const [visible, setVisible] = useState(false);
  const contentRef = useRef(null);

  const isOverflowing = useMemo(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      return contentElement.scrollWidth > contentElement.clientWidth;
    }
    return false;
  }, [contentRef.current]);

  const renderToggleButton = () => (
    <Pressable onPress={() => isOverflowing && setVisible(true)}>
      <Text numberOfLines={1} ref={contentRef}>
        {content}
      </Text>
    </Pressable>
  );

  return (
    <View style={styles.container}>
      <Tooltip
        visible={visible}
        anchor={renderToggleButton}
        onBackdropPress={() => setVisible(false)}
        placement={showTooltipOnRight ? 'right' : 'bottom'}
        style={styles.tooltip}>
        <Text style={styles.tooltipText} selectable>
          {content}
        </Text>
      </Tooltip>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    position: 'relative',
  },
  tooltip: {
    maxWidth: 300,
    backgroundColor: ezTheme.black,
    borderRadius: 5,
    padding: 6,
  },
  tooltipText: {
    color: ezTheme.white,
  },
});

export default TooltipCell;
