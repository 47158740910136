import { AU_POSTCODE_LENGTH, SHIPPING_CHANNEL_CODE } from '@ezom/library/lib/cjs/constants';
import * as Yup from 'yup';
import { t } from 'i18next';

const POSTCODE_PATTERNS = {
  AU: /^(0[8-9]|[1-2][0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])[0-9]{2}$/,
};

// checks if export info is required for the chose courier
const exportInfoTester = (courierList) => (value, testContext) => {
  const courierConfig = courierList.find(
    (courier) => courier.logistics_product_code === testContext.from[2]?.value?.courier,
  );
  if (courierConfig?.requireExportInfo) {
    return !!value;
  }
  return true;
};

export const validatePoscode = (country, schema) => {
  if (country === 'AU') {
    return schema
      .length(AU_POSTCODE_LENGTH, t('validation.validlengthAUPostcode', {
        country: country,
        length: AU_POSTCODE_LENGTH 
      }))
      .matches(POSTCODE_PATTERNS.AU, t('validation.australianPostcodeState'));
  }
  return schema.max(12, t('validation.maxLength', { count: 12 }));
}

export const getOutboundValidationSchema = () => Yup.object({
  outboundConsignments: Yup.array()
    .min(1)
    .of(
      Yup.object({
        warehouse: Yup.string().required(t('validation.outboundForm.warehouseIsRequired')),
        courier: Yup.string().required(t('validation.required')),
        skus: Yup.array()
        .min(1, t('validation.skus.minOneRequired'))
        .of(
          Yup.object({
            sku: Yup.string().required(t('validation.required')),
            qty: Yup.number().required(t('validation.required')).min((1, t('validation.skus.qtyMinOne'))),
          }),
        ),
      }),
    ),
  refNo: Yup.string().required(t('validation.required')),
  sales_no: Yup.string().nullable(),
  note: Yup.string().nullable(),
  address: Yup.object({
    country: Yup.string().required(t('validation.required')),
    name: Yup.string().required(t('validation.required')),
    state: Yup.string().required(t('validation.required')),
    city: Yup.string().required(t('validation.required')),
    phone: Yup.string().nullable(),
    email: Yup.string().email(t('validation.outboundForm.invalidEmail')).nullable(),
    post_code: Yup.string()
      .required(t('validation.required'))
      .when('country', validatePoscode),
    company: Yup.string().nullable(),
    street: Yup.string().required(t('validation.required')),
  }),
});

export const getShipmentValidationSchema = (courierList) =>
  Yup.object({
    ref_no: Yup.string()
      .required(t('validation.required'))
      .max(24, t('validation.maxLength', { count: 24 })),
    sales_no: Yup.string(),
    note: Yup.string()
      .nullable()
      .max(250, t('validation.maxLength', { count: 250 })),
    shipment: Yup.object({
      courier: Yup.string().required(t('validation.required')),
      serviceCode: Yup.string().when('courier', {
        is: SHIPPING_CHANNEL_CODE.BONDS,
        then: Yup.string().required(t('validation.required')),
      }),
      vehicleType: Yup.string().when('courier', {
        is: SHIPPING_CHANNEL_CODE.BONDS,
        then: Yup.string().required(t('validation.required')),
      }),
      deliveryWindowEnabled: Yup.boolean().required(),
      deliveryWindowStart: Yup.string().when('deliveryWindowEnabled', {
        is: true,
        then: Yup.string().required(t('validation.required')),
      }),
      deliveryWindowEnd: Yup.string().when('deliveryWindowEnabled', {
        is: true,
        then: Yup.string().required(t('validation.required')),
      }),
      dangerousGoods: Yup.boolean().required(),
      handUnload: Yup.boolean().required(),
      tailgate: Yup.boolean().required(),
      items: Yup.array()
        .min(1, t('validation.minItems', { count: 1 }))
        .of(
          Yup.object({
            description: Yup.string().required(t('validation.required')),
            commodity: Yup.string(),
            qty: Yup.number()
              .integer(t('validation.integerOnly'))
              .min(1, t('validation.tooSmall'))
              .required(t('validation.required')),
            weight: Yup.number()
              .min(0.1, t('validation.tooSmall'))
              .max(Number.MAX_SAFE_INTEGER, t('validation.tooLarge'))
              .required(t('validation.required')),
            length: Yup.number()
              .min(0.1, t('validation.tooSmall'))
              .max(Number.MAX_SAFE_INTEGER, t('validation.tooLarge'))
              .required(t('validation.required')),
            width: Yup.number()
              .min(0.1, t('validation.tooSmall'))
              .max(Number.MAX_SAFE_INTEGER, t('validation.tooLarge'))
              .required(t('validation.required')),
            height: Yup.number()
              .min(0.1, t('validation.tooSmall'))
              .max(Number.MAX_SAFE_INTEGER, t('validation.tooLarge'))
              .required(t('validation.required')),
            exportInfo: Yup.array().of(
              Yup.object({
                declare: Yup.string().test('declare', t('validation.required'), exportInfoTester(courierList)),
                hscode: Yup.string().test('declareHscode', t('validation.required'), exportInfoTester(courierList)),
                qty: Yup.number()
                  .integer(t('validation.integerOnly'))
                  .min(1, t('validation.tooSmall'))
                  .max(Number.MAX_SAFE_INTEGER, t('validation.tooLarge'))
                  .test('declareQty', t('validation.required'), exportInfoTester),
                declareValue: Yup.number()
                  .min(0, t('validation.tooSmall'))
                  .max(Number.MAX_SAFE_INTEGER, t('validation.tooLarge'))
                  .test('declareValue', t('validation.required'), exportInfoTester),
                countryOfOrigin: Yup.string()
                  .length(2, t('validation.countryOriginLength'))
                  .test('countryOfOrigin', t('validation.required'), exportInfoTester),
                weight: Yup.number()
                  .min(0.01, t('validation.tooSmall'))
                  .max(Number.MAX_SAFE_INTEGER, t('validation.tooLarge'))
                  .test('declareWeight', t('validation.required'), exportInfoTester),
              }),
            ),
          }),
        ),
    }).required(),
    pickupAddress: Yup.object({
      country: Yup.string().required(t('validation.required')).max(50),
      name: Yup.string().required(t('validation.required')).max(35),
      state: Yup.string().required(t('validation.required')).max(50),
      city: Yup.string().required(t('validation.required')).max(50),
      phone: Yup.string().max(20),
      email: Yup.string().email(t('validation.invalidEmail')).max(50),
      house_number: Yup.string().nullable().max(20),
      post_code: Yup.string()
        .required(t('validation.required'))
        .when('country', validatePoscode),
      company: Yup.string().nullable().max(30),
      street: Yup.string().required(t('validation.required')).max(50),
    }),
    receiverAddress: Yup.object({
      country: Yup.string().required(t('validation.required')).max(50),
      name: Yup.string().required(t('validation.required')).max(35),
      state: Yup.string().required(t('validation.required')).max(50),
      city: Yup.string().required(t('validation.required')).max(50),
      phone: Yup.string().max(20),
      email: Yup.string().email(t('validation.invalidEmail')).max(50),
      house_number: Yup.string().nullable().max(20),
      post_code: Yup.string()
        .required(t('validation.required'))
        .when('country', validatePoscode),
      company: Yup.string().nullable().max(30),
      street: Yup.string().required(t('validation.required')).max(50),
    }),
  });
