import * as React from 'react';
import { StyleSheet } from 'react-native';
import {
  Button,
  Card,
  Divider,
  Icon,
  Layout,
  Radio,
  RadioGroup,
  Text,
} from '@ui-kitten/components';
import GroupBarChartCard from '../../components/GroupBarChartCard';
import { useIsMobile } from '../../core/responsive.utils';
import Background from '../../components/Background';
import { observer } from 'mobx-react';
import { salesStatisticsStore } from '../../store';
import { UIStatusWrapper } from '../../components/ui-status';
import { Banner } from 'react-native-paper';
import { paperNativeTheme, uiKittenTheme } from '../../core/theme';
import { LineItemWithoutSkuWarningModal } from '../../components/LineItemWithoutSkuWarningModal';
import { TIME_INTERVAL } from '../../store/SalesStatisticsStore';
import Slider from '@react-native-community/slider';
import Autocomplete from '../../components/Autocomplete';
import Select from '../../components/Select';
import { useTranslation } from 'react-i18next';

export default observer(({ navigation }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <Background fullWidth={true}>
      <UIStatusWrapper
        status={{
          error: salesStatisticsStore.error,
          indeterminate: salesStatisticsStore.loading,
        }}>
        <Banner
          style={{ backgroundColor: uiKittenTheme['color-primary-100'] }}
          visible={salesStatisticsStore.lineItemsWithoutSkuBannerVisible}
          actions={
            salesStatisticsStore.lineItemsWithoutSkuModalOpen
              ? []
              : [
                  {
                    label: t('analytics.sales.learnMore'),
                    onPress: salesStatisticsStore.openLineItemsWithoutSkuModal,
                  },
                ]
          }
          icon={() => (
            <Icon
              fill={paperNativeTheme.colors.danger}
              name="alert-triangle-outline"
              status="danger"
              style={{ width: 20, height: 20 }}
            />
          )}>
          {t('analytics.sales.thereAreProductsWithoutSku')}
        </Banner>
        <LineItemWithoutSkuWarningModal
          visible={salesStatisticsStore.lineItemsWithoutSkuModalOpen}
          items={salesStatisticsStore.lineItemsWithoutSku}
          onBackdropPress={salesStatisticsStore.closeLineItemsWithoutSkuModal}
        />
        <Layout style={styles.container} level="1">
          <Card style={styles.card} status="danger" onPress={() => {}}>
            <Layout style={styles.textContainer} level="1">
              <Text category="h4" status="danger">
                {
                  Object.keys(
                    salesStatisticsStore.storeSkuStock.ordersDontHaveAvailableAndOnwayStock || {},
                  ).length
                }
              </Text>
            </Layout>
            <Text>{t('analytics.sales.ordersNoOnWayStock')}</Text>
          </Card>

          <Card style={styles.card} status="warning" onPress={() => {}}>
            <Layout style={styles.textContainer} level="1">
              <Text category="h4" status="warning">
                {
                  Object.keys(salesStatisticsStore.storeSkuStock.ordersDontHaveAvailableStock || {})
                    .length
                }
              </Text>
            </Layout>
            <Text>{t('analytics.sales.ordersNoAvailableStock')}</Text>
          </Card>

          <Card style={styles.card} status="warning" onPress={() => {}}>
            <Layout style={styles.textContainer} level="1">
              <Text category="h4" status="warning">
                {salesStatisticsStore.productsOfStockLessEqualZero.length}
              </Text>
            </Layout>
            <Text>{t('analytics.sales.productsStockLessEqualZero')}</Text>
          </Card>

          <Card style={styles.card} status="success" onPress={() => {}}>
            <Layout style={styles.textContainer} level="1">
              <Text category="h4" status="success">
                {salesStatisticsStore.productsOfStockLessGreater15.length}
              </Text>
            </Layout>
            <Text>{t('analytics.sales.productsStockGreaterEqualFifteen')}</Text>
          </Card>
        </Layout>
        <Divider style={{ marginVertical: 10 }} />
        <Layout style={isMobile ? styles.mobileContainer : styles.container} level="1">
          <GroupBarChartCard
            style={{ marginVertical: 10, marginLeft: 20, paddingHorizontal: 10 }}
            key="sales-forecast"
            header={() => (
              <Layout>
                <Layout
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Text category="S1" style={styles.header} onPress={() => {}}>
                    {t('analytics.sales.salesForecast')}
                  </Text>
                  <Select
                    size="small"
                    value={salesStatisticsStore.selectedStoreForSales}
                    options={salesStatisticsStore.storeOptions}
                    onChange={(option) => {
                      salesStatisticsStore.setSelectedStoreForSales(option.key);
                    }}
                    style={{ width: '40%', minWidth: 230 }}
                  />
                  <Button
                    appearance="ghost"
                    size="large"
                    accessoryLeft={(props) => <Icon name="download-outline" {...props} />}
                    onPress={salesStatisticsStore.exportSalesForecast}
                  />
                </Layout>
                <Divider />
                <Layout style={{ flexDirection: 'row', marginHorizontal: 5 }}>
                  <RadioGroup
                    selectedIndex={TIME_INTERVAL.indexOf(salesStatisticsStore.salesInterval)}
                    onChange={(index) => {
                      salesStatisticsStore.setSalesInterval(TIME_INTERVAL[index]);
                    }}
                    style={{ flexDirection: 'row' }}>
                    {TIME_INTERVAL.map((val) => (
                      <Radio style={styles.radio} status="primary" key={val}>
                        {val}
                      </Radio>
                    ))}
                  </RadioGroup>
                  <Slider
                    minimumValue={1}
                    maximumValue={24}
                    step={1}
                    value={salesStatisticsStore.salesForecastPeriod}
                    onSlidingComplete={(val) => {
                      salesStatisticsStore.setSalesForecastPeriod(val);
                    }}
                    minimumTrackTintColor={paperNativeTheme.colors.primary}
                  />
                </Layout>

                <Layout style={{ alignItems: 'center' }}>
                  <Text appearance="hint">
                    {salesStatisticsStore.salesForecastPeriod}-{salesStatisticsStore.salesInterval}{' '}
                    {t('analytics.sales.forecast')}
                  </Text>
                </Layout>
              </Layout>
            )}
            data={salesStatisticsStore.salesForecast}
            x="date"
            y="value"
            scale={{ x: 'time' }}
            colorScale={[uiKittenTheme['color-info-300'], uiKittenTheme['color-info-500']]}
          />
          <GroupBarChartCard
            style={{ marginVertical: 10, marginRight: 20, paddingHorizontal: 10 }}
            key="product-forecast"
            header={() => (
              <Layout>
                <Layout
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingHorizontal: 5,
                  }}>
                  <Text category="S1" style={styles.header} onPress={() => {}}>
                    {t('analytics.sales.productSalesForecast')}
                  </Text>
                  <Select
                    size="small"
                    value={salesStatisticsStore.selectedStoreForProduct}
                    options={salesStatisticsStore.storeOptions}
                    onChange={(option) => {
                      salesStatisticsStore.setSelectedStoreForProduct(option.key);
                    }}
                    style={{ width: '25%', minWidth: 200 }}
                  />
                  <Autocomplete
                    size="small"
                    style={{ width: '20%', minWidth: 180 }}
                    defaultValue={salesStatisticsStore.selectedProduct}
                    options={Object.keys(salesStatisticsStore.storeProductSalesForecast || {})}
                    placeholder={t('analytics.sales.productSku')}
                    onBlur={() => {}}
                    onSelect={(value) => {
                      if (
                        Object.keys(salesStatisticsStore.storeProductSalesForecast || {}).indexOf(
                          value,
                        ) > -1
                      ) {
                        salesStatisticsStore.setSelectedProduct(value);
                      }
                    }}
                  />
                  <Button
                    appearance="ghost"
                    size="large"
                    accessoryLeft={(props) => <Icon name="download-outline" {...props} />}
                    onPress={salesStatisticsStore.exportProductSalesForecast}
                  />
                </Layout>
                <Divider />
                <Layout style={{ flexDirection: 'row', marginHorizontal: 5 }}>
                  <RadioGroup
                    selectedIndex={TIME_INTERVAL.indexOf(salesStatisticsStore.productSalesInterval)}
                    onChange={(index) => {
                      salesStatisticsStore.setProductSalesInterval(TIME_INTERVAL[index]);
                    }}
                    style={{ flexDirection: 'row' }}>
                    {TIME_INTERVAL.map((val) => (
                      <Radio style={styles.radio} status="primary" key={val}>
                        {val}
                      </Radio>
                    ))}
                  </RadioGroup>
                  <Slider
                    minimumValue={1}
                    maximumValue={24}
                    step={1}
                    value={salesStatisticsStore.productSalesForecastPeriod}
                    onSlidingComplete={(val) => {
                      salesStatisticsStore.setProductSalesForecastPeriod(val);
                    }}
                    minimumTrackTintColor={paperNativeTheme.colors.primary}
                  />
                </Layout>
                <Layout style={{ alignItems: 'center' }}>
                  <Text appearance="hint">
                    {salesStatisticsStore.productSalesForecastPeriod}-
                    {salesStatisticsStore.productSalesInterval} {t('analytics.sales.forecast')}
                  </Text>
                </Layout>
              </Layout>
            )}
            data={salesStatisticsStore.productSalesForecast}
            x="date"
            y="value"
            scale={{ x: 'time' }}
            colorScale={[uiKittenTheme['color-info-300'], uiKittenTheme['color-info-500']]}
          />
        </Layout>
      </UIStatusWrapper>
    </Background>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card: {
    flex: 1,
    margin: 2,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerControl: {
    marginHorizontal: 2,
  },
  header: {
    textAlign: 'center',
    paddingVertical: 4,
  },
});
