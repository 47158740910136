import * as React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useIsMobile } from '../core/responsive.utils';
import {
  DashboardScreen,
  OrderDetailScreen,
  OrderListScreen,
  UserAccountBalanceScreen,
  OrgProfileScreen,
} from './index';
import OrderDispatchScreen from './order/OrderDispatchScreen';
import NavigationHeaderRight from '../components/NavigationHeaderRight';
import { SecondaryTopBar } from '../components/SecondaryTopBar';
import { paperNativeTheme } from 'src/core/theme';
import InventoryAnalytics from './analytics/InventoryAnalytics';
import SalesAnalytics from './analytics/SalesAnalytics';
import SkuList from './inventory/SkuList';
import InboundList from './inventory/InboundList';
import InboundDetail from './inventory/InboundDetail';
import OutboundList from './inventory/OutboundList';
import OutboundDetail from './inventory/OutboundDetail';
import ShipmentOrderDetail from './shipments/ShipmentOrderDetail';
import SkuDetail from './inventory/SkuDetail';
import {
  Drawer as UIKittenDrawer,
  DrawerItem,
  DrawerGroup,
  IndexPath,
} from '@ui-kitten/components';
import { IconButton } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import SkuMappingList from './inventory/SkuMappingList';
import FulfillmentTable from '../components/FulfillmentTable';
import { AddStoreScreen } from './AddStoreScreen';
import RestockList from './inventory/RestockList';
import { OrgListScreen } from './account-manager/OrgListScreen';
import TransferList from './inventory/TransferList';
import TransferDetail from './inventory/TransferDetail';
import CourierShipmentList from './shipments/CourierShipmentList';
import PickingTaskList from './inventory/PickingTaskList';
import PickingTaskDetail from './inventory/PickingTaskDetail';
import {
  playSuccessSoundAsync,
  useIsAccountManager,
  useIsEzomUser,
  useIsWmsManager,
  useOnBarcodeScanned,
} from '../core/utils/utils';
import { observer } from 'mobx-react';
import { isPickingTaskId } from '@ezom/library/lib/cjs/id';
import { pickingTaskTableStore, warehouseAccountStore } from '../store';
import PackagingMaterialList from './inventory/PackagingMaterialList';
import OcPackingScreen from './inventory/OcPackingScreen';
import { EditWmsAccountScreen } from './auth/EditWmsAccountScreen';
import NewShipment from './shipments/NewShipment';
import AddressBookList from './shipments/AddressBookList';
import { View } from 'react-native';
import TrackingShipments from './shipments/TrackingShipments';
import TrackingShipmentResults from './shipments/TrackingShipmentResults';
import { useTranslation } from 'react-i18next';

export const ScreenWrapper = ({ children }) => {
  return (
    <View style={{ flex: 1 }}>
      <SecondaryTopBar />
      {children}
    </View>
  );
};

const Drawer = createDrawerNavigator();

const getActiveRouteName = (route) => {
  if (!route) return undefined;

  if (route.state) {
    return getActiveRouteName((route.state.routes || [])[route.state.index]);
  }

  return route.name;
};

const routeNameToSelectedIndex = (routeName) => {
  switch (routeName) {
    case 'DashboardScreen':
      return new IndexPath(0);
    case 'OrderListScreen':
      return new IndexPath(1);
    case 'FulfillTable':
      return new IndexPath(0, 1);
    case 'Warehouse':
      return new IndexPath(0, 2);
    case 'InboundConsignments':
      return new IndexPath(1, 2);
    case 'OutboundConsignments':
      return new IndexPath(2, 2);
    case 'TransferConsignments':
      return new IndexPath(3, 2);
    case 'SkuMappingList':
      return new IndexPath(4, 2);
    case 'RestockList':
      return new IndexPath(5, 2);
    case 'PickingTaskList':
      return new IndexPath(6, 2);
    case 'InventoryAnalytics':
      return new IndexPath(0, 3);
    case 'SalesAnalytics':
      return new IndexPath(1, 3);
    case 'NewShipment':
      return new IndexPath(0, 4);
    case 'CourierShipmentList':
      return new IndexPath(1, 4);
    case 'TrackingShipments':
      return new IndexPath(2, 4);
    case 'AddressBook':
      return new IndexPath(3, 4);
  }
};

const CustomDrawerContent = (props) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const color = paperNativeTheme.colors.primary;

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));

  React.useEffect(() => {
    setSelectedIndex(routeNameToSelectedIndex(props.state.routeNames[props.state.index]));
  }, [props.state]);

  const isWmsManager = useIsWmsManager();
  const isEzomUser = useIsEzomUser();

  return (
    <UIKittenDrawer
      selectedIndex={selectedIndex}
      onSelect={(index) => {
        setSelectedIndex(index);
      }}>
      <DrawerItem
        title={t('authorised.dashboard')}
        accessoryLeft={(props) => <IconButton color={color} icon="view-dashboard" />}
        onPress={() => navigation.navigate('DashboardScreen')}
      />

      {isEzomUser ? (
        <DrawerGroup
          title={t('authorised.orders')}
          accessoryLeft={(props) => <IconButton color={color} icon="dolly" />}
          onPress={() => navigation.navigate('OrderListScreen')}>
          <DrawerItem
            title={t('authorised.fulfillOrders')}
            onPress={() => navigation.navigate('FulfillTable')}
          />
        </DrawerGroup>
      ) : (
        <></>
      )}

      {/*NOTE: it is not possible to open DrawerGroup by default at the moment. See this
       upstream ticket: https://github.com/akveo/react-native-ui-kitten/issues/1310*/}
      <DrawerGroup
        title={t('authorised.warehouse')}
        accessoryLeft={(props) => <IconButton color={color} icon="warehouse" />}>
        <DrawerItem
          title={t('authorised.inventory')}
          onPress={() => navigation.navigate('Inventory')}
        />
        <DrawerItem
          title={t('authorised.inboundConsignments')}
          onPress={() => navigation.navigate('InboundConsignments')}
        />
        <DrawerItem
          title={t('authorised.outboundConsignments')}
          onPress={() => navigation.navigate('OutboundConsignments')}
        />
        <DrawerItem
          title={t('authorised.transferConsignments')}
          onPress={() => navigation.navigate('TransferConsignments')}
        />
        {isEzomUser ? (
          <DrawerItem
            title={t('authorised.skuMapping')}
            onPress={() => navigation.navigate('SkuMappingList')}
          />
        ) : (
          <></>
        )}
        {isEzomUser ? (
          <DrawerItem
            title={t('authorised.inventoryHealth')}
            onPress={() => navigation.navigate('RestockList')}
          />
        ) : (
          <></>
        )}
        {isWmsManager ? (
          <DrawerItem
            title={t('authorised.pickingTasks')}
            onPress={() => navigation.navigate('PickingTaskList')}
          />
        ) : (
          <></>
        )}
        {isWmsManager ? (
          <DrawerItem
            title={t('authorised.packagingMaterial')}
            onPress={() => navigation.navigate('PackagingMaterialList')}
          />
        ) : (
          <></>
        )}
      </DrawerGroup>
      <DrawerGroup
        title={t('authorised.analytics')}
        accessoryLeft={(props) => <IconButton color={color} icon="chart-bar-stacked" />}>
        <DrawerItem
          title={t('authorised.inventory')}
          onPress={() => navigation.navigate('InventoryAnalytics')}
        />
        {isEzomUser ? (
          <DrawerItem
            title={t('authorised.sales')}
            onPress={() => navigation.navigate('SalesAnalytics')}
          />
        ) : (
          <></>
        )}
      </DrawerGroup>
      {isEzomUser ? (
        <DrawerGroup
          title={t('authorised.shipments')}
          accessoryLeft={(props) => <IconButton color={color} icon="map" />}>
          <DrawerItem
            title={t('authorised.newShipment')}
            onPress={() => navigation.navigate('NewShipment')}
          />
          <DrawerItem
            title={t('authorised.courierShipments')}
            onPress={() => navigation.navigate('CourierShipmentList')}
          />
          <DrawerItem
            title={t('authorised.trackShipments')}
            onPress={() => navigation.navigate('TrackingShipments')}
          />
          <DrawerItem
            title={t('authorised.addressBook')}
            onPress={() => navigation.navigate('AddressBook')}
          />
        </DrawerGroup>
      ) : (
        <></>
      )}
    </UIKittenDrawer>
  );
};

export default observer(({ user }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const navigation = useNavigation();
  const isWmsManager = useIsWmsManager();
  const isAccountManager = useIsAccountManager();
  useOnBarcodeScanned(async (barcode) => {
    if (isWmsManager) {
      if (isPickingTaskId(barcode)) {
        const task = await pickingTaskTableStore.getPickingTask(barcode);
        if (task) {
          await playSuccessSoundAsync();
          navigation.navigate('PickingTaskDetail', {
            id: barcode,
            warehouseAccountId: warehouseAccountStore.warehouseAccountId,
          });
        } else {
          pickingTaskTableStore.setSearchTerm(barcode);
        }
      }
    }
  }, []);

  return (
    <Drawer.Navigator
      initialRouteName="DashboardScreen"
      screenOptions={{
        unmountOnBlur: true,
        headerShown: true,
        headerStyle: {
          backgroundColor: paperNativeTheme.colors.primary,
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerRight: (props) => (
          <NavigationHeaderRight isAccountManager={isAccountManager} user={user} />
        ),
      }}
      drawerType={isMobile ? 'front' : 'permanent'}
      drawerContent={(props) => <CustomDrawerContent {...props} user={user} />}
      drawerStyle={{ width: 'fit-content' }}>
      <Drawer.Screen
        name="OrgListScreen"
        component={OrgListScreen}
        options={{ title: t('authorised.organisationList') }}
      />

      <Drawer.Screen
        name="DashboardScreen"
        component={DashboardScreen}
        options={{ title: t('authorised.dashboard') }}
      />

      {/*********************** User account *********************/}
      <Drawer.Screen
        name="UserAccountBalanceScreen"
        component={(props) => <UserAccountBalanceScreen {...props} user={user} />}
        options={{ title: t('authorised.yourAccountBalance') }}
      />

      <Drawer.Screen
        name="UserAccountBalanceScreenWithPaymentStatus"
        component={(props) => <UserAccountBalanceScreen {...props} user={user} />}
        options={{ title: t('authorised.yourAccountBalance') }}
      />

      <Drawer.Screen
        name="OrgProfileScreen"
        component={OrgProfileScreen}
        options={{ title: t('authorised.yourOrganisation') }}
      />

      <Drawer.Screen
        name="AddStoreScreen"
        component={AddStoreScreen}
        options={{ title: t('authorised.addStore') }}
      />

      <Drawer.Screen
        name="EditWmsAccountScreen"
        component={EditWmsAccountScreen}
        options={{ title: t('authorised.editWmsAccount') }}
      />

      {/*********************** Order *********************/}
      <Drawer.Screen
        name="OrderListScreen"
        component={OrderListScreen}
        options={{ title: t('authorised.orderList') }}
      />

      <Drawer.Screen
        name="OrderDetailScreen"
        // Use inline function to ensure the component is unmountd when leaving route (otherwise will be too many re-rendering)
        component={(props) => <OrderDetailScreen {...props} />}
        options={{ title: t('authorised.orderDetail') }}
      />

      <Drawer.Screen
        name="OrderDispatchScreen"
        // Use inline function to ensure the component is unmountd when leaving route (otherwise will be too many re-rendering)
        component={(props) => <OrderDispatchScreen {...props} />}
        options={{ title: t('authorised.orderDispatch') }}
      />

      {/*********************** Inventory *********************/}
      <Drawer.Screen
        name="Inventory"
        component={(props) => (
          <ScreenWrapper>
            <SkuList {...props} />
          </ScreenWrapper>
        )}
      />
      <Drawer.Screen
        name="SkuMappingList"
        component={(props) => (
          <ScreenWrapper>
            <SkuMappingList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: t('authorised.skuMappings') }}
      />
      <Drawer.Screen
        name="SkuDetail"
        component={SkuDetail}
        options={{ title: t('authorised.skuDetails') }}
      />
      <Drawer.Screen
        name="InboundConsignments"
        component={(props) => (
          <ScreenWrapper>
            <InboundList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: t('authorised.inboundConsignments') }}
      />
      <Drawer.Screen
        name="InboundDetail"
        component={InboundDetail}
        options={{ title: t('authorised.inboundConsignmentDetails') }}
      />
      <Drawer.Screen
        name="OutboundConsignments"
        component={(props) => (
          <ScreenWrapper>
            <OutboundList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: t('authorised.outboundConsignments') }}
      />
      <Drawer.Screen
        name="OutboundDetailScreen"
        component={OutboundDetail}
        options={{ title: t('authorised.outboundConsignmentDetails') }}
      />
      <Drawer.Screen
        name="TransferConsignments"
        component={TransferList}
        options={{ title: t('authorised.transferConsignments') }}
      />
      <Drawer.Screen
        name="TransferDetail"
        component={TransferDetail}
        options={{ title: t('authorised.transferConsignmentDetails') }}
      />
      <Drawer.Screen
        name="FulfillTable"
        component={(props) => <FulfillmentTable {...props} />}
        options={{ title: t('authorised.fulfillments') }}
      />
      <Drawer.Screen
        name="RestockList"
        component={RestockList}
        options={{ title: t('authorised.inventoryHealth') }}
      />
      <Drawer.Screen
        name="PickingTaskList"
        component={PickingTaskList}
        options={{ title: t('authorised.pickingTasks') }}
      />
      <Drawer.Screen
        name="PackagingMaterialList"
        component={(props) => (
          <ScreenWrapper>
            <PackagingMaterialList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: t('authorised.packagingMaterial') }}
      />
      <Drawer.Screen
        name="PickingTaskDetail"
        component={PickingTaskDetail}
        options={{ title: t('authorised.pickingTaskDetails') }}
      />
      <Drawer.Screen
        name="OcPackingScreen"
        component={OcPackingScreen}
        options={{ title: t('authorised.ocPacking') }}
      />
      {/*********************** Analytics *********************/}
      <Drawer.Screen
        name="InventoryAnalytics"
        component={InventoryAnalytics}
        options={{ title: t('authorised.inventoryAnalytics') }}
      />
      <Drawer.Screen
        name="SalesAnalytics"
        component={SalesAnalytics}
        options={{ title: t('authorised.salesAnalytics') }}
      />
      {/*********************** Shipments *********************/}
      <Drawer.Screen
        name="NewShipment"
        component={(props) => (
          <ScreenWrapper>
            <NewShipment {...props} />
          </ScreenWrapper>
        )}
        options={{ title: t('authorised.newShipment') }}
      />
      <Drawer.Screen
        name="CourierShipmentList"
        component={(props) => (
          <ScreenWrapper>
            <CourierShipmentList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: t('authorised.courierShipments') }}
      />
      <Drawer.Screen
        name="ShipmentOrderDetail"
        component={ShipmentOrderDetail}
        options={{ title: t('authorised.shipmentOrderDetails') }}
      />
      <Drawer.Screen
        name="TrackingShipments"
        component={(props) => (
          <ScreenWrapper>
            <TrackingShipments {...props} />
          </ScreenWrapper>
        )}
        options={{ title: t('authorised.trackShipments') }}
      />
      <Drawer.Screen
        name="TrackingShipmentResults"
        component={TrackingShipmentResults}
        options={{ title: t('authorised.trackShipmentResult') }}
      />
      <Drawer.Screen
        name="AddressBook"
        component={AddressBookList}
        options={{ title: t('authorised.addressBook') }}
      />
    </Drawer.Navigator>
  );
});
