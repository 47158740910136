import { Button, Text } from '@ui-kitten/components';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  footerContainer: {
    flex: 1,
  },

  footerControl: {
    marginHorizontal: 2,
  },
});

export const Header = (props) => {
  return (
    <View {...props}>
      <Text category="h6">{props.heading}</Text>
      {/*<Text appearance="hint">*/}
      {/*  <strong>last updated:</strong>{' '}*/}
      {/*  {formatDistanceToNow(new Date(faker.date.recent()), { addSuffix: true })}*/}
      {/*</Text>*/}
    </View>
  );
};

export const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <View style={[props.style, styles.footerContainer]}>
      <Button style={styles.footerControl} disabled={props.disabled} onPress={props.onPress}>
        {t('userAccount.shared.update')}
      </Button>
    </View>
  );
};
