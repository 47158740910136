import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import { zipObj } from 'ramda';
import {
  Button,
  CheckBox,
  Divider,
  Icon,
  Layout,
  List,
  ListItem,
  Spinner,
  Text,
} from '@ui-kitten/components';
import { paperNativeTheme } from 'src/core/theme';
import { NETWORK_CALL_STATUS, unifiedAlert } from '../../core/utils/utils';
import { TooltipWrapper } from '../../components/TooltipWrapper';
import { useIsMobile } from '../../core/responsive.utils';
import { orderTableStore } from '../../store';
import { observer } from 'mobx-react';
import { ezTheme } from '../../core/theme';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  modalStyle: {
    minWidth: 400,
    backgroundColor: 'white',
    margin: 20,
    alignSelf: 'center',
  },
  button: {
    marginHorizontal: 10,
    marginVertical: 10,
  },

  checkbox: { flexDirection: 'row', alignItems: 'center' },
  desktopButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  resyncAllData: {
    marginVertical: 10,
    marginHorizontal: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const TickBadge = () => (
  <Button
    appearance="ghost"
    size="small"
    accessoryLeft={() => (
      <Icon
        name="checkmark-circle-2-outline"
        style={{ width: 22, fill: paperNativeTheme.colors.success }}
      />
    )}
  />
);

const LoadingBadge = () => (
  <Button appearance="ghost" size="small" accessoryLeft={() => <Spinner size="medium" />} />
);

const ErrorBadge = () => (
  <Button
    appearance="ghost"
    size="small"
    accessoryLeft={(props) => (
      <Icon
        name="alert-circle-outline"
        style={{ width: 22, fill: paperNativeTheme.colors.danger }}
      />
    )}
  />
);

export const SyncOrdersModal = observer(({ stores, visibleSync, setVisibleSync }) => {
  const [syncState, setSyncState] = useState({});
  const isMobile = useIsMobile();
  const [forceAll, setForceAll] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const { t } = useTranslation();

  const onStart = useCallback(async () => {
    if (selectedStores.length === 0) {
      unifiedAlert(t('order.syncOrdersModal.pleaseSelectStore'));
      return;
    }
    setSyncState(
      zipObj(
        selectedStores,
        selectedStores.map(() => ({ state: NETWORK_CALL_STATUS.PENDING, errMsg: null })),
      ),
    );

    await Promise.all(
      stores
        .filter((store) => selectedStores.includes(store.id))
        .map(async (store) => {
          try {
            await orderTableStore.syncOrders(store.id, forceAll);
            setSyncState((prev) => ({
              ...prev,
              [store.id]: { state: NETWORK_CALL_STATUS.SUCCESS },
            }));
          } catch (e) {
            console.error(e);
            setSyncState((prev) => ({
              ...prev,
              [store.id]: { state: NETWORK_CALL_STATUS.FAILED, errMsg: JSON.stringify(e) },
            }));
          }
        }),
    );
    orderTableStore.fetchItems();
  }, [setSyncState, stores, selectedStores, forceAll, t]);

  const renderItem = ({ item }) => {
    return (
      <ListItem
        key={item.id}
        title={item.name}
        description={item.provider}
        accessoryLeft={() => (
          <CheckBox
            style={styles.checkbox}
            size={'small'}
            checked={selectedStores.includes(item.id)}
            onChange={(checked) =>
              setSelectedStores(
                checked
                  ? [...selectedStores, item.id]
                  : selectedStores.filter((id) => id !== item.id),
              )
            }
          />
        )}
        accessoryRight={() =>
          syncState[item.id]?.state === NETWORK_CALL_STATUS.PENDING ? (
            <LoadingBadge />
          ) : syncState[item.id]?.state === NETWORK_CALL_STATUS.SUCCESS ? (
            <TickBadge />
          ) : syncState[item.id]?.state === NETWORK_CALL_STATUS.FAILED ? (
            <TooltipWrapper anchor={<ErrorBadge />}>
              <Text category="p2">{syncState[item.id]?.errMsg}</Text>
            </TooltipWrapper>
          ) : null
        }
      />
    );
  };
  const onDismiss = () => {
    setVisibleSync(false);
    setSyncState([]);
  };

  return (
    <Portal>
      <Modal
        visible={visibleSync}
        backdropStyle={{
          backgroundColor: ezTheme.backdropModalColor,
        }}
        onDismiss={onDismiss}
        contentContainerStyle={styles.modalStyle}>
        <Layout style={{ margin: 20 }}>
          <Text category="h6" style={{ marginBottom: 20 }}>
            {t('order.syncOrdersModal.synchroniseOrdersFromShops')}
          </Text>
          <Divider />
          <List data={stores} renderItem={renderItem} ItemSeparatorComponent={Divider} />
          <Layout style={styles.resyncAllData}>
            <CheckBox
              size="small"
              checked={forceAll}
              onChange={(checked) => setForceAll(checked)}></CheckBox>
            <Text category="p2" style={{ marginHorizontal: 10 }}>
              {t('order.syncOrdersModal.resyncAllDataFromHistory')}
            </Text>
            <TooltipWrapper
              anchor={
                <Button
                  appearance="ghost"
                  size="small"
                  accessoryLeft={(props) => (
                    <Icon
                      name="alert-circle-outline"
                      style={{ width: 22, fill: paperNativeTheme.colors.danger }}
                    />
                  )}
                />
              }>
              <Text>{t('order.syncOrdersModal.syncOrdersWarning')}</Text>
            </TooltipWrapper>
          </Layout>

          <Text category="p2" status="warning" style={{ marginHorizontal: 20 }}>
            {t('order.syncOrdersModal.syncDurationWarning')}
          </Text>
          <Layout style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
            <Button
              status="primary"
              onPress={onStart}
              style={styles.button}
              disabled={
                // Disable button when all sync are finished, or sync is in progress
                Object.keys(syncState).length > 0 &&
                (!!Object.values(syncState).find((s) => s.state === NETWORK_CALL_STATUS.PENDING) ||
                  !Object.values(syncState).find((s) => s.state !== NETWORK_CALL_STATUS.SUCCESS))
              }>
              {t('order.syncOrdersModal.startSync')}
            </Button>
            <Button status="control" onPress={onDismiss} style={styles.button}>
              {t('order.syncOrdersModal.close')}
            </Button>
          </Layout>
        </Layout>
      </Modal>
    </Portal>
  );
});
