import React from 'react';
import { Card as KittenCard } from '@ui-kitten/components/ui/card/card.component';
import { Header } from '../user-account/shared';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextInputField } from '../../components/input/TextInputField';
import Button from '../../components/Button';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { StyleSheet } from 'react-native';
import Select from '../../components/Select';
import { useTranslation } from 'react-i18next';

const CREATE_TRANSACTION = gql`
  mutation AddTransaction($orgId: ID!, $type: TransactionType!, $amount: Float!, $remark: String) {
    createTransaction(orgId: $orgId, type: $type, amount: $amount, remark: $remark) {
      createTransaction {
        id
      }
    }
  }
`;

export const TRANSACTION_TYPE = {
  OPEN: 'open',
  RECHARGE: 'recharge',
  REFUND: 'refund',
  CHARGE: 'charge',
};

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
});

const transactionOptions = Object.values(TRANSACTION_TYPE).sort();

export const AddTransaction = ({ orgId, onAdded }) => {
  const [createTransaction, createTransactionResult] = useMutation(CREATE_TRANSACTION);
  const { t } = useTranslation();

  return (
    <Formik
      onSubmit={(values, resetForm) => {
        createTransaction({
          variables: {
            orgId,
            type: values.type,
            amount: Number(values.amount),
            remark: values.remark,
          },
        }).then(onAdded);
      }}
      validationSchema={Yup.object({
        type: Yup.string().required().oneOf(transactionOptions),
        amount: Yup.number().required(),
        remark: Yup.string().required(),
      })}
      initialValues={{}}
      enableReinitialize>
      {(formProps) => {
        return (
          <KittenCard
            style={styles.card}
            header={(props) => (
              <Header {...props} heading={t('accountManager.addTransaction.createTransaction')} />
            )}>
            <Select
              name="type"
              style={styles.field}
              label={t('accountManager.addTransaction.type')}
              options={transactionOptions}
              value={transactionOptions.find((s) => s === formProps.values.type)}
              error={formProps.errors.type}
              touched={formProps.touched.type}
              onChange={(val) => {
                formProps.setFieldValue('type', val);
              }}
              onBlur={() => formProps.setFieldTouched('type', true)}
              {...formProps}
            />

            <TextInputField
              name="amount"
              label={t('accountManager.addTransaction.amount')}
              style={styles.field}
              {...formProps}
            />
            <TextInputField
              name="remark"
              label={t('accountManager.addTransaction.remark')}
              style={styles.field}
              {...formProps}
            />

            <Button
              disabled={createTransactionResult.loading}
              mode="outlined"
              onPress={() => formProps.handleSubmit()}>
              {t('accountManager.addTransaction.create')}
            </Button>
          </KittenCard>
        );
      }}
    </Formik>
  );
};
