import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { observer } from 'mobx-react';
import 'react-native-gesture-handler';
import 'core-js/es6/symbol';
import 'core-js/fn/symbol/iterator';
import { HomeScreen, AuthorisedScreen, AddOrganisationScreen } from './screens';
import { gql, useLazyQuery } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';
import Spinner from './components/Spinner';
import ErrorScreen from './screens/ErrorScreen';
import { GET_USER } from './core/utils/utils';

const Stack = createStackNavigator();

const MAIN_TITLE = 'EzOM';

const NAV_LINKING_CONFIG = {
  config: {
    screens: {
      HomeScreen: '',
      IndeterminateScreen: 'l',
      AddOrganisationScreen: 'add-org',
      AuthorisedScreen: {
        screens: {
          OrgListScreen: 'a/orgs',
          DashboardScreen: 'a/dashboard',
          OrderDispatchScreen: 'a/dispatch-order',
          OrderListScreen: 'a/orders',
          OrderDetailScreen: 'a/store/:storeId/order/:id',

          // NOTE: if this route is updated, other places will need to be updated as well.
          // Then, on 3rd party provider settings, you might need to be update the accepted
          // redirect URL list
          AddStoreScreen: '/a/store/add/:provider',

          Inventory: 'a/inventory',
          Warehouse: 'a/warehouse',
          EditWmsAccountScreen: 'a/edit-wms-account/:id',
          SkuDetail: 'a/inventory/sku/:id',
          InboundConsignments: 'a/warehouse/inbound',
          InboundDetail: 'a/inbound/:consignmentNumber',
          SkuMappingList: 'a/warehouse/skuMappings',
          OutboundConsignments: 'a/warehouse/outbound',
          OutboundDetailScreen: 'a/warehouse/outbound/:id',
          TransferConsignments: 'a/warehouse/transfer',
          TransferDetail: 'a/transfer/:consignmentNumber',
          RestockList: 'a/warehouse/restock',
          PickingTaskList: 'a/warehouse/pickingTasks',
          PickingTaskDetail: 'a/pickingTask/:id',
          OcPackingScreen: 'a/ocPacking/:ocNo',
          OrgProfileScreen: 'a/org',
          UserAccountBalanceScreen: 'a/account-balance',
          UserAccountBalanceScreenWithPaymentStatus: 'a/account-balance/:paymentStatus',
          FulfillTable: 'a/fulfillment',
          Analytics: 'a/analytics',
          SalesAnalytics: 'a/analytics/sales',
          InventoryAnalytics: 'a/analuytics/inventory',
          Shipments: 'a/shipments',
          CourierShipmentList: 'a/shipments/courier-shipments',
          NewShipment: 'a/shipments/new',
          ShipmentOrderDetail: 'a/shipment/:id',
          AddressBook: 'a/shipments/address-book',
          TrackingShipments: 'a/shipments/tracking-shipments',
          TrackingShipmentResults: 'a/shipments/tracking-shipments-result',
          PackagingMaterialList: 'a/warehouse/packaging-materials',
        },
      },
    },
  },
};

const authScreens = {
  HomeScreen,
};

export default observer(() => {
  const { keycloak, initialized } = useKeycloak();
  const [
    loadUser,
    {
      data: loadUserData,
      loading: loadingUser,
      error: loadUserError,
      called: loadUserCalled,
      refetch: refetchUser,
    },
  ] = useLazyQuery(GET_USER);

  const user = (loadUserData && loadUserData.user) || undefined;

  React.useEffect(() => {
    if (keycloak.authenticated) {
      loadUser();
    }
  }, [keycloak.authenticated]);

  const screenStack = () => {
    if (keycloak.authenticated) {
      if (loadUserError?.networkError) {
        return (
          <Stack.Navigator>
            <Stack.Screen name="NetworkError" options={{ headerShown: false, title: MAIN_TITLE }}>
              {() => (
                <ErrorScreen
                  errorMsg="Oops. Time for a coffee!"
                  errorDetail={loadUserError.networkError.message}
                />
              )}
            </Stack.Screen>
          </Stack.Navigator>
        );
      }

      if (!user || (user && !user.organisation)) {
        return (
          <Stack.Navigator>
            <Stack.Screen
              name="AddOrganisationScreen"
              component={(props) => (
                <AddOrganisationScreen {...props} user={user} refetchUser={refetchUser} />
              )}
              options={{ headerShown: false, title: MAIN_TITLE }}
            />
          </Stack.Navigator>
        );
      }
      return (
        <Stack.Navigator>
          <Stack.Screen
            name="AuthorisedScreen"
            component={(props) => <AuthorisedScreen {...props} user={user} />}
            options={{ title: 'Dashboard', headerShown: false }}
          />
        </Stack.Navigator>
      );
    } else {
      return (
        <Stack.Navigator>
          {Object.entries(authScreens).map(([name, component]) => (
            <Stack.Screen
              key={name}
              name={name}
              component={component}
              options={{ headerShown: false, title: MAIN_TITLE }}
            />
          ))}
        </Stack.Navigator>
      );
    }
  };

  if (!initialized || loadingUser || (keycloak.authenticated && !loadUserCalled)) {
    return <Spinner content="Loading ..." />;
  }

  return <NavigationContainer linking={NAV_LINKING_CONFIG}>{screenStack()}</NavigationContainer>;
});
