import { Text } from '@ui-kitten/components';
import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Button, IconButton, Modal, Portal, useTheme } from 'react-native-paper';
import { ezTheme } from '../../core/theme';
import {
  getPODFileName,
  TrackingShipmentResultsDate,
  TrackingShipmentResultsInfo,
} from './TrackingShipmentResultsTable';
import { downloadBase64Image } from '../../core/utils/utils';

const TrackingShipmentResultsModal = ({ data, onClose, visible }) => {
  const theme = useTheme();
  const shipmentOrderRefNo = data?.shipmentOrder?.ref_no;

  const renderEvent = ({ item }) => {
    return (
      <View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <IconButton
            style={{ margin: 0 }}
            icon={'circle-medium'}
            color={theme.colors.primary}></IconButton>
          <Text>{item.status}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            borderLeftWidth: 2,
            borderLeftColor: theme.colors.primary,
            marginLeft: 18,
          }}>
          <View
            style={{
              width: '100%',
              paddingHorizontal: 18,
              paddingBottom: 10,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <TrackingShipmentResultsDate date={item.date}></TrackingShipmentResultsDate>
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              {item?.pod && (
                <Button
                  color={theme.colors.primary}
                  mode="text"
                  icon="image"
                  onPress={() => {
                    downloadBase64Image(
                      item.pod,
                      getPODFileName(data.trackingNumber, shipmentOrderRefNo, item.fileFormat),
                    );
                  }}>
                  POD
                </Button>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderTrackingShipmentDetail = () => {
    return (
      <ScrollView>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text selectable style={{ fontWeight: 'bold' }}>{`${data.trackingNumber} ${
            data.courier && `(${data.courier})`
          }`}</Text>
          <IconButton
            icon="content-copy"
            size={17}
            color={theme.colors.primary}
            onPress={() => {
              navigator.clipboard.writeText(data.trackingNumber);
            }}></IconButton>
        </View>

        <Text selectable style={styles.separator}>
          {data.shipmentOrderId}
        </Text>
        <TrackingShipmentResultsInfo info={data}></TrackingShipmentResultsInfo>
        <View style={{ padding: 10 }}></View>
        {Array.isArray(data.trackingEvents) &&
          data.trackingEvents.map((event, index) =>
            renderEvent({
              item: event,
              index,
            }),
          )}
      </ScrollView>
    );
  };

  return (
    <Portal>
      <Modal
        style={styles.container}
        visible={visible}
        onDismiss={onClose}
        dismissable={true}
        contentContainerStyle={styles.modalContent}>
        {renderTrackingShipmentDetail()}
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {},
  backdropStyle: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modalContent: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '50%', // Adjust width as needed
    height: '100%',
    backgroundColor: 'white',
    padding: 20,
  },
  separator: {
    marginBottom: 10,
  },
});

export default memo(TrackingShipmentResultsModal);
