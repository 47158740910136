import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Card, Modal, Text } from 'react-native-paper';
import { Portal } from 'react-native-paper';
import { observer } from 'mobx-react';
import { List, ListItem } from '@ui-kitten/components';
import { format } from 'date-fns';
import Table from '../../components/Table';
import Background from '../../components/Background';
import TcSearchForm from '../../components/TcSearchForm';
import CreateTransferForm from '../../components/CreateTransferForm';
import Dialogue from '../../components/Dialogue';
import {
  TC_STATUS_CODE,
  TC_STATUS_MAP,
  WAREHOUSE_PROVIDERS,
} from '@ezom/library/lib/cjs/constants';
import exportCsv from '../../core/csv';
import TransferControl from '../../components/TransferControl';
import { UIStatusWrapper } from '../../components/ui-status';
import { useIsFocused } from '@react-navigation/core';
import { transferOrderTableStore, warehouseAccountStore, warehousesStore } from '../../store';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { all } from 'ramda';
import { useTranslation } from 'react-i18next';

const getId = (item) => item.consignment_no;
const selectedTcs = (icOrders, selectedIds) =>
  icOrders.filter((icOrder) => selectedIds.has(getId(icOrder)));
const CANCELLABLE_STATUS = [TC_STATUS_CODE.Approved, TC_STATUS_CODE.Draft];
const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

export default observer(({ navigation }) => {
  const isFocused = useIsFocused();
  const { t } = useTranslation();

  // Refetch data when screen is focused
  React.useEffect(() => {
    if (isFocused) {
      transferOrderTableStore.fetchItems();
    }
  }, [isFocused]);

  return (
    <Background fullWidth={true}>
      <TransferControl
        filterParams={transferOrderTableStore.filterParams}
        indicators={getIndicators(transferOrderTableStore.filterParams, t)}
        onSearchTermChange={transferOrderTableStore.setSearchTerm}
        onPressAdvancedSearch={transferOrderTableStore.openSearch}
        onFilterClear={() => {
          transferOrderTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          transferOrderTableStore.setFilterParams({
            ...transferOrderTableStore.filterParams,
            ...params,
          });
        }}
        sortOptions={transferOrderTableStore.sortOption}
        onSortChange={transferOrderTableStore.setSortOption}
        disableSearch={transferOrderTableStore.loading}
        requireButtonClickToSearch={transferOrderTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: transferOrderTableStore.error,
            indeterminate: transferOrderTableStore.loading,
          }}>
          <Table
            items={transferOrderTableStore.items}
            getId={getId}
            displayKeys={transferOrderTableStore.DISPLAY_KEYS}
            sort="single"
            defaultSortOption={transferOrderTableStore.sortOption}
            onSortChange={transferOrderTableStore.setSortOption}
            heightOffset={300}
            titleByKey={transferOrderTableStore.TITLE_BY_KEY}
            formatterByKey={transferOrderTableStore.FORMATTER_BY_KEY}
            rowOnClick={(item) =>
              navigation.navigate('TransferDetail', {
                consignmentNumber: item.consignment_no,
                warehouseAccountId: item.warehouseAccountId,
              })
            }
            isFabVisible={isFocused}
            currentPage={transferOrderTableStore.currentPage}
            onPageChange={(page) => {
              transferOrderTableStore.setCurrentPage(page);
            }}
            totalItemNum={transferOrderTableStore.total}
            itemsPerPage={transferOrderTableStore.pageSize}
            setPageSize={transferOrderTableStore.setPageSize}
            fabActions={(selectedIds) => {
              const actions = [];
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'magnify',
                  label: t('inventory.transferList.searchConsignments'),
                  onPress: transferOrderTableStore.openSearch,
                });
                actions.push({
                  icon: 'plus',
                  label: t('inventory.transferList.addTransferConsignment'),
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      transferOrderTableStore.openConsignmentForm();
                    } else {
                      transferOrderTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        transferOrderTableStore.openConsignmentForm();
                      });
                      transferOrderTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
                actions.push({
                  icon: 'download-outline',
                  label: t('inventory.transferList.exportAll'),
                  onPress: async () => {
                    await transferOrderTableStore.fetchItems(true);
                    const tcs = transferOrderTableStore.exportedItems.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = tcs?.length > 0 ? Object.keys(tcs[0]) : [];
                    exportCsv(tcs, csvHeaders, 'icOrders.csv');
                  },
                });
              }

              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'download-outline',
                  label: t(
                    `inventory.transferList.exportSelected${
                      selectedIds.size > 1 ? 'Orders' : 'Order'
                    }`,
                  ),
                  onPress: () => {
                    const tcs = selectedTcs(transferOrderTableStore.items, selectedIds);
                    const exportedIcs = tcs.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = exportedIcs?.length > 0 ? Object.keys(exportedIcs[0]) : [];
                    exportCsv(exportedIcs, csvHeaders, 'tcOrders.csv');
                  },
                });
                if (
                  all(
                    (item) => CANCELLABLE_STATUS.includes(item.status),
                    selectedTcs(transferOrderTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'cancel',
                    label: t(
                      `inventory.transferList.cancelSelected${
                        selectedIds.size > 1 ? 'Orders' : 'Order'
                      }`,
                    ),
                    onPress: () => {
                      transferOrderTableStore.ordersToCancel = selectedIds;
                      transferOrderTableStore.openCancelDialogue();
                    },
                  });
                }
              }

              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>
      <Portal>
        <Modal
          visible={transferOrderTableStore.searchOpen}
          onDismiss={transferOrderTableStore.closeSearch}
          contentContainerStyle={styles.modalStyle}>
          <TcSearchForm
            searchParams={transferOrderTableStore.filterParams}
            items={transferOrderTableStore.items}
            onDismiss={transferOrderTableStore.closeSearch}
            onSubmit={(filterParams) => {
              transferOrderTableStore.setFilterParams(filterParams);
              transferOrderTableStore.closeSearch();
            }}
          />
        </Modal>
      </Portal>

      <Portal>
        <Modal
          visible={transferOrderTableStore.consignmentFormOpen}
          onDismiss={transferOrderTableStore.closeConsignmentForm}
          contentContainerStyle={styles.modalStyle}>
          <CreateTransferForm
            warehouseAccount={warehouseAccountStore.selectedWarehouseAccount}
            onDismiss={transferOrderTableStore.closeConsignmentForm}
            onSubmit={() => {
              setTimeout(() => transferOrderTableStore.fetchItems(), 2000);
              transferOrderTableStore.closeConsignmentForm();
            }}
          />
        </Modal>
      </Portal>

      <Portal>
        <Modal
          visible={transferOrderTableStore.cancelDialogueOpen}
          onDismiss={transferOrderTableStore.closeCancelDialogue}
          contentContainerStyle={styles.modalStyle}>
          <Dialogue
            onSubmit={async () => {
              await Promise.all(
                selectedTcs(
                  transferOrderTableStore.items,
                  transferOrderTableStore.ordersToCancel,
                ).map((tc) =>
                  transferOrderTableStore.dataStore.cancelItem(
                    tc.warehouseAccountId,
                    tc.consignment_no,
                  ),
                ),
              );
              setTimeout(() => transferOrderTableStore.fetchItems(), 2000);
              transferOrderTableStore.closeCancelDialogue();
            }}
            onDismiss={transferOrderTableStore.closeCancelDialogue}>
            <Text>{t('inventory.transferList.areYouSureCancelConsignments')}</Text>
            <List
              data={selectedTcs(
                transferOrderTableStore.items,
                transferOrderTableStore.ordersToCancel,
              )}
              renderItem={({ item }) => <ListItem title={`${item.consignment_no}`} />}
            />
          </Dialogue>
        </Modal>
      </Portal>

      <WarehouseAccountSelectionModal
        visible={transferOrderTableStore.warehouseSelectionVisible}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts.filter(
          (w) => w.provider === WAREHOUSE_PROVIDERS.EZWMS,
        )}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          transferOrderTableStore.onWarehouseSelectionSubmit(warehouseAccount);
          transferOrderTableStore.setWarehouseSelectionVisible(false);
        }}
        onCancel={() => transferOrderTableStore.setWarehouseSelectionVisible(false)}
      />
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', margin: 20 },
});

const getIndicators = (params, t) => {
  const dateFormatter = (date) => format(date, 'd MMM y');
  let indicators = [];

  if (params.consignmentNumber) {
    indicators.push(`${t('inventory.transferList.consignmentNo')}: ${params.consignmentNumber}`);
  } else {
    if (params.refNumber) {
      indicators.push(`${t('inventory.transferList.refNo')}: ${params.refNumber}`);
    } else {
      if (params.status) {
        indicators.push(`${t('inventory.transferList.status')}: ${TC_STATUS_MAP[params.status]}`);
      }

      if (params.warehouse_code) {
        indicators.push(
          `${t('inventory.transferList.warehouse')}: ${warehousesStore.getWarehouseNameEn(
            params.warehouse_code,
            warehouseAccountStore.selectedWarehouseAccount,
          )}`,
        );
      }

      if (params.create_time_start && params.create_time_end) {
        indicators.push(
          `${t('inventory.transferList.ordersFrom')} ${dateFormatter(params.create_time_start)} ${t(
            'inventory.transferList.to',
          )} ${dateFormatter(params.create_time_end)}`,
        );
      } else if (params.create_time_start) {
        indicators.push(
          `${t('inventory.transferList.ordersFrom')} ${dateFormatter(params.create_time_start)}`,
        );
      } else if (params.create_time_end) {
        indicators.push(
          `${t('inventory.transferList.ordersBy')} ${dateFormatter(params.create_time_end)}`,
        );
      }
    }
  }

  return indicators;
};
