import { useNavigation } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Dimensions,
  ScrollView,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Card, useTheme } from 'react-native-paper';
import Background from '../../components/Background';
import TrackingShipmentResultsTable from '../../components/CourierShipments/TrackingShipmentResultsTable';
import {
  courierShipmentSelectionTableStore,
  courierShipmentStore,
  warehouseAccountStore,
} from '../../store';
import { unifiedAlert } from '../../core/utils/utils';
import Spinner from '../../components/Spinner';
import { converge, concat, head, join, map, pipe, split, tail, toUpper } from 'ramda';

const capitalizeWord = converge(concat, [pipe(head, toUpper), tail]);
const capitalizeSentence = pipe(split(' '), map(capitalizeWord), join(' '));
const TrackingShipmentResults = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation();
  const windowHeight = Dimensions.get('window').height;
  const [resultMapping, setResultMapping] = useState({});
  const [activeTab, setActiveTab] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  const mappingData = (data) => {
    const mapping = {};
    mapping.all = data;
    data.forEach((item) => {
      if (item.error) {
        if (mapping.error) mapping.error.push(item);
        else mapping.error = [item];
      }
      const lastEvent = item.trackingEvents && item.trackingEvents[item.trackingEvents.length - 1];
      if (lastEvent) {
        const key = `${lastEvent.status}`.toLowerCase();
        if (mapping[key]) {
          mapping[key].push(item);
        } else {
          mapping[key] = [item];
        }
      }
    });
    return mapping;
  };

  const storeHistory = (dataInsert) => {
    const localStorageKey = 'history_tracking';
    let history = [];
    try {
      history = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    } catch (e) {
      console.log(e);
    }
    history.unshift(dataInsert);
    if (history.length > 5) {
      history.pop();
    }
    localStorage.setItem(localStorageKey, JSON.stringify(history));
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const dataToTrack = courierShipmentSelectionTableStore.trackingNumbers;
      const trackingInfo = await courierShipmentStore.trackShipmentOrder(
        warehouseAccountStore.selectedWarehouseAccount?.id,
        [...dataToTrack],
      );
      const dataMapping = mappingData(trackingInfo);
      setResultMapping(dataMapping);
      storeHistory(dataToTrack.join(', '));
    } catch (error) {
      unifiedAlert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      courierShipmentSelectionTableStore.trackingNumbers &&
      courierShipmentSelectionTableStore.trackingNumbers.length === 0
    ) {
      navigation.navigate('TrackingShipments');
    } else {
      loadData();
    }
  }, [courierShipmentSelectionTableStore.trackingNumbers]);

  const renderTab = () => {
    const getColor = (key) => {
      if (key === 'error') {
        return 'red';
      } else if (key === 'delivered') {
        return 'green';
      } else {
        return theme.colors.primary;
      }
    };

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {Object.entries(resultMapping).map(([key, value]) => {
          const isActiveTab = activeTab === key;
          const translatedKey = t(`shipments.trackingResults.${key}`);
          return (
            <TouchableWithoutFeedback onPress={() => setActiveTab(key)}>
              <View style={{ cursor: 'pointer' }}>
                <View
                  style={{
                    paddingHorizontal: 15,
                    paddingVertical: 10,
                  }}>
                  <Text
                    category="s1"
                    style={{ color: getColor(key) }}>{`${translatedKey} (${value.length})`}</Text>
                </View>
                <View
                  style={{
                    width: '100%',
                    height: 3,
                    borderRadius: 2,
                    backgroundColor: isActiveTab ? getColor(key) : 'transparent',
                  }}></View>
              </View>
            </TouchableWithoutFeedback>
          );
        })}
      </View>
    );
  };

  const renderResult = () => {
    return (
      <View style={{ flex: 1 }}>
        {renderTab()}
        <ScrollView style={{ height: windowHeight - 168 }}>
          <TrackingShipmentResultsTable data={resultMapping[activeTab] || []} />
        </ScrollView>
      </View>
    );
  };

  const renderLoading = () => {
    return (
      <View style={{ flex: 1, padding: 10, alignItems: 'center' }}>
        <Spinner content={t('shipments.trackingResults.loading')} />
      </View>
    );
  };

  return (
    <Background fullWidth={true}>
      <Card>{isLoading ? renderLoading() : renderResult()}</Card>
    </Background>
  );
};

export default memo(observer(TrackingShipmentResults));
