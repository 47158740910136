import gql from 'graphql-tag';
import { Alert, Platform } from 'react-native';
import { divide, pipe, curry, __, isNil } from 'ramda';
import { fromUnixTime, format, lastDayOfWeek, getDate, addDays } from 'date-fns';
import { parseWarehouseCourierValue } from '@ezom/library/lib/cjs/store';
import * as Yup from 'yup';
import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo, useEffect, useCallback } from 'react';
import { Audio } from 'expo-av';
import { customAlphabet } from 'nanoid';
import { getAlertMessage } from '@ezom/library/lib/cjs/ErrorTranslationKey';
import { t } from 'i18next';

export const emailValidator = (email) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return 'Email cannot be empty.';
  if (!re.test(email)) return 'Ooops! We need a valid email address.';

  return '';
};

export const passwordValidator = (password) => {
  if (!password || password.length <= 0) return 'Password cannot be empty.';

  return '';
};

export const nameValidator = (name) => {
  if (!name || name.length <= 0) return 'Name cannot be empty.';

  return '';
};

export const GET_USER = gql`
  query GetUser {
    user {
      id
      organisation {
        id
        name
        email
      }
      roles
    }
  }
`;

export const useUser = () => {
  const { data: loadUserData } = useQuery(GET_USER);

  const user = useMemo(() => (loadUserData && loadUserData.user) || undefined, [loadUserData]);
  return user;
};

const KC_ROLE_WMS_MANAGER = 'wms-manager';
export const useIsWmsManager = () => {
  const user = useUser();
  return user && user.roles && user.roles.includes(KC_ROLE_WMS_MANAGER);
};

const KC_ROLE_DEFAULT_EZOM = 'default-roles-ezom';
export const useIsEzomUser = () => {
  const user = useUser();
  return user && user.roles && user.roles.includes(KC_ROLE_DEFAULT_EZOM);
};

export const useOrgId = () => {
  const user = useUser();
  return user && user.organisation && user.organisation.id;
};

const KC_ROLE_ACCOUNT_MANAGER = 'account-manager';
export const useIsAccountManager = () => {
  const user = useUser();
  return user && user.roles && user.roles.includes(KC_ROLE_ACCOUNT_MANAGER);
};

export const ORDER_QUERY = gql`
  query orderDetail($storeId: ID!, $id: ID!) {
    order(storeId: $storeId, id: $id) {
      id
      number
      email
      provider
      createdAt
      fulfillmentStatus
      financialStatus
      shippingAddress {
        address1
        address2
        phone
        company
        name
        province
        provinceCode
        city
        zip
        countryCode
      }
      storeId
      note
      lineItems {
        platformId
        storeId
        fulfillmentStatus
        name
        sku
        quantity
        fulfillableQuantity
        variant
        pricePaid
        unitPrice
        variantId
        id
      }
      fulfillments {
        id
        createdAt
        status
        trackingInfo {
          company
          url
          number
        }
        updatedAt
        fulfillmentLineItems {
          id
          quantity
        }
      }
      shippingChannel
    }
  }
`;

export const CANCELLED_OS_STATUSES = ['X', 'D'];

export const ORDER_STATUSES = {
  cancelled: 'cancelled',
  open: 'open',
  closed: 'closed',
};

export const ORIENTATION_LIST = [
  { key: 'landscape', val: 'Landscape' },
  { key: 'portrait', val: 'Portrait' },
];

export const PAPER_SIZE_LIST = [
  'A0',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'A10',
  'A11',
  'A12',
  'A13',
  'B0',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'B6',
  'B7',
  'B8',
  'B9',
  'B10',
  'B11',
  'B12',
  'C0',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',
  'C7',
  'C8',
];

export const TRANSPORTATION_TYPE = {
  A: 'Air',
  S: 'Sea',
  E: 'Express delivery',
  G: 'Truck',
  R: 'Railway',
};

export const TO_WAREHOUSE_TRANSPORTATION_TYPE = {
  C: 'Container',
  E: 'Express delivery',
  G: 'Truck',
};

export const GET_STORES = gql`
  query stores {
    stores {
      id
      isDisabled
      name
      provider
      preference {
        shippingChannelMappings {
          shippingChannel
          warehouseCourier
        }
        warehouseCourierSelection
      }
    }
  }
`;

export const unifiedAlert = (msg) => {
  const message = getAlertMessage(msg, t);
  if (Platform.OS === 'web') {
    alert(message);
  } else {
    Alert.alert(message);
  }
};

export const unifiedConfirm = (title, msg, callback) => {
  if (Platform.OS === 'web') {
    if (confirm(`${title}\n${msg}`)) {
      callback();
    }
  } else {
    Alert.alert(title, msg, [
      // The "Yes" button
      {
        text: 'Yes',
        onPress: callback,
      },
      // The "No" button
      // Does nothing but dismiss the dialog when tapped
      {
        text: 'No',
      },
    ]);
  }
};

export const sanitize = (text) => {
  if (isNil(text)) return '';
  if (typeof text !== 'string') return text;
  let sanitized = text.replaceAll('undefined', '');
  sanitized = sanitized.replaceAll('null', '');
  return sanitized;
};

export const ORDER_FULFILLMENT_STATUSES = {
  in_progress: 'in_progress',
  fulfilled: 'fulfilled',
  partial: 'partial',
  not_eligible: 'not_eligible',
  unfulfilled: 'unfulfilled',
};

export const DEFAULT_COURIER_CODE = 'F687';

export const FULFILL_ORDER = gql`
  mutation fulfillOrder(
    $orderId: ID!
    $fulfilledLineItems: [FulfilledLineItem!]!
    $trackingInfo: TrackingInfoInput
    $notifyCustomer: Boolean!
    $storeId: ID!
  ) {
    fulfillOrder(
      orderId: $orderId
      fulfilledLineItems: $fulfilledLineItems
      trackingInfo: $trackingInfo
      notifyCustomer: $notifyCustomer
      storeId: $storeId
    ) {
      userErrors {
        field
        message
      }
    }
  }
`;

export const ORDER_STOCKS_STATUS = {
  haveAvailableStock: 'in_stock',
  dontHaveAvailableStock: 'out_of_stock',
};

export const unixMillisecondToDate = (t) => {
  if (!t || isNaN(t) || t <= 0) {
    return '';
  }

  return pipe(divide(__, 1000), fromUnixTime, curry(format)(__, 'dd MMM yy', {}))(t);
};

export const unixMillisecondToLastDayOfWeek = (t) => {
  if (!t || isNaN(t) || t <= 0) {
    return '';
  }

  const seconds = divide(__, 1000);
  const toDate = fromUnixTime;
  const formatResult = curry(format)(__, 'yyyy-MM-dd', {});

  const date = toDate(seconds(t));
  const lastDay = lastDayOfWeek(date);

  if (!date || isNaN(date.getTime()) || !lastDay || isNaN(lastDay.getTime())) {
    console.error('Error: Invalid date or last day of week.');
    return '';
  }

  return getDate(lastDay) !== getDate(date)
    ? formatResult(addDays(lastDay, 1))
    : formatResult(lastDay);
};

export const NETWORK_CALL_STATUS = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
};

// cancellation reasons for ebay: https://developer.ebay.com/devzone/post-order/types/CancelReasonEnum.html
export const EBAY_ORDER_CANCEL_REASONS = {
  // EBAY CANCEL REASONS
  ADDRESS_ISSUES: "Issues with buyer's delivery address",
  BUYER_ASKED_CANCEL: 'Buyer asked to cancel',
  BUYER_CANCEL_OR_ADDRESS_ISSUE: 'Buyer requested to cancel or issue with delivery address',
  FOUND_CHEAPER_PRICE: 'Buyer found a cheaper price',
  ORDER_MISTAKE: 'Buyer made a purchasing mistake',
  ORDER_UNPAID: 'Buyer has not paid',
  OTHER: 'Other reason',
  OUT_OF_STOCK_OR_CANNOT_FULFILL: 'Item out of stock or fulfillment issue',
  PRICE_TOO_HIGH: 'Buyer feels the price too high',
  UNKNOWN: 'Unknown reason',
  WONT_ARRIVE_IN_TIME: 'Item will not arrive on time',
  WRONG_PAYMENT_METHOD: 'Buyer used wrong payment method',
  WRONG_SHIPPING_ADDRESS: 'The wrong shipping address is on file for the buyer.',
  WRONG_SHIPPING_METHOD: 'The wrong shipping address is on file for the buyer.',
};

// cancellation reasons for shopify: https://shopify.dev/api/admin-rest/2021-07/resources/order#[post]/admin/api/2021-07/orders/{order_id}/cancel.json
export const SHOPIFY_ORDER_CANCEL_REASONS = {
  // SHOPIFY CANCEL REASONS
  customer: 'Customer changed/canceled order',
  inventory: 'Items unavailable',
  fraud: 'Fradulent order',
  declined: 'Payment declined',
  other: 'Other reason',
};

export const ORDER_CANCEL_REASONS = {
  ...EBAY_ORDER_CANCEL_REASONS,
  ...SHOPIFY_ORDER_CANCEL_REASONS,
};
export const SYNC_ORDERS = gql`
  mutation syncOrders($storeId: ID!, $forceAll: Boolean) {
    syncOrders(storeId: $storeId, forceAll: $forceAll)
  }
`;

export const PRODUCT_SALES_FORCAST = gql`
  query productSalesForecast(
    $storeIds: [ID!]!
    $timeZone: String!
    $forecastPeriod: Int!
    $interval: TimeInterval!
    $smoothPeriod: Int
  ) {
    productSalesForecast(
      storeIds: $storeIds
      timeZone: $timeZone
      smoothPeriod: $smoothPeriod
      forecastPeriod: $forecastPeriod
      interval: $interval
    )
  }
`;

export const STORES = gql`
  query stores {
    stores {
      id
      name
      provider
      apiUrl
      isDisabled
      preference {
        shippingChannelMappings {
          shippingChannel
          warehouseCourier
        }
        warehouseCourierSelection
      }
      shopifyEzomCarrierId
      enableInventorySync
      connectionError
      isReconnected
    }
  }
`;

export const STORE = gql`
  query store($id: ID!) {
    store(id: $id) {
      id
      name
      provider
      apiUrl
      isDisabled
      preference {
        shippingChannelMappings {
          shippingChannel
          warehouseCourier
        }
        warehouseCourierSelection
      }
      shopifyEzomCarrierId
    }
  }
`;

export const SKU_CODES_EXIST = gql`
  query skuCodesExist($skuCodes: [ID!], $productCodes: [ID!], $warehouseAccountId: ID!) {
    skuCodesExist(
      skuCodes: $skuCodes
      productCodes: $productCodes
      warehouseAccountId: $warehouseAccountId
    )
  }
`;

export const getCourierCodeFromMapping = (store, order) => {
  return (
    parseWarehouseCourierValue(
      store?.preference?.shippingChannelMappings?.find(
        (m) => m.shippingChannel === order.shippingChannel,
      )?.warehouseCourier,
    )?.courierCode || DEFAULT_COURIER_CODE
  );
};

export const getWarehouseCodeFromMapping = (store, order) => {
  return parseWarehouseCourierValue(
    store?.preference?.shippingChannelMappings?.find(
      (m) => m.shippingChannel === order.shippingChannel,
    )?.warehouseCourier,
  )?.warehouseCode;
};

export const getWarehouseAccountIdFromMapping = (store, order) => {
  return parseWarehouseCourierValue(
    store?.preference?.shippingChannelMappings?.find(
      (m) => m.shippingChannel === order.shippingChannel,
    )?.warehouseCourier,
  )?.warehouseAccountId;
};

export const asyncEvery = async (predicate, arr) => {
  for (let e of arr) {
    if (!(await predicate(e, arr.indexOf(e)))) return false;
  }
  return true;
};

const rateSchema = Yup.object({
  OriginPostcodeFrom: Yup.string().trim().required('OriginPostcodeFrom is required'),
  OriginPostcodeTo: Yup.string().trim().required('OriginPostcodeTo is required'),
  Carrier: Yup.string().trim().required('Carrier name is required.'),
  Zone: Yup.string().trim().required('Zone is required.'),
  WeightFromKg: Yup.number().min(0).required('WeightFromKg is required and must be a number.'),
  BaseCost: Yup.number().required('BaseCost is required and must be number.'),
  RatePerKg: Yup.number().required('RatePerKg is required and must be number.'),
  MinimumCharge: Yup.number().required('MinimumCharge is required and must be number.'),
});

const deliverySchema = Yup.object({
  Country: Yup.string().trim().required('country is required.'),
  PostcodeFrom: Yup.string().trim().required('postcodeFrom is required.'),
  PostcodeTo: Yup.string().trim().required('postcodeTo is required.'),
  Zone: Yup.string().trim().required('zone is required.'),
  Suburb: Yup.string().trim().nullable(),
  MinBusinessDay: Yup.number().min(0).required('minBusinessDays is required'),
  MaxBusinessDay: Yup.number().min(0).required('minBusinessDays is required'),
});

const lastMileSurchargeSchema = Yup.object({
  Name: Yup.string().trim().required('name is required.'),
  Unit: Yup.string().trim().required('unit is required.'),
  Condition: Yup.string().trim().required('condition is required.'),
  Min: Yup.number().min(0).nullable(),
  Max: Yup.number().min(0).nullable(),
  Fee: Yup.number().min(0).required('fee is required'),
});

const rasSchema = Yup.object({
  Country: Yup.string().trim().required('country is required.'),
  Suburb: Yup.string().trim().required('suburb is required.'),
  State: Yup.string().trim().nullable(),
  Postcode: Yup.string().trim().required('postcode is required.'),
  Charge: Yup.number().min(0).required('charge is required.'),
  Name: Yup.string().trim().nullable(),
});

const mapShippingRates = ({
  OriginCountry,
  OriginPostcodeFrom,
  OriginPostcodeTo,
  Carrier,
  Zone,
  WeightFromKg,
  BaseCost,
  RatePerKg,
  MinimumCharge,
  WeightToKg,
}) => ({
  originCountry: OriginCountry,
  originPostcodeFrom: OriginPostcodeFrom,
  originPostcodeTo: OriginPostcodeTo,
  carrierName: Carrier,
  zone: Zone,
  weightFromKg: parseFloat(WeightFromKg),
  weightToKg: parseFloat(WeightToKg),
  baseCost: parseFloat(BaseCost),
  ratePerKg: parseFloat(RatePerKg),
  minimumCharge: parseFloat(MinimumCharge),
});

const mapDelivery = ({
  CarrierName,
  Country,
  PostcodeFrom,
  PostcodeTo,
  Zone,
  Suburb,
  MinBusinessDay,
  MaxBusinessDay,
}) => ({
  country: Country,
  carrierName: CarrierName,
  postcodeFrom: PostcodeFrom,
  postcodeTo: PostcodeTo,
  zone: Zone,
  suburb: Suburb,
  minBusinessDays: parseInt(MinBusinessDay),
  maxBusinessDays: parseInt(MaxBusinessDay),
});

const mapLastMileSurcharge = ({
  CarrierName,
  Name,
  Country,
  Unit,
  Condition,
  Min,
  Max,
  Fee,
  AdditionalCharge,
}) => ({
  carrierName: CarrierName,
  country: Country,
  name: Name,
  unit: Unit,
  condition: Condition,
  min: parseFloat(Min),
  max: parseFloat(Max),
  fee: parseFloat(Fee),
  additionalCharge: (AdditionalCharge && AdditionalCharge.toLowerCase() === 'yes') || false,
});

const mapRsaCharge = ({ CarrierName, Name, Country, Suburb, State, Charge, Postcode }) => ({
  carrierName: CarrierName,
  country: Country,
  name: Name,
  suburb: Suburb,
  state: State,
  postcode: Postcode,
  charge: parseFloat(Charge),
});

export const parseShippingRateSheet = (sheets) => {
  let shippingRates = [];
  let deliveryZones = [];
  let lastMileSurcharges = [];
  let rasCharges = [];
  let errors = [];
  let e;

  Object.keys(sheets).forEach((name) => {
    sheets[name].forEach((record, rowInd) => {
      if (rateSchema.isValidSync(record)) {
        shippingRates.push(mapShippingRates(record));
      } else if (deliverySchema.isValidSync(record)) {
        deliveryZones.push(mapDelivery({ CarrierName: name, ...record }));
      } else if (lastMileSurchargeSchema.isValidSync(record)) {
        lastMileSurcharges.push(
          mapLastMileSurcharge({ CarrierName: name.split(' Charge')[0], ...record }),
        );
      } else if (rasSchema.isValidSync(record)) {
        rasCharges.push(mapRsaCharge({ CarrierName: name.split(' RAS')[0], ...record }));
      } else {
        errors.push(`Invalid format in row ${rowInd + 2} in sheet "${name}"`);
      }
    });
  });
  return { shippingRates, deliveryZones, lastMileSurcharges, rasCharges, errors };
};

export const shippingRatesToExcel = async (
  shippingRates,
  deliveryZones,
  surcharges,
  rasCharges,
) => {
  const XLSX = await import('xlsx/xlsx.mjs');

  const workbook = XLSX.utils.book_new();

  // remove __typename
  const rates = (shippingRates || []).map(({ __typename, ...o }) => ({
    OriginCountry: o.originCountry,
    OriginPostcodeFrom: o.originPostcodeFrom,
    OriginPostcodeTo: o.originPostcodeTo,
    Carrier: o.carrierName,
    Zone: o.zone,
    WeightFromKg: o.weightFromKg,
    WeightToKg: o.weightToKg,
    BaseCost: o.baseCost,
    RatePerKg: o.ratePerKg,
    MinimumCharge: o.minimumCharge,
  }));

  // add shipping rates into sheet 'Rate'
  XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(rates), 'Rate');

  // group delivery data by carrier name
  const deliverDurationsByCarrierDict = (deliveryZones || []).reduce(
    (currentDict, { __typename, carrierName, ...o }) =>
      !currentDict.hasOwnProperty(carrierName)
        ? {
            ...currentDict,
            [carrierName]: [
              {
                Country: o.country,
                Suburb: o.suburb,
                PostcodeFrom: o.postcodeFrom,
                PostcodeTo: o.postcodeTo,
                Zone: o.zone,
                MinBusinessDay: o.minBusinessDays,
                MaxBusinessDay: o.maxBusinessDays,
              },
            ],
          }
        : {
            ...currentDict,
            [carrierName]: currentDict[carrierName].concat([
              {
                Country: o.country,
                Suburb: o.suburb,
                PostcodeFrom: o.postcodeFrom,
                PostcodeTo: o.postcodeTo,
                Zone: o.zone,
                MinBusinessDay: o.minBusinessDays,
                MaxBusinessDay: o.maxBusinessDays,
              },
            ]),
          },
    {},
  );

  // each sheet contains a list of delivery zones for a carrier, add each sheet to the workbook
  Object.keys(deliverDurationsByCarrierDict).map((carrier) => {
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(deliverDurationsByCarrierDict[carrier]),
      carrier,
    );
  });

  // group carrier surcharges by carrier name
  const surchargeByCarrierDict = (surcharges || []).reduce(
    (currentDict, { __typename, carrierName, ...o }) => {
      const sheetName = `${carrierName} Charges`;

      let additionalCharge;
      if (typeof o.additionalCharge == 'boolean') {
        additionalCharge = o.additionalCharge ? 'yes' : 'no';
      } else {
        additionalCharge = '';
      }

      return !currentDict.hasOwnProperty(sheetName)
        ? {
            ...currentDict,
            [sheetName]: [
              {
                Name: o.name,
                Country: o.country,
                Unit: o.unit,
                Condition: o.condition,
                Min: o.min,
                Max: o.max,
                Fee: o.fee,
                AdditionalCharge: additionalCharge,
              },
            ],
          }
        : {
            ...currentDict,
            [sheetName]: currentDict[sheetName].concat([
              {
                Name: o.name,
                Country: o.country,
                Unit: o.unit,
                Condition: o.condition,
                Min: o.min,
                Max: o.max,
                Fee: o.fee,
                AdditionalCharge: additionalCharge,
              },
            ]),
          };
    },
    {},
  );

  // there might be sheets contains a list of surcharges from carriers, add each sheet to the workbook
  Object.keys(surchargeByCarrierDict).map((sheet) => {
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(surchargeByCarrierDict[sheet]),
      sheet,
    );
  });

  // group carrier surcharges by carrier name
  const rasChargsByCarrierDict = (rasCharges || []).reduce(
    (currentDict, { __typename, carrierName, ...o }) => {
      const sheetName = `${carrierName} RAS`;
      return !currentDict.hasOwnProperty(sheetName)
        ? {
            ...currentDict,
            [sheetName]: [
              {
                Country: o.country,
                Suburb: o.suburb,
                State: o.state,
                Postcode: o.postcode,
                Charge: o.charge,
                Name: o.name,
              },
            ],
          }
        : {
            ...currentDict,
            [sheetName]: currentDict[sheetName].concat([
              {
                Country: o.country,
                Suburb: o.suburb,
                State: o.state,
                Postcode: o.postcode,
                Charge: o.charge,
                Name: o.name,
              },
            ]),
          };
    },
    {},
  );

  // there might be sheets contains a list of surcharges from carriers, add each sheet to the workbook
  Object.keys(rasChargsByCarrierDict).map((sheet) => {
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(rasChargsByCarrierDict[sheet]),
      sheet,
    );
  });

  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  return wbout;
};

export const jsonToExcel = async (data, filename) => {
  const XLSX = await import('xlsx/xlsx.mjs');
  const sheets = Object.keys(data).reduce((wb, sheetName) => {
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(data[sheetName], {
        defval: null,
        rawNumbers: true,
        raw: false,
      }),
      sheetName,
    );
    return wb;
  }, XLSX.utils.book_new());

  XLSX.writeFile(sheets, filename);
};

export const base64toBlob = (base64Data) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
};

export const printBase64Pdf = (base64Pdf) => {
  const blob = base64toBlob(base64Pdf);
  const blobURL = URL.createObjectURL(blob);

  const iframe = document.createElement('iframe'); //load content in an iframe to print later
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 10);
  };
};

export function downloadBase64Image(base64String, fileName) {
  // Create a blob from the Base64 string
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/png' }); // Adjust type if needed, e.g., 'image/jpeg'

  // Create a link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName || 'image.png';

  // Append the link, trigger the click, and remove the link
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const generateNanoIdByCustomAlphabet = () => {
  return customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6)();
};

export const formatInventoryLocation = (item) =>
  item.location_aisle && item.location_unit && item.location_shelf && item.location_unit
    ? `${item.location_aisle.toString().padStart(3, '0')}.${item.location_unit
        .toString()
        .padStart(2, '0')}.${item.location_shelf}.${item.location_section}`
    : '';

export const useOnBarcodeScanned = (callback, observants = []) => {
  useEffect(() => {
    const listener = async function (evt) {
      // evt.preventDefault();
      if (evt.code == 'Enter') {
        if (barcode) {
          callback(barcode);
        }
        barcode = '';
        return;
      }
      barcode += evt.key;
    };
    let barcode = '';
    document.addEventListener('keypress', listener);
    return () => {
      document.removeEventListener('keypress', listener);
    };
  }, observants);
};

export const playSuccessSoundAsync = async () => {
  const { sound } = await Audio.Sound.createAsync(
    require('../../../assets/audio/music_marimba_chord.wav'),
  );
  await sound.playAsync();
};

export const playErrorSoundAsync = async () => {
  const { sound } = await Audio.Sound.createAsync(
    require('../../../assets/audio/mixkit-wrong-electricity-buzz.wav'),
  );
  await sound.playAsync();
};

export const useSearchHsCode = () => {
  const client = useApolloClient();
  return useCallback(async (query) => {
    if (!query) return [];
    const {
      data: { hscode },
    } = await client.query({
      query: gql`
        query hscode($query: String!) {
          hscode(searchTerm: $query) {
            HS
            description
          }
        }
      `,
      variables: {
        query,
      },
    });
    return hscode;
  }, []);
};
