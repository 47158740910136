import { Card, Button, Divider, Avatar, IconButton, Menu, Portal, Modal } from 'react-native-paper';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Formik } from 'formik';
import { USER_SETTINGS } from 'src/core/user-settings';
import { isNil, omit } from 'ramda';
import { TextInputField } from 'src/components/input/TextInputField';
import { useIsMobile } from '../../core/responsive.utils';
import { gql, useMutation } from '@apollo/client';
import OrderCancelForm from '../../components/OrderCancelForm';
import { unifiedAlert } from '../../core/utils/utils';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  card: {
    marginRight: '0.4em',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    overflow: 'hidden',
    maxHeight: 'calc(100vh - 64px)',
  },

  desktopCard: {
    minWidth: '420px',
    width: '25%',
    alignSelf: 'stretch',
  },

  mobileCard: {
    marginBottom: '1em',
    width: '90%',
  },

  menuListIcons: {
    margin: 0,
    alignItems: 'start',
  },

  cardContent: {
    flex: 1,
    overflowY: 'auto',
    paddingRight: 8,
    paddingBottom: 16,
    maxHeight: 'calc(100vh - 120px)',
  },

  field: {
    minWidth: 'auto',
    marginVertical: 8
  },

  cardActions: {
    justifyContent: 'center',
    padding: '16px',
    borderTop: '1px solid #eee',
  },
});

const UPDATE_ORDER = gql`
  mutation updateOrder($input: OrderInput!, $storeId: ID!) {
    updateOrder(input: $input, storeId: $storeId) {
      order {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const TOGGLE_STATUS_FULFILLED = gql`
  mutation toggleStatusFulfilled(
    $id: ID!
    $storeId: ID!
    $currentStatus: String
    $newStatus: String
  ) {
    toggleStatusFulfilled(
      id: $id
      storeId: $storeId
      currentStatus: $currentStatus
      newStatus: $newStatus
    )
  }
`;

export default ({
  order,
  noTitle = false,
  onFulfillOrder,
  onSelfCollectOrder,
  navigation,
  actionsAllowed = true,
}) => {
  const isMobile = useIsMobile();
  const [visibleActionMenu, setVisibleActionMenu] = React.useState(false);
  const [updateOrder, { loading: updateOrderLoading, error: updateOrderError }] = useMutation(
    UPDATE_ORDER,
    { refetchQueries: ['ordersToDispatch'] },
  );

  const [toggleStatusFulfilled] = useMutation(TOGGLE_STATUS_FULFILLED, {
    refetchQueries: ['ordersToDispatch'],
  });

  const isReadOnlyProvider =
    !order.provider ||
    order.provider === STORE_PROVIDERS.EBAY ||
    order.provider === STORE_PROVIDERS.MAGENTO2 ||
    order.provider === STORE_PROVIDERS.ETSY;
  const [isCancelFormOpen, setCancelFormOpen] = React.useState(false);

  const isMarkAsFulfilled = `${order.fulfillmentStatus}`.toLowerCase() !== 'fulfilled';
  const isResetFulfilled = !isMarkAsFulfilled && !isNil(order.baseFulfillmentStatus);
  const { t } = useTranslation();

  return (
    <>
      {actionsAllowed && navigation ? (
        <Portal>
          <Modal visible={isCancelFormOpen} onDismiss={() => setCancelFormOpen(false)}>
            <OrderCancelForm
              onDismiss={() => setCancelFormOpen(false)}
              onSubmit={() => alert('submit')}
              order={order}
              navigation={navigation}></OrderCancelForm>
          </Modal>
        </Portal>
      ) : null}
      <Formik
        initialValues={order}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const {
              data: {
                updateOrder: { userErrors },
              },
            } = await updateOrder({
              variables: {
                input: {
                  id: order.id,
                  email: values.email,
                  note: values.note,
                  shippingAddress: omit(['__typename'], values.shippingAddress),
                },
                storeId: order.storeId,
              },
            });
            if (userErrors && userErrors.length > 0) {
              throw new Error(
                t('order.orderShippingDetailsForm.failedToUpdateOrder') +
                  userErrors.map(({ message }) => message).join('\n'),
              );
            }
            unifiedAlert(t('order.orderShippingDetailsForm.successfullyUpdatedOrderDetails'));
          } catch (e) {
            unifiedAlert(e.message);
          } finally {
            setSubmitting(false);
          }
        }}>
        {(formProps) => {
          return (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              {noTitle ? null : (
                <Card.Title
                  title={t('order.orderShippingDetailsForm.orderDetail')}
                  subtitle={`${order?.provider} ${t(
                    'order.orderShippingDetailsForm.orderNumber',
                  )}: ${order?.displayId}`}
                  left={(props) => <Avatar.Icon {...props} icon="receipt" />}
                  right={
                    !actionsAllowed
                      ? null
                      : (props) => (
                          <Menu
                            visible={visibleActionMenu}
                            onDismiss={() => setVisibleActionMenu(false)}
                            anchor={
                              <IconButton
                                {...props}
                                icon="dots-vertical"
                                onPress={() => setVisibleActionMenu(true)}
                              />
                            }>
                            {(onFulfillOrder && (
                              <Menu.Item
                                icon="truck"
                                onPress={() => {
                                  setVisibleActionMenu(false);
                                  onFulfillOrder();
                                }}
                                title={t('order.orderShippingDetailsForm.fulfillOrder')}
                              />
                            )) ||
                              null}

                            {(navigation && USER_SETTINGS.localPickupEnabled && (
                              <Menu.Item
                                icon="forklift"
                                onPress={() => {
                                  setVisibleActionMenu(false);
                                  onSelfCollectOrder();
                                }}
                                title={t('order.orderShippingDetailsForm.selfCollect')}
                              />
                            )) ||
                              null}
                            {(isMarkAsFulfilled || isResetFulfilled) && (
                              <Menu.Item
                                icon={
                                  isResetFulfilled ? 'refresh-circle' : 'checkbox-marked-circle'
                                }
                                onPress={async () => {
                                  try {
                                    setVisibleActionMenu(false);
                                    let payload = {
                                      id: order.id,
                                      storeId: order.storeId,
                                      currentStatus: order.fulfillmentStatus,
                                      newStatus: 'fulfilled',
                                    };
                                    if (isResetFulfilled) {
                                      payload = {
                                        id: order.id,
                                        storeId: order.storeId,
                                        newStatus: order.baseFulfillmentStatus,
                                      };
                                    }
                                    const res = await toggleStatusFulfilled({
                                      variables: payload,
                                    });
                                    if (!res?.data?.toggleStatusFulfilled) {
                                      unifiedAlert(t('order.orderShippingDetailsForm.failed'));
                                    }
                                  } catch (error) {
                                    unifiedAlert(error.message);
                                  }
                                }}
                                title={
                                  isResetFulfilled
                                    ? t('order.orderShippingDetailsForm.resetFulfillmentStatus')
                                    : t('order.orderShippingDetailsForm.markOrderFulfilled')
                                }
                              />
                            )}

                            <Divider />
                            <Menu.Item
                              icon="delete"
                              onPress={() => {
                                setVisibleActionMenu(false);
                                setCancelFormOpen(true);
                              }}
                              title={t('order.orderShippingDetailsForm.cancelOrder')}
                            />
                          </Menu>
                        )
                  }
                />
              )}

              <Card.Content style={styles.cardContent}>
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.orderNo')}
                  name="displayId"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.orderDate')}
                  name="createdAt"
                  disabled={true}
                  style={styles.field}
                  value={new Date(formProps.values.createdAt).toLocaleString()}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.paymentStatus')}
                  name="financialStatus"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.fulfillmentStatus')}
                  name="fulfillmentStatus"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.salesChannel')}
                  name="provider"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.email')}
                  name="email"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.houseNumber')}
                  name="shippingAddress.houseNumber"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.addressLine1')}
                  name="shippingAddress.address1"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.addressLine2')}
                  name="shippingAddress.address2"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.name')}
                  name="shippingAddress.name"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.phone')}
                  name="shippingAddress.phone"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.state')}
                  name="shippingAddress.province"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.stateCode')}
                  name="shippingAddress.provinceCode"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.postalCode')}
                  name="shippingAddress.zip"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.city')}
                  name="shippingAddress.city"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                {/* FIXME
                  Note should be modifiable
                  At this moment, temporarily disable note for Ebay
                  A further look into the API about what is supported again will be put back
               */}
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.country')}
                  name="shippingAddress.countryCode"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label={t('order.orderShippingDetailsForm.note')}
                  textStyle={{ minHeight: 64 }}
                  multiline
                  name="note"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
              </Card.Content>

              {!isReadOnlyProvider && (
                <Card.Actions style={styles.cardActions}>
                  <Button
                    onPress={formProps.submitForm}
                    mode="contained"
                    loading={formProps.isSubmitting}
                    disabled={formProps.isSubmitting || !formProps.dirty}>
                    {t('order.orderShippingDetailsForm.saveChanges')}
                  </Button>
                </Card.Actions>
              )}
            </Card>
          );
        }}
      </Formik>
    </>
  );
};
