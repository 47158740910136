import { nanoid } from 'nanoid';
import { clone, either, isEmpty, isNil, pipe, trim } from 'ramda';
import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, Text, TextInput, View } from 'react-native';
import { Button, IconButton, useTheme } from 'react-native-paper';

// check string is not null, trim and it is not empty string
const isBlankString = either(isNil, pipe(trim, isEmpty));
export const filterValue = (data) => {
  let newData = clone(data);
  newData = newData.filter(
    (item, index, self) =>
      index ===
      self.findIndex((obj) => `${obj.value}`.toLowerCase() === `${item.value}`.toLowerCase()) &&
      !isBlankString(item.value),
  );
  return newData;
};
const TextAreaItemList = ({ data, setData, resetData }) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();
  const inputRefs = useRef({});

  const renderLineNumber = (value) => {
    return (
      <Text
        style={{
          padding: 5,
          color: theme.colors.placeholder,
        }}>{`${value}.`}</Text>
    );
  };

  const focusInput = (index) => {
    setTimeout(() => {
      const currentValue = inputRefs.current[index]?.value;
      // make sure cursor in end of input
      inputRefs.current[index].setSelectionRange(currentValue?.length, currentValue?.length);
      inputRefs.current[index]?.focus?.();
    }, 10);
  };

  const commonInputProp = ({ value, index }) => ({
    multiline: true,
    value,
    ref: (el) => {
      inputRefs.current[index] = el;
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onBlur: () => {
      setIsFocused(false);
    },
    style: {
      flex: 1,
      color: '#000000',
      backgroundColor: 'transparent',
      paddingHorizontal: 0,
      paddingVertical: 5,
      outlineStyle: 'none',
    },
    onChangeText: (text) => {
      if (text.includes('\n')) {
        const values = text.split('\n');
        setData((oldData) => {
          const newData = clone(oldData);
          values.forEach((item, i) => {
            if (i === 0) {
              newData[index].value = item;
            } else {
              newData.splice(index + i, 0, { key: nanoid(), value: item });
            }
          });
          return newData;
        });
        focusInput(index + values.length - 1);
      } else {
        setData((oldData) => {
          const newData = clone(oldData);
          newData[index].value = text;
          return newData;
        });
      }
    },
  });

  const renderInput = ({ value, index, key }) => {
    return (
      <View
        key={key}
        onTouchStart={() => {
          inputRefs.current[index]?.focus?.();
        }}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: index === 0 ? 10 : undefined,
          backgroundColor: index % 2 !== 0 ? '#f7f7f7' : '#ffffff',
        }}>
        {renderLineNumber(index + 1)}
        <TextInput
          scrollEnabled={false}
          autoFocus={isNil(value)}
          numberOfLines={1}
          {...commonInputProp({ index, value })}
        />
        <IconButton
          onPress={() => {
            setData((oldData) => {
              const newData = clone(oldData);
              newData.splice(index, 1);
              return newData;
            });
          }}
          size={15}
          color={theme.colors.placeholder}
          icon={'close-circle'}></IconButton>
      </View>
    );
  };

  const initInputHaveValue = data.length === 1 && !isBlankString(data[0].value);

  const renderMainInput = ({ index, value, key }) => {
    return (
      <View
        key={key}
        style={{
          flexDirection: 'row',
          flex: 1,
          alignItems: 'flex-start',
          borderRadius: 10,
        }}>
        {renderLineNumber(index + 1)}
        <TextInput
          scrollEnabled={false}
          placeholder={t('components.input.textArea.placeholder')}
          numberOfLines={12}
          {...commonInputProp({ index, value })}
        />
        {value && (
          <IconButton
            size={15}
            color={theme.colors.placeholder}
            icon={'close-circle'}
            onPress={() => {
              setData((oldData) => {
                const newData = clone(oldData);
                newData[index].value = '';
                return newData;
              });
            }}></IconButton>
        )}
      </View>
    );
  };

  const filterHandle = () => {
    //remove duplicate value
    setData((oldData) => {
      const newData = filterValue(oldData);
      focusInput(newData.length - 1);
      return newData;
    });
  };

  return (
    <View
      style={{
        borderWidth: 2,
        borderColor: isFocused ? theme.colors.primary : '#c7c7c7',
        borderRadius: 10,
        height: 240,
      }}>
      <ScrollView
        style={{ flex: 1, borderRadius: 10 }}
        keyboardShouldPersistTaps="always"
        scrollEnabled={data.length > 1 ? true : false}>
        {data.map(({ key, value }, index) => {
          return data.length === 1
            ? renderMainInput({ index, value, key })
            : renderInput({ index, value, key });
        })}
      </ScrollView>
      {(data.length > 1 || initInputHaveValue) && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
          }}>
          <Button
            onPress={filterHandle}
            mode="outlined"
            icon={'filter'}
            style={{ flex: 1, marginHorizontal: 10 }}>
            {t('components.input.textArea.filter')}
          </Button>
          <Button
            mode="outlined"
            icon={'trash-can'}
            style={{ flex: 1, marginHorizontal: 10 }}
            onPress={() => {
              resetData();
              focusInput(0);
            }}>
            {t('components.input.textArea.removeAll')}
          </Button>
        </View>
      )}
    </View>
  );
};

export default memo(TextAreaItemList);
