import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Dimensions } from 'react-native';
import Background from 'src/components/Background';
import { useIsMobile } from '../../core/responsive.utils';
import { Card, Divider, Avatar, Portal, Modal, FAB } from 'react-native-paper';
import { Layout } from '@ui-kitten/components';
import { UIStatusWrapper } from '../../components/ui-status';
import Table from '../../components/Table';
import RowDetail from '../../components/RowDetail';
import { OC_STATUS_MAP, UPDATABLE_OC_STATUSES } from '@ezom/library/lib/cjs/constants';
import { outboundOrderStore, warehousesStore } from '../../store';
import { observer } from 'mobx-react';
import { OC_TYPE_MAP } from '@ezom/library/lib/cjs/constants';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { paperNativeTheme } from '../../core/theme';
import CreateOutboundForm from '../../components/CreateOutboundForm';
import TransactionDetail from '../../components/TransactionDetail';
import { useTranslation } from 'react-i18next';

const windowHeight = Dimensions.get('window').height;

const OutboundDetailScreen = observer(({ navigation, route }) => {
  const { t } = useTranslation();
  const decodedId = (route?.params?.id && decodeURIComponent(route?.params?.id)) || null;
  const warehouseAccountId = route?.params?.warehouseAccountId;

  const recipientInfo = [
    {
      label: t('inventory.outboundDetail.fields.recipient.lastName'),
      key: 'last_name',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.firstName'),
      key: 'first_name',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.company'),
      key: 'company',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.phone'),
      key: 'phone',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.email'),
      key: 'email',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.postcode'),
      key: 'post_code',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.houseNumber'),
      key: 'house_number',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.street'),
      key: 'street',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.district'),
      key: 'district',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.city'),
      key: 'city',
    },

    {
      label: t('inventory.outboundDetail.fields.recipient.state'),
      key: 'state',
    },
    {
      label: t('inventory.outboundDetail.fields.recipient.country'),
      key: 'country',
    },
  ];

  const commonInfos = [
    {
      label: t('inventory.outboundDetail.fields.consignmentNo'),
      key: 'consignment_no',
    },
    {
      label: t('inventory.outboundDetail.fields.consignmentType'),
      key: 'consignment_type',
      format: (type) => OC_TYPE_MAP[type] || type,
    },
    {
      label: t('inventory.outboundDetail.fields.status'),
      key: 'status',
      format: (status) => OC_STATUS_MAP[status] || status,
    },
    {
      label: t('inventory.outboundDetail.fields.exceptionReason'),
      key: 'exceptionReason',
    },
    {
      label: t('inventory.outboundDetail.fields.warehouse'),
      key: 'from_warehouse_code',
      format: (w) => {
        return warehousesStore.getWarehouseNameEn(w, warehouseAccountId);
      },
    },
    {
      label: t('inventory.outboundDetail.fields.transportType'),
      key: 'transport_type',
    },
    {
      label: t('inventory.outboundDetail.fields.refNo'),
      key: 'ref_no',
    },
    {
      label: t('inventory.outboundDetail.fields.salesNo'),
      key: 'sales_no',
    },
    {
      label: t('inventory.outboundDetail.fields.salesPlatform'),
      key: 'sales_platform',
    },
    {
      label: t('inventory.outboundDetail.fields.totalWeight'),
      key: 'total_weight',
      format: weightGramToKg(true),
    },
    {
      label: t('inventory.outboundDetail.fields.shippingWeight'),
      key: 'shipping_weight',
      format: weightGramToKg(true),
    },
    {
      label: t('inventory.outboundDetail.fields.chargeableWeight'),
      key: 'billing_weight',
      format: weightGramToKg(true),
    },
    {
      label: t('inventory.outboundDetail.fields.totalVolume'),
      key: 'total_volume',
      format: volumeCmToMetre(true),
    },
    {
      label: t('inventory.outboundDetail.fields.note'),
      key: 'remark',
    },
    {
      label: t('inventory.outboundDetail.fields.signatureServices'),
      key: 'signature_service',
    },
    {
      label: t('inventory.outboundDetail.fields.trackingNo'),
      key: 'shipping_no',
    },
    {
      label: t('inventory.outboundDetail.fields.insuranceType'),
      key: 'insure_services',
    },
    {
      label: t('inventory.outboundDetail.fields.insuredAmount'),
      key: 'insure_value',
    },
    {
      label: t('inventory.outboundDetail.fields.createTime'),
      key: 'create_time',
      format: (number) => new Date(Number(number)).toLocaleString(),
    },
    {
      label: t('inventory.outboundDetail.fields.logisticsCode'),
      key: 'logistics_product_code',
    },
    {
      label: t('inventory.outboundDetail.fields.logisticsProvider'),
      key: 'logistics_provider',
    },
  ];

  const [detail, setDetail] = useState(null);
  if (route.params.id !== decodedId) {
    navigation.setParams({ id: decodedId });
    return null;
  }

  const { id } = route.params || {};
  useEffect(() => {
    (async () => {
      const oc = await outboundOrderStore.getOutboundOrder(warehouseAccountId, id);
      setDetail(oc);
    })();
  }, [warehouseAccountId, id]);

  const isMobile = useIsMobile();

  const fabActions = React.useMemo(() => {
    const actions = [];
    if (detail && UPDATABLE_OC_STATUSES.includes(detail.status)) {
      actions.push({
        icon: 'pencil',
        label: t('inventory.outboundDetail.actions.edit'),
        onPress: () => setUpdateOcFormOpen(true),
      });
    }
    return actions;
  }, [detail, detail?.status]);

  const [fabOpen, setFabOpen] = React.useState(false);
  const [updateOcFormOpen, setUpdateOcFormOpen] = React.useState(false);

  return (
    <Background fullWidth={true}>
      <UIStatusWrapper
        status={{
          indeterminate: !detail,
        }}>
        <ScrollView
          style={[styles.container, isMobile ? styles.mobileContainer : styles.desktopContainer]}>
          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title={t('inventory.outboundDetail.commonInfo')}
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(0, Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }, index) => (
                    <RowDetail
                      key={`${key}-${index}`}
                      label={label}
                      value={detail && detail[key]}
                      format={format}
                    />
                  ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }, index) => (
                    <RowDetail
                      key={`${key}-${index}`}
                      label={label}
                      value={detail && detail[key]}
                      format={format}
                    />
                  ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title={t('inventory.outboundDetail.recipientInfo')}
              left={(props) => <Avatar.Icon {...props} icon={'account'} />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {recipientInfo
                  .slice(0, Math.ceil(recipientInfo.length / 2))
                  .map(({ key, label }, index) => (
                    <RowDetail
                      key={`${key}-${index}`}
                      label={label}
                      value={detail && detail[key]}
                    />
                  ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {recipientInfo.slice(Math.ceil(recipientInfo.length / 2)).map(({ key, label }) => (
                  <RowDetail key={`${label}-${key}`} label={label} value={detail && detail[key]} />
                ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title={t('inventory.outboundDetail.outboundSkuList')}
              left={(props) => <Avatar.Icon {...props} icon={'table'} />}
            />
            <Divider />
            <Card.Content>
              <Table
                items={detail?.outboundlist_sku}
                displayKeys={['sku_id', 'sku_name', 'sku_code', 'qty']}
                titleByKey={{
                  sku_id: t('inventory.outboundDetail.table.sku.id'),
                  sku_name: t('inventory.outboundDetail.table.sku.name'),
                  sku_code: t('inventory.outboundDetail.table.sku.code'),
                  qty: t('inventory.outboundDetail.table.sku.quantity'),
                }}
              />
            </Card.Content>
          </Card>
          {detail?.transactions && detail.transactions.length > 0 ? (
            detail.transactions.map((transaction, index) => (
              <TransactionDetail key={transaction.id || index} transaction={transaction} />
            ))
          ) : (
            <TransactionDetail transaction={{}} />
          )}
        </ScrollView>
        <FAB.Group
          visible={fabActions.length > 0}
          isFabVisible={true}
          fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
          open={fabOpen}
          icon={fabOpen ? 'chevron-down' : 'chevron-up'}
          actions={fabActions}
          onStateChange={({ open }) => setFabOpen(open)}
        />
      </UIStatusWrapper>
      <Portal>
        <Modal
          visible={updateOcFormOpen}
          onDismiss={() => setUpdateOcFormOpen(false)}
          contentContainerStyle={styles.modalStyle}>
          <CreateOutboundForm
            onDismiss={() => setUpdateOcFormOpen(false)}
            warehouseAccountId={warehouseAccountId}
            onSubmit={() => {
              setUpdateOcFormOpen(false);
              setTimeout(async () => {
                navigation.navigate('OutboundConsignments');
              }, 2000);
            }}
            updateMode={true}
            initialConsignment={{
              consignment_no: detail?.consignment_no,
              refNo: detail?.ref_no,
              sales_no: detail?.sales_no,
              note: detail?.remark,
              address: {
                country: detail?.country,
                name: detail?.first_name + ' ' + detail?.last_name,
                state: detail?.state,
                city: detail?.city,
                phone: detail?.phone,
                email: detail?.email,
                post_code: detail?.post_code,
                company: detail?.company,
                street: detail?.street,
              },
              warehouse: detail?.from_warehouse_code,
              courier: detail?.logistics_product_code,
              skus: detail?.outboundlist_sku.map((sku) => ({
                warehouseSku: sku.sku_code,
                qty: sku.qty,
              })),
            }}
          />
        </Modal>
      </Portal>
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: {
    backgroundColor: 'white',
    margin: 20,
    alignSelf: 'center',
    minWidth: 500,
  },
  card: {
    marginHorizontal: '1em',
    marginVertical: '1em',
  },

  desktopCard: {
    marginHorizontal: '0.8em',
  },

  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  desktopColumn: {
    width: '50%',
    flex: 1,
    flexDirection: 'column',
  },

  mobileColumn: {
    width: '100%',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  container: {
    overflowY: 'auto',
  },

  mobileContainer: {
    height: 0.82 * windowHeight,
  },

  desktopContainer: {
    height: 0.9 * windowHeight,
  },
});
export default OutboundDetailScreen;
