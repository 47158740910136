import React, { memo } from 'react';
import Background from 'src/components/Background';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { paperNativeTheme, uiKittenTheme } from '../core/theme';
import { Banner } from 'react-native-paper';
import { removeAuthToken } from 'src/core/cache';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  header: {
    fontSize: 22,
    color: paperNativeTheme.colors.danger,
    fontWeight: 'bold',
    marginBottom: 8,
    alignSelf: 'center',
  },

  errorBanner: {
    width: '80%',
    backgroundColor: uiKittenTheme['color-danger-100'],
    flex: 'column',
  },

  errorBannerContent: {
    width: '100%',
  },

  errorDetailContent: {
    fontStyle: 'italic',
  },
  errorTitleContent: {
    fontWeight: 'bold',
    marginVertical: 8,
  },
});

const NetworkErrorScreen = ({ errorMsg, errorDetail }) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  return (
    <Background>
      <Banner
        style={styles.errorBanner}
        visible="true"
        actions={[
          {
            label: t('error.backToLogin'),
            onPress: () => {
              removeAuthToken();
              keycloak.logout();
            },
          },
        ]}>
        <View style={styles.errorBannerContent}>
          <Text style={styles.header}>{t('networkError')}</Text>
          <Text style={styles.errorTitleContent}>{t(errorMsg)}</Text>
          <Text style={styles.errorDetailContent}>{t(errorDetail)}</Text>
        </View>
      </Banner>
    </Background>
  );
};

export default memo(NetworkErrorScreen);
