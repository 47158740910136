import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import { clone } from 'ramda';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { ActivityIndicator, Card, Chip, IconButton, useTheme } from 'react-native-paper';
import Background from '../../components/Background';
import BatchTrackingShipmentSelectionModal from '../../components/CourierShipments/BatchTrackingShipmentSelectionModal';
import TextAreaItemList, { filterValue } from '../../components/input/TextAreaItemList';
import { unifiedAlert } from '../../core/utils/utils';
import { courierShipmentSelectionTableStore } from '../../store';

const INIT_DATA = [{ key: 'init', value: '' }];
const localStorageKey = 'history_tracking';
const TrackingShipments = observer(() => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [data, setData] = useState(INIT_DATA);
  const [visibleSelectFromList, setVisibleSelectFromList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const { t } = useTranslation();

  const onTrack = async () => {
    try {
      setLoading(true);
      const dataToTrack = filterValue(data).map((item) => item.value);
      if (dataToTrack.length === 0) {
        unifiedAlert(t('shipments.trackingShipments.incorrectNumber'));
        return;
      }
      courierShipmentSelectionTableStore.setTrackingNumbers(dataToTrack);
      navigation.navigate('TrackingShipmentResults');
    } catch (e) {
      unifiedAlert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const selectFromList = () => {
    setVisibleSelectFromList(true);
  };

  const renderTrackPage = () => {
    return (
      <View
        style={{
          width: '100%',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
        }}>
        <BatchTrackingShipmentSelectionModal
          visible={visibleSelectFromList}
          onClose={() => {
            setVisibleSelectFromList(false);
          }}
          onSubmit={(selectedData) => {
            setData((oldData) => {
              const newData = clone(oldData);
              const itemData = selectedData.map((item) => ({ key: nanoid(), value: item }));
              if (oldData.length === 1 && !oldData[0].value) {
                return itemData;
              }
              newData.push(...itemData);
              return newData;
            });
            setVisibleSelectFromList(false);
          }}
        />
        <View
          style={{
            flex: 1,
            width: '80%',
            maxWidth: 650,
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}>
          <View style={{ flex: 1 }}>
            <TextAreaItemList
              data={data}
              setData={setData}
              resetData={() => {
                setData(INIT_DATA);
              }}
            />
          </View>
          <View style={{ marginHorizontal: 10, justifyContent: 'space-between', height: '100%' }}>
            <TouchableWithoutFeedback disabled={loading} onPress={selectFromList}>
              <View
                style={{
                  height: 110,
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  width: 110,
                  marginHorizontal: 10,
                  borderRadius: 10,
                  borderColor: theme.colors.primary,
                  borderWidth: 2,
                }}>
                <IconButton
                  onPress={selectFromList}
                  icon={'format-list-bulleted-square'}
                  size={28}
                  color={theme.colors.primary}
                />
                <Text style={{ color: theme.colors.primary, textAlign: 'center' }}>
                  {t('shipments.trackingShipments.selectFromList')}
                </Text>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback disabled={loading} onPress={onTrack}>
              <View
                style={{
                  cursor: 'pointer',
                  height: 110,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 110,
                  borderRadius: 10,
                  marginHorizontal: 10,
                  backgroundColor: theme.colors.primary,
                }}>
                {loading ? (
                  <ActivityIndicator />
                ) : (
                  <IconButton onPress={onTrack} icon={'text-box-search'} size={28} color="white" />
                )}
                <Text style={{ color: 'white' }}>{t('shipments.trackingShipments.track')}</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    );
  };

  useEffect(() => {
    const history = localStorage.getItem(localStorageKey);
    let historyData = [];
    if (history) {
      try {
        historyData = JSON.parse(history);
      } catch (e) {
        console.log(e);
      }
      setHistory(historyData || []);
    }
  }, []);

  const renderHistory = () => {
    if (!history.length) return null;
    return (
      <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingVertical: 10,
            width: '80%',
            maxWidth: 650,
          }}>
          <Text style={{ margin: 10 }}>{t('shipments.trackingShipments.history')}</Text>
          {history.map((item, index) => (
            <Chip
              onClose={() => {
                const newHistory = clone(history);
                newHistory.splice(index, 1);
                localStorage.setItem(localStorageKey, JSON.stringify(newHistory));
                setHistory(newHistory);
              }}
              style={{ margin: 5 }}
              ellipsizeMode="middle"
              textStyle={{ maxWidth: 200 }}
              onPress={() =>
                setData(`${item}`.split(', ').map((i) => ({ key: nanoid(), value: i })))
              }>
              {`${item}`}
            </Chip>
          ))}
        </View>
      </View>
    );
  };

  return (
    <Background fullWidth={true}>
      <Card>
        {renderTrackPage()}
        {renderHistory()}
      </Card>
    </Background>
  );
});

export default memo(TrackingShipments);
