import {
  CARRIER_ALLOW_PRINT_MANIFESTS,
  SHIPMENT_ORDER_STATES,
  SHIPMENT_ORDER_STATE_MAP,
} from '@ezom/library/lib/cjs/constants';
import { useIsFocused } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import { Button } from '@ui-kitten/components';
import { format } from 'date-fns';
import * as Linking from 'expo-linking';
import { observer } from 'mobx-react';
import { all, sum } from 'ramda';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { Card, Modal, Portal } from 'react-native-paper';
import Background from '../../components/Background';
import CourierShipmentListControl from '../../components/CourierShipmentListControl';
import CourierShipmentStaging from '../../components/CourierShipmentStaging';
import CreateShipmentForm from '../../components/CreateShipmentForm';
import CsvUploader from '../../components/CsvUploader';
import EditShipmentForm from '../../components/EditShipmentForm';
import Table from '../../components/Table';
import { UIStatusWrapper } from '../../components/ui-status';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import exportCsv from '../../core/csv';
import { useIsMobile } from '../../core/responsive.utils';
import {
  generateNanoIdByCustomAlphabet,
  printBase64Pdf,
  unifiedAlert,
  unifiedConfirm,
} from '../../core/utils/utils';
import {
  courierShipmentSelectionTableStore,
  courierShipmentStore,
  courierShipmentTableStore,
  courierStore,
  warehouseAccountStore,
} from '../../store';
import { CourierShipmentStore } from '../../store/CourierShipmentStore';

export const getId = (item) => item.id;
const selectedShipmentOrders = (shipmentOrders, selectedIds) =>
  shipmentOrders.filter((o) => selectedIds.has(getId(o)));
const CANCELLABLE_STATUS = [SHIPMENT_ORDER_STATES.Draft];
export const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

const CourierShipmentBulkUploadModal = observer(
  ({ contentContainerStyle, visible, onDismiss, onSubmit }) => {
    const [stagedShipmentOrders, setStagedShipmentOrders] = React.useState([]);
    const [shipmentOrderErrors, setShipmentOrderErrors] = React.useState({});
    const onUploadCSV = async (data) => {
      const mappedShipmentOrders = CourierShipmentStore.MapCsvUploadedShipmentOrders(data);
      setStagedShipmentOrders(mappedShipmentOrders);
      setShipmentOrderErrors(
        await courierShipmentStore.validateCsvUploadedShipmentOrders(
          warehouseAccountStore.selectedWarehouseAccount?.id,
          mappedShipmentOrders,
        ),
      );
    };
    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          {stagedShipmentOrders && stagedShipmentOrders.length == 0 ? (
            <>
              {/* Force to read the first sheet */}
              <CsvUploader onDismiss={onDismiss} onSubmit={onUploadCSV} sheetIndices={[0]} />
              <Button
                appearance="ghost"
                style={styles.button}
                onPress={() =>
                  Linking.openURL(
                    require('../../spreadsheetTemplates/CourierShipmentTemplate.xlsx'),
                  )
                }>
                Download Template
              </Button>
            </>
          ) : (
            <CourierShipmentStaging
              shipmentOrders={stagedShipmentOrders}
              onClearOrders={() => setStagedShipmentOrders([])}
              errors={shipmentOrderErrors}
              onSubmit={async () => {
                await onSubmit(stagedShipmentOrders);
                setStagedShipmentOrders([]);
              }}
            />
          )}
        </Modal>
      </Portal>
    );
  },
);

const CourierShipmentBulkBatchSubmitModal = observer(
  ({ contentContainerStyle, visible, onDismiss, onSubmit }) => {
    const onUploadCSV = async (data) => {
      const mappedBatchSubmitShipmentOrderIds = data.map((o) => o['ID']);
      onSubmit(mappedBatchSubmitShipmentOrderIds);
    };
    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          <>
            {/* Force to read the first sheet */}
            <CsvUploader onDismiss={onDismiss} onSubmit={onUploadCSV} sheetIndices={[0]} />
            <Button
              appearance="ghost"
              style={styles.button}
              onPress={() =>
                Linking.openURL(
                  require('../../spreadsheetTemplates/BatchSubmitCourierShipmentsTemplate.xlsx'),
                )
              }>
              Download Template
            </Button>
          </>
        </Modal>
      </Portal>
    );
  },
);

export default observer(() => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const isMobile = useIsMobile();
  const [duplicateCourierShipmentModal, setDuplicateCourierShipmentModal] = React.useState({
    data: null,
    isOpen: false,
  });
  const [editShipmentModal, setEditShipmentModal] = React.useState({
    data: null,
    isOpen: false,
  });
  const [shipmentCourierList, setShipmentCourierList] = React.useState([]);

  const handleCloseDuplicateCourierShipmentModal = () => {
    setDuplicateCourierShipmentModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleOpenDuplicateCourierShipmentModal = (data) => {
    setDuplicateCourierShipmentModal(() => ({ isOpen: true, data }));
  };

  const loadData = async () => {
    await courierShipmentTableStore.fetchItems();
    const courierList = await courierStore.fetchShipmentOrderCourierList(
      warehouseAccountStore.selectedWarehouseAccount.id,
    );
    setShipmentCourierList(courierList);
  };

  React.useEffect(() => {
    if (isFocused && warehouseAccountStore.selectedWarehouseAccount) {
      loadData();
    }
  }, [isFocused, warehouseAccountStore.selectedWarehouseAccount]);

  return (
    <Background fullWidth={true}>
      <WarehouseAccountSelectionModal
        visible={!warehouseAccountStore.selectedWarehouseAccount}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
        }}
        onCancel={() => navigation.navigate('DashboardScreen')}
      />
      <CourierShipmentListControl
        filterParams={courierShipmentTableStore.filterParams}
        indicators={getIndicators(courierShipmentTableStore.filterParams)}
        onSearchTermChange={courierShipmentTableStore.setSearchTerm}
        onFilterClear={() => {
          courierShipmentTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          courierShipmentTableStore.setFilterParams({
            ...courierShipmentTableStore.filterParams,
            ...params,
          });
        }}
        courierList={shipmentCourierList}
        sortOptions={courierShipmentTableStore.sortOption}
        onSortChange={courierShipmentTableStore.setSortOption}
        disableSearch={
          courierShipmentTableStore.loading || !warehouseAccountStore.selectedWarehouseAccount
        }
        requireButtonClickToSearch={courierShipmentTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: courierShipmentTableStore.error,
            indeterminate:
              courierShipmentTableStore.loading || !warehouseAccountStore.selectedWarehouseAccount,
          }}>
          <Table
            heightOffset={300}
            items={courierShipmentTableStore.items}
            getId={getId}
            displayKeys={courierShipmentTableStore.DISPLAY_KEYS}
            titleByKey={courierShipmentTableStore.TITLE_BY_KEY}
            formatterByKey={courierShipmentTableStore.FORMATTER_BY_KEY}
            rowOnClick={(item) => {
              navigation.navigate('ShipmentOrderDetail', {
                id: item.id,
                warehouseAccountId: warehouseAccountStore.selectedWarehouseAccount?.id,
              });
            }}
            sort="single"
            isFabVisible={useIsFocused()}
            currentPage={courierShipmentTableStore.currentPage}
            onPageChange={(page) => {
              courierShipmentTableStore.setCurrentPage(page);
            }}
            totalItemNum={courierShipmentTableStore.total}
            itemsPerPage={courierShipmentTableStore.pageSize}
            setPageSize={courierShipmentTableStore.setPageSize}
            fabActions={(selectedIds, selectedRows) => {
              const { t } = useTranslation();
              const actions = [];
              actions.push({
                icon: 'radar',
                label: t('shipments.courierShipment.actions.track'),
                onPress: async () => {
                  if (selectedRows.length > 0) {
                    courierShipmentSelectionTableStore.setTrackingNumbers(
                      selectedRows.map((item) => item.shipments[0].tracking_number),
                    );
                    navigation.navigate('TrackingShipmentResults');
                  } else {
                    navigation.navigate('TrackingShipments');
                  }
                },
              });
              actions.push({
                icon: 'upload',
                label: t('shipments.courierShipment.actions.batchSubmit'),
                onPress: () => {
                  courierShipmentTableStore.toggleBatchSubmitShipmentModal(true);
                },
              });
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'plus',
                  label: t('shipments.courierShipment.actions.add'),
                  onPress: () => {
                    navigation.navigate('NewShipment');
                  },
                });
                actions.push({
                  icon: 'download-outline',
                  label: t('shipments.courierShipment.actions.export.all'),
                  onPress: async () => {
                    await courierShipmentTableStore.fetchItems(true);
                    const shipmentOrders = courierShipmentTableStore.exportedItems.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders =
                      shipmentOrders?.length > 0 ? Object.keys(shipmentOrders[0]) : [];
                    exportCsv(shipmentOrders, csvHeaders, 'Shipment orders.csv');
                  },
                });
                actions.push({
                  icon: 'file-upload-outline',
                  label: t('shipments.courierShipment.actions.uploadBatch'),
                  onPress: () => {
                    courierShipmentTableStore.openCsvModal();
                  },
                });
              }
              if (selectedIds.size === 1) {
                actions.push({
                  icon: 'content-copy',
                  label: t('shipments.courierShipment.actions.duplicate'),
                  onPress: () => {
                    handleOpenDuplicateCourierShipmentModal(
                      selectedShipmentOrders(courierShipmentTableStore.items, selectedIds)[0],
                    );
                  },
                });
              }
              if (selectedIds.size > 0) {
                if (
                  all(
                    (item) => CANCELLABLE_STATUS.includes(item.status),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'cancel',
                    label: t(
                      `shipments.courierShipment.actions.cancel.${
                        selectedIds.size > 1 ? 'multiple' : 'single'
                      }`,
                    ),
                    onPress: () => {
                      unifiedConfirm(
                        t('shipments.courierShipment.modals.cancel.title'),
                        t('shipments.courierShipment.modals.cancel.message'),
                        async () => {
                          const res = await Promise.all(
                            [...selectedIds].map(async (id) => {
                              try {
                                await courierShipmentStore.cancelItem(
                                  warehouseAccountStore.selectedWarehouseAccount?.id,
                                  id,
                                );
                                return { id };
                              } catch (e) {
                                return { error: e, id };
                              }
                            }),
                          );
                          const message = res
                            .map((r) =>
                              r.error
                                ? t('shipments.courierShipment.messages.cancelFailed', {
                                    id: r.id,
                                    error: r.error.message,
                                  })
                                : t('shipments.courierShipment.messages.cancelSuccess', {
                                    id: r.id,
                                  }),
                            )
                            .join('\n');
                          unifiedAlert(message);
                          courierShipmentTableStore.fetchItems();
                        },
                      );
                    },
                  });
                }
                if (
                  all(
                    (item) => [SHIPMENT_ORDER_STATES.Draft].includes(item.status),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'truck-check',
                    label: t(
                      `shipments.courierShipment.actions.submit.${
                        selectedIds.size > 1 ? 'multiple' : 'single'
                      }`,
                    ),
                    onPress: () => {
                      unifiedConfirm(
                        t('shipments.courierShipment.modals.batchSubmit.title'),
                        t('shipments.courierShipment.modals.batchSubmit.message', {
                          shipmentIds: [...selectedIds].join('\n'),
                        }),
                        async () => {
                          try {
                            const results = await courierShipmentStore.submitItems(
                              warehouseAccountStore.selectedWarehouseAccount?.id,
                              [...selectedIds],
                            );

                            let message = results
                              .map((r) =>
                                r.error || r.id === ''
                                  ? t('shipments.courierShipment.messages.submitFailed', {
                                      id: r.id ?? '',
                                      error: r.error,
                                    })
                                  : t('shipments.courierShipment.messages.submitSuccess', {
                                      id: r.id,
                                    }),
                              )
                              .join('\n');
                            const totalCharge = sum(
                              results.map((r) => r.createTransaction?.amount || 0),
                            );
                            message += t('shipments.courierShipment.messages.submitCost', {
                              amount: totalCharge,
                            });
                            unifiedAlert(message);
                            courierShipmentTableStore.fetchItems();
                          } catch (e) {
                            unifiedAlert(
                              t('shipments.courierShipment.messages.submitOrdersFailed', {
                                error: e.message,
                              }),
                            );
                          }
                        },
                      );
                    },
                  });
                }
                actions.push({
                  icon: 'download-outline',
                  label: t(
                    `shipments.courierShipment.actions.export.selected.${
                      selectedIds.size > 1 ? 'multiple' : 'single'
                    }`,
                  ),
                  onPress: () => {
                    const shipmentOrders = selectedShipmentOrders(
                      courierShipmentTableStore.items,
                      selectedIds,
                    );
                    const expoertedShipmentOrders = shipmentOrders.map(
                      ({ __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders =
                      expoertedShipmentOrders?.length > 0
                        ? Object.keys(expoertedShipmentOrders[0])
                        : [];
                    exportCsv(expoertedShipmentOrders, csvHeaders, 'Shipment orders.csv');
                  },
                });
                if (
                  all(
                    (item) =>
                      [SHIPMENT_ORDER_STATES.Draft, SHIPMENT_ORDER_STATES.Submitted].includes(
                        item.status,
                      ),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'printer',
                    label: t(
                      `shipments.courierShipment.actions.print.labels.${
                        selectedIds.size > 1 ? 'multiple' : 'single'
                      }`,
                    ),
                    onPress: async () => {
                      try {
                        const labels = await courierShipmentStore.printLabels(
                          warehouseAccountStore.selectedWarehouseAccount?.id,
                          [...selectedIds],
                        );
                        for (let label of labels) {
                          printBase64Pdf(label);
                        }
                      } catch (e) {
                        unifiedAlert(e);
                      }
                    },
                  });
                }

                if (
                  all(
                    (item) => [SHIPMENT_ORDER_STATES.Submitted].includes(item.status),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  ) &&
                  all(
                    (item) =>
                      all(
                        (s) => CARRIER_ALLOW_PRINT_MANIFESTS.includes(s.logistics_provider),
                        item.shipments,
                      ),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'printer',
                    label: t(
                      `shipments.courierShipment.actions.print.manifests.${
                        selectedIds.size > 1 ? 'multiple' : 'single'
                      }`,
                    ),
                    onPress: async () => {
                      try {
                        const labels = await courierShipmentStore.printManifests(
                          warehouseAccountStore.selectedWarehouseAccount?.id,
                          [...selectedIds],
                        );
                        for (let label of labels) {
                          printBase64Pdf(label);
                        }
                      } catch (e) {
                        unifiedAlert(e);
                      }
                    },
                  });
                }
                if (
                  all(
                    (item) => item.status === SHIPMENT_ORDER_STATES.Draft,
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'delete',
                    label: t(
                      `shipments.courierShipment.actions.delete.${
                        selectedIds.size > 1 ? 'multiple' : 'single'
                      }`,
                    ),
                    onPress: () => {
                      unifiedConfirm(
                        t('shipments.courierShipment.modals.delete.message'),
                        [...selectedIds].join('\n'),
                        async () => {
                          const res = await Promise.all(
                            [...selectedIds].map(async (id) => {
                              try {
                                await courierShipmentStore.deleteDraftItems(
                                  warehouseAccountStore.selectedWarehouseAccount?.id,
                                  [id],
                                );
                                return { id };
                              } catch (e) {
                                return { error: e, id };
                              }
                            }),
                          );
                          const message = res
                            .map((r) =>
                              r.error
                                ? t('shipments.courierShipment.messages.deleteFailed', {
                                    id: r.id,
                                    error: r.error.message,
                                  })
                                : t('shipments.courierShipment.messages.deleteSuccess', {
                                    id: r.id,
                                  }),
                            )
                            .join('\n');
                          unifiedAlert(message);
                          courierShipmentTableStore.fetchItems();
                        },
                      );
                    },
                  });
                }
                if (
                  selectedIds.size === 1 &&
                  all(
                    (item) => item.status === SHIPMENT_ORDER_STATES.Draft,
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'pencil',
                    label: 'Edit draft order',
                    onPress: () => {
                      setEditShipmentModal({
                        isOpen: true,
                        data: selectedShipmentOrders(
                          courierShipmentTableStore.items,
                          selectedIds,
                        )[0],
                      });
                    },
                  });
                }
              }
              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>

      <CourierShipmentBulkUploadModal
        contentContainerStyle={styles.modalStyle}
        visible={courierShipmentTableStore.csvModalOpen}
        onDismiss={courierShipmentTableStore.closeCsvModal}
        onSubmit={async (courierShipments) => {
          try {
            const res = await courierShipmentTableStore.dataStore.batchAddItems(
              warehouseAccountStore.selectedWarehouseAccount?.id,
              courierShipments.map(({ shipment, ...s }) => ({
                ...s,
                shipments: [shipment],
              })),
            );
            if (res && res.length > 0) {
              unifiedAlert(`Created ${res.length} shipment(s)`);
              // Refetch  after 500ms
              setTimeout(() => courierShipmentTableStore.fetchItems(), 500);
            } else {
              throw new Error('Failed to create shipments');
            }

            courierShipmentTableStore.closeCsvModal();
          } catch (e) {
            unifiedAlert(e.message);
          }
        }}
      />

      <Portal>
        <Modal
          visible={editShipmentModal.isOpen}
          onDismiss={() => setEditShipmentModal({ data: null, isOpen: false })}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
          <ScrollView>
            <EditShipmentForm
              data={editShipmentModal.data}
              warehouseAccountId={warehouseAccountStore.selectedWarehouseAccount?.id}
              onSuccess={() => {
                setEditShipmentModal({ data: null, isOpen: false });
                courierShipmentTableStore.fetchItems();
              }}
            />
          </ScrollView>
        </Modal>
      </Portal>

      <CourierShipmentBulkBatchSubmitModal
        contentContainerStyle={styles.modalStyle}
        visible={courierShipmentTableStore.batchSubmitShipmentOpen}
        onDismiss={() => courierShipmentTableStore.toggleBatchSubmitShipmentModal(false)}
        onSubmit={async (courierShipmentIds) => {
          unifiedConfirm(
            `Are you sure you want to submit the shipments with: ` + courierShipmentIds.join('\n'),
            '\nShipments cannot be cancelled after submission.',
            async () => {
              const res = [];
              for (let i = 0; i < courierShipmentIds.length; i++) {
                const id = courierShipmentIds[i];
                try {
                  const [submittedItem] = await courierShipmentStore.submitItems(
                    warehouseAccountStore.selectedWarehouseAccount?.id,
                    [id],
                  );
                  const { createTransaction } = submittedItem;
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                  res.push({ id, amount: createTransaction.amount });
                } catch (e) {
                  res.push({ error: e, id });
                }
              }

              let message = res
                .map((r) =>
                  r.error ? `Failed to submit ${r.id}, ${r.error.message}` : `${r.id} submitted.`,
                )
                .join('\n');
              const totalCharge = sum(res.map((r) => r.amount || 0));
              message += ` Cost is $${totalCharge}.`;

              unifiedAlert(message);
              courierShipmentTableStore.fetchItems();
            },
          );
          courierShipmentTableStore.toggleBatchSubmitShipmentModal(false);
        }}
      />

      <Portal>
        <Modal
          visible={duplicateCourierShipmentModal.isOpen}
          onDismiss={() => handleCloseDuplicateCourierShipmentModal()}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
          <ScrollView>
            <CreateShipmentForm
              onSuccess={() => {
                handleCloseDuplicateCourierShipmentModal();
                courierShipmentTableStore.fetchItems();
              }}
              data={{
                ...duplicateCourierShipmentModal.data,
                ref_no:
                  duplicateCourierShipmentModal.data?.ref_no +
                  '_' +
                  generateNanoIdByCustomAlphabet(),
              }}
              warehouseAccountId={warehouseAccountStore.selectedWarehouseAccount?.id}
            />
          </ScrollView>
        </Modal>
      </Portal>
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: {
    backgroundColor: 'white',
    margin: 20,
    alignSelf: 'center',
    minWidth: 500,
  },
  dialogueStyle: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },

  mobileModalStyle: { backgroundColor: 'white', margin: 20, padding: 10 },
  modalStyle: {
    maxHeight: '95vh',
    backgroundColor: 'white',
    marginHorizontal: 'auto',
    width: '90%',
    maxWidth: 1680,
    padding: 10,
    margin: 40,
  },
});

export const getIndicators = (params) => {
  const { t } = useTranslation();
  const dateFormatter = (date) => format(date, 'd MMM y');
  let indicators = [];

  if (params.consignmentNumber) {
    indicators.push(
      t('shipments.courierShipment.filters.consignmentNo', { value: params.consignmentNumber }),
    );
  } else if (params.refNumber) {
    indicators.push(t('shipments.courierShipment.filters.refNo', { value: params.refNumber }));
  } else if (params.shippingNumber) {
    indicators.push(
      t('shipments.courierShipment.filters.trackingNo', { value: params.shippingNumber }),
    );
  } else {
    if (params.status) {
      indicators.push(
        t('shipments.courierShipment.filters.status', {
          value: SHIPMENT_ORDER_STATE_MAP[params.status],
        }),
      );
    }
    if (params.courier && params.courier.length > 0) {
      indicators.push(
        t('shipments.courierShipment.filters.courier', {
          value: Array.isArray(params.courier) ? params.courier.join(', ') : params.courier,
        }),
      );
    }
    if (params.sku_code) {
      indicators.push(t('shipments.courierShipment.filters.sku', { value: params.sku_code }));
    }

    if (params.create_time_start && params.create_time_end) {
      indicators.push(
        t('shipments.courierShipment.filters.dateRange.fromTo', {
          startDate: dateFormatter(params.create_time_start),
          endDate: dateFormatter(params.create_time_end),
        }),
      );
    } else if (params.create_time_start) {
      indicators.push(
        t('shipments.courierShipment.filters.dateRange.from', {
          date: dateFormatter(params.create_time_start),
        }),
      );
    } else if (params.create_time_end) {
      indicators.push(
        t('shipments.courierShipment.filters.dateRange.to', {
          date: dateFormatter(params.create_time_end),
        }),
      );
    }
  }

  return indicators;
};
