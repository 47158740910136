import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, ScrollView, TouchableWithoutFeedback, View } from 'react-native';
import { Button, Card, Chip, Text } from 'react-native-paper';
import {
  getId,
  getIndicators,
  MAX_NO_OF_ITEMS_AUTO_SEARCH,
} from '../../screens/shipments/CourierShipmentList';
import {
  courierShipmentSelectionTableStore,
  courierStore,
  warehouseAccountStore,
} from '../../store';
import CourierShipmentListControl from '../CourierShipmentListControl';
import Table from '../Table';

const BatchTrackingShipmentSelection = observer(
  ({ selectedRows: selectedRowsProps = undefined, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const windowHeight = Dimensions.get('window').height;
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [selectedRows, setSelectedRows] = useState([]);

    const [shipmentCourierList, setShipmentCourierList] = useState([]);

    useEffect(() => {
      if (selectedRowsProps?.length) {
        setSelectedIds(new Set(selectedRowsProps.map(getId)));
        setSelectedRows(selectedRowsProps);
      }
    }, [selectedRowsProps?.length]);

    useEffect(() => {
      if (warehouseAccountStore.selectedWarehouseAccount) {
        courierShipmentSelectionTableStore.fetchItems();
        (async () => {
          const courierList = await courierStore.fetchShipmentOrderCourierList(
            warehouseAccountStore.selectedWarehouseAccount.id,
          );
          setShipmentCourierList(courierList);
        })();
      }
    }, [warehouseAccountStore.selectedWarehouseAccount]);

    const isSelectedOrder = [...selectedIds].length > 0;

    return (
      <View>
        {isSelectedOrder ? (
          <ScrollView
            style={{ maxHeight: 100, marginBottom: 10, flex: 1 }}
            contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <TouchableWithoutFeedback
              onPress={() => {
                setSelectedIds(new Set());
                setSelectedRows([]);
              }}>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginHorizontal: 10,
                  cursor: 'pointer',
                }}>
                <Text
                  style={{
                    color: 'red',
                    paddingRight: 10,
                    textDecorationLine: 'underline',
                    textDecorationColor: 'red',
                  }}>
                  {t('components.batchTracking.clearAllSelected')}
                </Text>
              </View>
            </TouchableWithoutFeedback>

            {[...selectedRows].map(({ id, shipments }) => {
              return (
                <Chip
                  style={{ margin: 5 }}
                  onClose={() => {
                    setSelectedIds((prev) => {
                      const newSet = new Set(prev);
                      newSet.delete(id);
                      return newSet;
                    });
                    setSelectedRows(selectedRows.filter((r) => getId(r) !== id));
                  }}>
                  {shipments?.[0]?.tracking_number}
                </Chip>
              );
            })}
          </ScrollView>
        ) : (
          <Text style={{ color: 'red' }}>{t('components.batchTracking.pleaseSelect')}</Text>
        )}

        <CourierShipmentListControl
          filterParams={courierShipmentSelectionTableStore.filterParams}
          indicators={getIndicators(courierShipmentSelectionTableStore.filterParams)}
          onSearchTermChange={courierShipmentSelectionTableStore.setSearchTerm}
          onFilterClear={() => {
            courierShipmentSelectionTableStore.resetFilterParams();
          }}
          onFilterChange={(params) => {
            courierShipmentSelectionTableStore.setFilterParams({
              ...courierShipmentSelectionTableStore.filterParams,
              ...params,
            });
          }}
          courierList={shipmentCourierList}
          sortOptions={courierShipmentSelectionTableStore.sortOption}
          onSortChange={courierShipmentSelectionTableStore.setSortOption}
          disableSearch={
            courierShipmentSelectionTableStore.loading ||
            !warehouseAccountStore.selectedWarehouseAccount
          }
          requireButtonClickToSearch={
            courierShipmentSelectionTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH
          }
        />
        <Card style={{ marginBottom: 20 }}>
          <Table
            keepSelectedWhenPageChange
            selectedRows={selectedRows}
            onSelectedRowsChange={({ selectedIds, selectedRows }) => {
              setSelectedIds(selectedIds);
              setSelectedRows(selectedRows);
            }}
            error={courierShipmentSelectionTableStore.error}
            loading={
              courierShipmentSelectionTableStore.loading ||
              !warehouseAccountStore.selectedWarehouseAccount
            }
            // because this modal with height 90% => heightOffset = 10% + 300 px of another component
            heightOffset={400 + (windowHeight * 10) / 100}
            items={courierShipmentSelectionTableStore.items}
            getId={getId}
            displayKeys={courierShipmentSelectionTableStore.DISPLAY_KEYS}
            titleByKey={courierShipmentSelectionTableStore.TITLE_BY_KEY}
            formatterByKey={courierShipmentSelectionTableStore.FORMATTER_BY_KEY}
            sort="single"
            isFabVisible={false}
            currentPage={courierShipmentSelectionTableStore.currentPage}
            onPageChange={(page) => {
              courierShipmentSelectionTableStore.setCurrentPage(page);
            }}
            totalItemNum={courierShipmentSelectionTableStore.total}
            itemsPerPage={courierShipmentSelectionTableStore.pageSize}
            setPageSize={courierShipmentSelectionTableStore.setPageSize}
          />
        </Card>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
            marginHorizontal: 10,
          }}>
          <Button mode="outlined" icon={'close'} onPress={onClose}>
            {t('components.batchTracking.close')}
          </Button>
          <Button
            icon={'check'}
            mode="contained"
            disabled={!isSelectedOrder}
            onPress={async () => {
              const selectedTrackingNumber = selectedRows.map(
                (item) => item.shipments?.[0]?.tracking_number,
              );
              onSubmit(selectedTrackingNumber);
            }}>
            {t('components.batchTracking.submit')}
          </Button>
        </View>
      </View>
    );
  },
);

export default BatchTrackingShipmentSelection;
