import React, { memo, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { Card as KittenCard } from '@ui-kitten/components/ui/card/card.component';
import {
  Layout,
  Text,
  Button as UiKittenButton,
  Spinner,
  Avatar,
  Popover,
  Icon,
} from '@ui-kitten/components';
import NumberFormat from 'react-number-format';
import { gql, useQuery } from '@apollo/client';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';
import { Colors, Divider, List, Modal, Portal } from 'react-native-paper';
import { useIsMobile } from '../../core/responsive.utils';
import { InitiateTransaction } from './InitiateTransaction';
import { StripePaymentButton } from './StripePaymentButton';
import { ezTheme } from '../../core/theme';
import { useTranslation } from 'react-i18next';

const ACCOUNT_BALANCE_BY_ORG = gql`
  query AccountBalanceByOrg($orgId: ID!) {
    accountBalanceByOrg(orgId: $orgId) {
      avaiableBalance
      totalBalance
      onHoldBalance
    }
  }
`;

const ACCOUNT_BALANCE = gql`
  query AccountBalance {
    accountBalance {
      avaiableBalance
      totalBalance
      onHoldBalance
    }
  }
`;

const styles = StyleSheet.create({
  card: {
    marginBottom: '1em',
    border: '1px solid #F2F2F2',
  },

  layoutContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  layout: {
    alignItems: 'center',
    marginBottom: '10px',
  },
  innerLayout: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: { width: 23, height: 23, marginLeft: 10 },

  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20 },
  modalStyle: { backgroundColor: 'white', marginHorizontal: '40%', minWidth: 600 },

  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerControl: {
    marginHorizontal: 2,
  },
});

export const STATUS_BY_BALANCE = {
  WARNING: 500,
  DANGER: 10,
};

const BalanceDisplay = ({ label, value, infoTextCategory, status, hint }) => {
  const { t } = useTranslation();
  return (
    <Layout style={[styles.layout, { paddingHorizontal: 10 }]}>
      <Layout style={styles.innerLayout}>
        <Text
          category={infoTextCategory || 'h4'}
          status={status}
          style={{ paddingHorizontal: 5 }}
          numberOfLines={1}>
          <NumberFormat
            value={value}
            displayType={'text'}
            decimalScale={2}
            thousandSeparator={true}
            prefix={'$ '}
          />
        </Text>
        <Text appearance="hint">&nbsp;{hint}</Text>
      </Layout>
      <Text appearance="label" status={status}>
        {label}
      </Text>
    </Layout>
  );
};

const Cell = ({ value, level, infoTextCategory, hint, onInfoPress }) => {
  const { t } = useTranslation();
  let status;
  let label = t('userAccount.balanceInfo.availableBalance');

  if (value < STATUS_BY_BALANCE.DANGER) {
    status = 'danger';
    label = t('userAccount.balanceInfo.topUpNow');
  } else if (value === 0 || value <= STATUS_BY_BALANCE.WARNING) {
    status = 'warning';
    label = t('userAccount.balanceInfo.topUpSoon');
  } else {
    status = 'success';
  }

  return (
    <Layout style={styles.layoutContainer}>
      <Layout style={styles.layout} level={level || 1}>
        <Layout style={styles.innerLayout} level={level || 1}>
          <Text category={infoTextCategory || 'h4'} status={status}>
            <NumberFormat
              value={value}
              displayType={'text'}
              decimalScale={2}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Text>
          <Text appearance="hint">&nbsp;{hint}</Text>
        </Layout>
        <Text appearance="label" status={status}>
          {label}
        </Text>
      </Layout>
      <TouchableWithoutFeedback onPress={onInfoPress}>
        <Icon fill="#8F9BB3" name="info-outline" style={styles.icon} onPress={onInfoPress} />
      </TouchableWithoutFeedback>
    </Layout>
  );
};

/**
 *
 * @param orgId OrgId is only available for users with account manager role
 * @param refetch toggle this value to refetch balance information
 * @param onlyShowIfBelow Only show the balance information if the amount is less than the given value
 * @param hideAddCreditButton Truthy value will hide the Add Credit button
 * @returns {JSX.Element}
 * @constructor
 */
const BalanceInfo = ({
  orgId,
  refetch,
  onlyShowIfBelow = undefined,
  hideAddCreditButton = false,
}) => {
  const { t } = useTranslation();
  const [addCreditVisible, setAddCreditVisible] = React.useState(false);
  const [visible, setVisible] = useState(false);
  const isMobile = useIsMobile();

  const {
    loading: loadingBalance,
    error: balanceError,
    data: balanceResponse,
    refetch: refetchBalance,
  } = useQuery(
    orgId ? ACCOUNT_BALANCE_BY_ORG : ACCOUNT_BALANCE,
    orgId ? { variables: { orgId } } : undefined,
  );

  React.useEffect(() => {
    refetchBalance();
  }, [refetch]);

  const accountBalance =
    balanceResponse &&
    (orgId ? balanceResponse.accountBalanceByOrg : balanceResponse.accountBalance);
  const { avaiableBalance, totalBalance, onHoldBalance } = accountBalance || {};

  if (onlyShowIfBelow) {
    if (loadingBalance || balanceError) {
      return null;
    } else if (avaiableBalance !== undefined && avaiableBalance >= onlyShowIfBelow) {
      return null;
    }
  }

  const renderToggleButton = () => (
    <Layout>
      <Cell
        value={avaiableBalance}
        hint="AUD"
        infoTextCategory="h2"
        onInfoPress={() => {
          setVisible(true);
        }}
      />
    </Layout>
  );

  return (
    <KittenCard style={styles.titleCard} status="info" disabled={true}>
      <Portal>
        <Modal
          visible={addCreditVisible}
          onDismiss={() => setAddCreditVisible(false)}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
          <InitiateTransaction />
        </Modal>
      </Portal>

      <UIStatusWrapper status={new UIStatus(loadingBalance, balanceError)}>
        <Popover
          anchor={renderToggleButton}
          visible={visible}
          backdropStyle={{ backgroundColor: ezTheme.backdropModalColor }}
          onBackdropPress={() => setVisible(false)}>
          <Layout
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 20,
              paddingVertical: 10,
            }}>
            <BalanceDisplay
              hint={t('userAccount.balanceInfo.currency')}
              infoTextCategory={'h6'}
              label={t('userAccount.balanceInfo.totalBalance')}
              status={'success'}
              value={totalBalance}
            />
            <View
              style={{
                backgroundColor: 'gray',
                width: 1,
                marginHorizontal: 10,
                height: 50,
              }}></View>
            <BalanceDisplay
              hint={t('userAccount.balanceInfo.currency')}
              infoTextCategory={'h6'}
              label={t('userAccount.balanceInfo.onHoldBalance')}
              status={'basic'}
              value={onHoldBalance}
            />
          </Layout>
        </Popover>

        {!hideAddCreditButton && (
          <StripePaymentButton
            title={t('userAccount.balanceInfo.addCredit')}
            onPress={() => {
              setAddCreditVisible(true);
            }}
          />
        )}
      </UIStatusWrapper>
    </KittenCard>
  );
};

export default memo(BalanceInfo);
