import * as React from 'react';
import { Avatar, Card, FAB } from 'react-native-paper';
import { ScrollView, StyleSheet, Dimensions } from 'react-native';
import { observer } from 'mobx-react';
import { paperNativeTheme } from 'src/core/theme';
import Table from '../../components/Table';
import Background from '../../components/Background';
import { Divider, Layout, Text } from '@ui-kitten/components';
import DevanningAppointmentModal from '../../components/DevanningAppointmentModal';
import LabelPrintModal from '../../components/LabelPrintModal';
import { useIsMobile } from 'src/core/responsive.utils';
import { UIStatusWrapper } from '../../components/ui-status';
import RowDetail from '../../components/RowDetail';
import { TC_STATUS_CODE, TC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import { TRANSPORTATION_TYPE } from 'src/core/utils/utils';
import { transferOrderStore, transferOrderTableStore, warehousesStore } from '../../store';
import ConsignmentReceiveConfirmationModal from '../../components/ConsignmentReceiveConfirmationModal';
import { isNil } from 'ramda';
import ConsignmentPutawayConfirmationModal from '../../components/ConsignmentPutawayConfirmationModal';
import { useIsWmsManager } from '../../core/utils/utils';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { useTranslation } from 'react-i18next';

const windowHeight = Dimensions.get('window').height;

export default observer(({ route }) => {
  const { consignmentNumber, warehouseAccountId } = route?.params || {};
  const { t } = useTranslation();

  const commonInfos = [
    {
      label: t('inventory.transferDetail.consignmentNo'),
      key: 'consignment_no',
    },
    {
      label: t('inventory.transferDetail.status'),
      key: 'status',
      format: (status) => TC_STATUS_MAP[status],
    },
    {
      label: t('inventory.transferDetail.fromWarehouse'),
      key: 'from_warehouse_code',
      format: (w) => warehousesStore.getWarehouseNameEn(w, warehouseAccountId),
    },
    {
      label: t('inventory.transferDetail.toWarehouse'),
      key: 'to_warehouse_code',
      format: (w) => warehousesStore.getWarehouseNameEn(w, warehouseAccountId),
    },
    {
      label: t('inventory.transferDetail.transportation'),
      key: 'transport_type',
      format: (t) => TRANSPORTATION_TYPE[t],
    },
    {
      label: t('inventory.transferDetail.refNo'),
      key: 'ref_no',
    },
    {
      label: t('inventory.transferDetail.totalVolume'),
      key: 'total_volume',
      format: volumeCmToMetre(true),
    },
    {
      label: t('inventory.transferDetail.totalWeight'),
      key: 'total_weight',
      format: weightGramToKg(true),
    },
    {
      label: t('inventory.transferDetail.createTime'),
      key: 'create_time',
      format: (number) => new Date(Number(number)).toLocaleString(),
    },
    {
      label: t('inventory.transferDetail.receiveTime'),
      key: 'receive_time',
      format: (number) => new Date(Number(number)).toLocaleDateString(),
    },
    {
      label: t('inventory.transferDetail.note'),
      key: 'remark',
    },
  ];

  const [detail, setDetail] = React.useState(null);

  const isWmsManager = useIsWmsManager();

  const fetchTransferOrder = React.useCallback(async () => {
    const ic = await transferOrderStore.getTransferOrder(warehouseAccountId, consignmentNumber);
    setDetail(ic);
  });

  React.useEffect(() => {
    (async () => {
      await fetchTransferOrder();
    })();
  }, [warehouseAccountId, consignmentNumber]);

  const isMobile = useIsMobile();

  const [appointmentModalVisible, setAppointmentVisible] = React.useState(false);
  const [labelPrintModalVisible, setLabelPrintModalVisible] = React.useState(false);
  const [receivingModalVisible, setReceivingModalVisible] = React.useState(false);
  const [putawayModalVisible, setPutawayModalVisible] = React.useState(false);

  const [fabOpen, setFabOpen] = React.useState(false);

  const fabActions = React.useMemo(() => {
    const actions = [
      {
        icon: 'printer',
        label: t('inventory.transferDetail.print'),
        onPress: () => setLabelPrintModalVisible(true),
      },
    ];
    if (detail?.status === TC_STATUS_CODE.Approved) {
      actions.push({
        icon: 'calendar',
        label: t('inventory.transferDetail.bookDevanning'),
        onPress: () => setAppointmentVisible(true),
      });
    }
    if (
      [
        TC_STATUS_CODE.Approved,
        TC_STATUS_CODE.Dispatched,
        TC_STATUS_CODE['Booking confirmed'],
        TC_STATUS_CODE.Checked,
      ].includes(detail?.status) &&
      isWmsManager
    ) {
      actions.push({
        icon: 'package-variant',
        label: t('inventory.transferDetail.receive'),
        onPress: () => setReceivingModalVisible(true),
      });
    }
    if (TC_STATUS_CODE.Received === detail?.status && isWmsManager) {
      actions.push({
        icon: 'package-variant',
        label: t('inventory.transferDetail.editReceived'),
        onPress: () => setReceivingModalVisible(true),
      });
      actions.push({
        icon: 'forklift',
        label: t('inventory.transferDetail.putaway'),
        onPress: () => setPutawayModalVisible(true),
      });
    }
    return actions;
  }, [setAppointmentVisible, detail?.status, t]);

  return (
    <Background fullWidth={true}>
      <UIStatusWrapper
        status={{
          indeterminate: !detail,
        }}>
        <ScrollView
          style={[styles.container, isMobile ? styles.mobileContainer : styles.desktopContainer]}>
          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title={t('inventory.transferDetail.commonInfo')}
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(0, Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title={t('inventory.transferDetail.transferSkuList')}
              left={(props) => <Avatar.Icon {...props} icon={'table'} />}
            />
            <Divider />
            <Card.Content>
              <Table
                items={detail?.lstsku}
                displayKeys={[
                  'box_no',
                  'sku_code',
                  'weight',
                  'volume',
                  'plan_qty',
                  'received_qty',
                  'exception_qty',
                  'diff_qty',
                ]}
                titleByKey={{
                  box_no: t('inventory.transferDetail.box'),
                  sku_code: t('inventory.transferDetail.sku'),
                  weight: t('inventory.transferDetail.weight'),
                  volume: t('inventory.transferDetail.volume'),
                  plan_qty: t('inventory.transferDetail.planQuantity'),
                  received_qty: t('inventory.transferDetail.receivedQuantity'),
                  exception_qty: t('inventory.transferDetail.exceptionQuantity'),
                  diff_qty: t('inventory.transferDetail.difference'),
                }}
                formatterByKey={{
                  volume: volumeCmToMetre(true),
                  diff_qty: (_, item) =>
                    isNil(item.plan_qty) || isNil(item.received_qty) ? (
                      ''
                    ) : (
                      <Text status={item.received_qty - item.plan_qty === 0 ? 'success' : 'danger'}>
                        {item.received_qty - item.plan_qty}
                      </Text>
                    ),
                }}
              />
            </Card.Content>
          </Card>
          <DevanningAppointmentModal
            visible={appointmentModalVisible}
            setVisible={setAppointmentVisible}
            consignmentNumbers={consignmentNumber}
          />
          <LabelPrintModal
            warehouseAccountId={warehouseAccountId}
            visible={labelPrintModalVisible}
            setVisible={setLabelPrintModalVisible}
            ic={detail}
          />
        </ScrollView>
        <FAB.Group
          visible={true}
          isFabVisible={true}
          fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
          open={fabOpen}
          icon={fabOpen ? 'chevron-down' : 'chevron-up'}
          actions={fabActions}
          onStateChange={({ open }) => setFabOpen(open)}
        />
        <ConsignmentReceiveConfirmationModal
          visible={receivingModalVisible}
          setVisible={setReceivingModalVisible}
          consignment={detail}
          onSubmit={async (values) => {
            await transferOrderStore.receiveTransferOrder(
              warehouseAccountId,
              consignmentNumber,
              values.receive_time,
              values.lstsku,
            );
            await fetchTransferOrder();
            await transferOrderTableStore.fetchItems();
          }}
        />
        <ConsignmentPutawayConfirmationModal
          visible={putawayModalVisible}
          setVisible={setPutawayModalVisible}
          consignment={detail}
          onSubmit={async (values) => {
            await transferOrderStore.putawayTransferOrder(
              warehouseAccountId,
              consignmentNumber,
              values.lstsku,
            );
            await fetchTransferOrder();
            await transferOrderTableStore.fetchItems();
          }}
        />
      </UIStatusWrapper>
    </Background>
  );
});

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
    marginVertical: '1em',
  },
  desktopCard: {
    marginHorizontal: '0.4em',
    minWidth: '300px',
  },
  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  desktopColumn: {
    width: '50%',
    flex: 1,
    flexDirection: 'column',
  },

  mobileColumn: {
    width: '100%',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  container: {
    overflowY: 'auto',
  },

  mobileContainer: {
    height: 0.82 * windowHeight,
  },

  desktopContainer: {
    height: 0.9 * windowHeight,
  },
});
