import { Text as KittenText } from '@ui-kitten/components';
import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { DataTable, IconButton, useTheme } from 'react-native-paper';
import { downloadBase64Image } from '../../core/utils/utils';
import TrackingShipmentResultsModal from './TrackingShipmentResultsModal';

const styles = StyleSheet.create({
  centerColumn: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topText: {
    paddingBottom: 10,
  },
});

export const getPODFileName = (trackingNumber, shipmentOrderRefNo, fileFormat) => {
  return `POD_${trackingNumber}_${shipmentOrderRefNo}.${fileFormat || 'jpg'}`;
};

export const TrackingShipmentResultsDate = ({ date }) => {
  const theme = useTheme();
  const contentStyle = {
    color: theme.colors.placeholder,
    fontSize: 12,
  };

  return (
    <Text style={contentStyle}>{`${new Date(date).toLocaleDateString()} ${new Date(
      date,
    ).toLocaleTimeString()}`}</Text>
  );
};

export const TrackingShipmentResultsInfo = ({ info }) => {
  const { shipmentOrder, error } = info;
  const {
    pickup_country,
    pickup_state,
    pickup_city,
    pickup_post_code,
    receiver_country,
    receiver_state,
    receiver_city,
    receiver_post_code,
  } = shipmentOrder || {};
  return (
    <View>
      {error ? (
        <Text style={{ color: 'red' }}>{error}</Text>
      ) : shipmentOrder ? (
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          <Text
            style={{
              marginRight: 10,
            }}>{`${pickup_city}, ${pickup_state} ${pickup_country} ${pickup_post_code}`}</Text>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}>
            <IconButton style={{ margin: 0 }} icon="transfer-right" color={'#bfbfbf'}></IconButton>
            <Text
              style={{
                marginLeft: 10,
              }}>{`${receiver_city}, ${receiver_state} ${receiver_country} ${receiver_post_code}`}</Text>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const TrackingShipmentResultsTable = ({ data }) => {
  const { t } = useTranslation();
  const [modalResult, setModalResult] = useState({ data: null, visible: false });

  const theme = useTheme();
  const bottomTextStyle = {
    color: theme.colors.placeholder,
    fontSize: 12,
  };
  const renderRow = () => {
    return (
      <Fragment>
        {data.map((item) => {
          const lastEvent = item.trackingEvents
            ? item.trackingEvents[item.trackingEvents.length - 1]
            : null;
          return (
            <DataTable.Row
              onPress={() => setModalResult({ data: item, visible: true })}
              style={{ paddingVertical: 10 }}>
              <DataTable.Cell style={{ flex: 3 }}>
                {
                  <View>
                    <Text style={styles.topText}>{item.trackingNumber}</Text>
                    <Text style={bottomTextStyle}>{item.courier}</Text>
                  </View>
                }
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 6 }}>
                <TrackingShipmentResultsInfo info={item}></TrackingShipmentResultsInfo>
              </DataTable.Cell>
              <DataTable.Cell style={[styles.centerColumn, { flex: 2 }]}>
                {lastEvent && (
                  <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={styles.topText}>{lastEvent.status}</Text>
                    <TrackingShipmentResultsDate
                      date={lastEvent.date}></TrackingShipmentResultsDate>
                  </View>
                )}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centerColumn}>
                {
                  <View
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'row',
                    }}>
                    {lastEvent?.pod && (
                      <IconButton
                        color={theme.colors.primary}
                        icon="image"
                        onPress={() => {
                          downloadBase64Image(
                            lastEvent.pod,
                            getPODFileName(
                              item.trackingNumber,
                              item.shipmentOrder.ref_no,
                              lastEvent.fileFormat,
                            ),
                          );
                        }}></IconButton>
                    )}
                  </View>
                }
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
      </Fragment>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <TrackingShipmentResultsModal
        visible={modalResult.visible}
        data={modalResult.data || {}}
        onClose={() =>
          setModalResult({ data: null, visible: false })
        }></TrackingShipmentResultsModal>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={{ flex: 3 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <KittenText category="s1">
                {t('components.courierShipments.trackingTable.trackingNumber')}
              </KittenText>
              <IconButton
                size={15}
                color={theme.colors.primary}
                style={{ marginVertical: 0 }}
                icon="content-copy"
                onPress={() => {
                  const listTrackingNumber = data.map((item) => item.trackingNumber);
                  navigator.clipboard.writeText(listTrackingNumber.join('\n'));
                }}
                title={t('components.courierShipments.trackingTable.copyTrackingNumbers')}
              />
            </View>
          </DataTable.Title>
          <DataTable.Title style={{ flex: 6 }}>
            <KittenText category="s1">
              {t('components.courierShipments.trackingTable.information')}
            </KittenText>
          </DataTable.Title>
          <DataTable.Title style={[styles.centerColumn, { flex: 2 }]}>
            <KittenText category="s1">
              {t('components.courierShipments.trackingTable.lastEvent')}
            </KittenText>
          </DataTable.Title>
          <DataTable.Title style={styles.centerColumn}>
            <KittenText category="s1">
              {t('components.courierShipments.trackingTable.pod')}
            </KittenText>
          </DataTable.Title>
        </DataTable.Header>
        {renderRow()}
      </DataTable>
    </View>
  );
};
export default memo(TrackingShipmentResultsTable);
