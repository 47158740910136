import React from 'react';
import { gql, useMutation } from '@apollo/client';
import Background from '../components/Background';
import { AddStoreForm, SHOPIFY_NAME_SUFFIX } from '../components/AddStoreForm';
import { UIStatus, UIStatusWrapper } from '../components/ui-status';
import { STORES } from '../core/utils/utils';
import { Layout, Text } from '@ui-kitten/components';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';
import { useTranslation } from 'react-i18next';

const ADD_STORE_EBAY = gql`
  mutation AddStoreEbay($name: String!, $code: String!) {
    addStoreEbay(input: { name: $name, code: $code }) {
      name
    }
  }
`;

const ADD_STORE_MAGENTO2 = gql`
  mutation AddStoreMagento2(
    $name: String
    $shopUrl: String!
    $consumerKey: String!
    $consumerSecret: String!
    $accessToken: String!
    $accessTokenSecret: String!
  ) {
    addStoreMagento2(
      input: {
        name: $name
        shopUrl: $shopUrl
        consumerKey: $consumerKey
        consumerSecret: $consumerSecret
        accessToken: $accessToken
        accessTokenSecret: $accessTokenSecret
      }
    ) {
      name
    }
  }
`;

const ADD_STORE_WOOCOMMERCE = gql`
  mutation AddStoreWoocommerce(
    $name: String
    $shopUrl: String!
    $consumerKey: String!
    $consumerSecret: String!
  ) {
    addStoreWoocommerce(
      input: {
        name: $name
        shopUrl: $shopUrl
        consumerKey: $consumerKey
        consumerSecret: $consumerSecret
      }
    ) {
      name
    }
  }
`;

const ADD_STORE_ETSY = gql`
  mutation AddStoreEtsy(
    $name: String!
    $code: String!
    $codeVerifier: String!
    $redirectUrl: String!
  ) {
    addStoreEtsy(
      input: { name: $name, code: $code, codeVerifier: $codeVerifier, redirectUrl: $redirectUrl }
    ) {
      name
    }
  }
`;

export const AddStoreScreen = ({ navigation, route }) => {
  const { t } = useTranslation();

  const [addStoreEbay, addStoreEbayResult] = useMutation(ADD_STORE_EBAY, {
    refetchQueries: [{ query: STORES }],
  });
  const [addStoreMangeto2, addStoreMagento2Result] = useMutation(ADD_STORE_MAGENTO2, {
    refetchQueries: [{ query: STORES }],
  });
  const [addStoreWoocommerce, addStoreWoocommerceResult] = useMutation(ADD_STORE_WOOCOMMERCE, {
    refetchQueries: [{ query: STORES }],
  });
  const [addStoreEtsy, addStoreEtsyResult] = useMutation(ADD_STORE_ETSY, {
    refetchQueries: [{ query: STORES }],
  });

  const provider = route?.params?.provider.toUpperCase();

  const renderContent = () => {
    if (provider === STORE_PROVIDERS.SHOPIFY) {
      // Shopify stores will be added on server side
      return <Layout>{t('addStore.shopifyStoreAdded')}</Layout>;
    } else if (provider === STORE_PROVIDERS.EBAY) {
      const code = route?.params?.code;

      return (
        <UIStatusWrapper
          status={new UIStatus(addStoreEbayResult.loading, addStoreEbayResult.error)}>
          <AddStoreForm
            provider={provider}
            providerAuthorised={code !== undefined}
            onSubmit={(values) => {
              addStoreEbay({
                variables: {
                  name: values.name,
                  code,
                },
              }).then(() => {
                navigation.navigate('DashboardScreen');
              });
            }}
          />
        </UIStatusWrapper>
      );
    } else if (provider === STORE_PROVIDERS.MAGENTO2) {
      const { shopUrl, consumerKey, consumerSecret, accessToken, accessTokenSecret } =
        route?.params || {};

      return (
        <UIStatusWrapper
          status={new UIStatus(addStoreMagento2Result.loading, addStoreMagento2Result.error)}>
          <AddStoreForm
            provider={provider}
            providerAuthorised={accessToken !== undefined}
            shopUrl={shopUrl}
            consumerKey={consumerKey}
            consumerSecret={consumerSecret}
            accessToken={accessToken}
            accessTokenSecret={accessTokenSecret}
            onSubmit={(values) => {
              addStoreMangeto2({
                variables: {
                  name: values.name,
                  shopUrl,
                  consumerKey,
                  consumerSecret,
                  accessToken,
                  accessTokenSecret,
                },
              }).then(() => {
                navigation.navigate('DashboardScreen');
              });
            }}
          />
        </UIStatusWrapper>
      );
    } else if (provider === STORE_PROVIDERS.WOOCOMMERCE) {
      const { shopUrl, consumerKey, consumerSecret } = route?.params || {};

      return (
        <UIStatusWrapper
          status={new UIStatus(addStoreWoocommerceResult.loading, addStoreWoocommerceResult.error)}>
          <AddStoreForm
            provider={provider}
            providerAuthorised={consumerKey !== undefined}
            shopUrl={shopUrl}
            consumerKey={consumerKey}
            consumerSecret={consumerSecret}
            onSubmit={(values) => {
              addStoreWoocommerce({
                variables: {
                  name: values.name,
                  shopUrl,
                  consumerKey,
                  consumerSecret,
                },
              }).then(() => {
                navigation.navigate('DashboardScreen');
              });
            }}
          />
        </UIStatusWrapper>
      );
    } else if (provider === STORE_PROVIDERS.ETSY) {
      const code = route?.params?.code;
      const codeVerifier = window.localStorage.getItem('codeVerifier');
      return (
        <UIStatusWrapper
          status={new UIStatus(addStoreEtsyResult.loading, addStoreEtsyResult.error)}>
          <AddStoreForm
            provider={provider}
            providerAuthorised={code !== undefined}
            onSubmit={(values) => {
              addStoreEtsy({
                variables: {
                  name: values.name,
                  code,
                  codeVerifier,
                  redirectUrl:
                    window.location.protocol + '//' + window.location.host + `/a/store/add/etsy`,
                },
              }).then(() => navigation.navigate('DashboardScreen'));
            }}
          />
        </UIStatusWrapper>
      );
    }
    {
      return <Text status="info">{t('addStore.accountNotSupported', { provider: provider })}</Text>;
    }
  };

  return <Background fullWidth={true}>{renderContent()}</Background>;
};
