import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Modal, Text, Portal } from 'react-native-paper';
import { observer } from 'mobx-react';
import { useNavigation } from '@react-navigation/native';
import { ListItem, List, Button, Icon, ModalService } from '@ui-kitten/components';
import { useIsFocused } from '@react-navigation/core';
import { format } from 'date-fns';
import { all, groupBy, prop, reject } from 'ramda';
import * as Linking from 'expo-linking';
import { saveAs } from 'file-saver';
import CsvUploader from '../../components/CsvUploader';
import Table from '../../components/Table';
import Background from '../../components/Background';
import CreateOutboundForm from '../../components/CreateOutboundForm';
import OcSearchForm from '../../components/OcSearchForm';
import Dialogue from '../../components/Dialogue';
import exportCsv from '../../core/csv';
import OutboundControl from '../../components/OutboundControl';
import { UIStatusWrapper } from '../../components/ui-status';
import {
  generateNanoIdByCustomAlphabet,
  ORDER_STATUSES,
  unifiedAlert,
  useIsWmsManager,
  useOrgId,
} from '../../core/utils/utils';
import { OC_STATUS_CODE, OC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import {
  outboundOrderStore,
  outboundOrderTableStore,
  warehouseAccountStore,
  warehousesStore,
} from '../../store';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import ImportedOrderDispatchStaging from '../../components/ImportedOrderDispatchStaging';
import OutboundConsignmentStaging from '../../components/OutboundConsignmentStaging';
import { OutboundOrderStore } from '../../store/OutboundOrderStore';
import CreatePickingWaveTaskModal from '../../components/CreatePickingWaveTaskModal';
import { ezTheme } from '../../core/theme';
import { useTranslation } from 'react-i18next';

const getId = (item) => item.consignment_no;
const selectedOcs = (ocOrders, selectedIds) => ocOrders.filter((o) => selectedIds.has(getId(o)));
const CANCELLABLE_STATUS = [
  OC_STATUS_CODE.Draft,
  OC_STATUS_CODE.Preparing,
  OC_STATUS_CODE.Exception,
];

const XERO_EXPORT_INSTRUCTION_URL = 'https://central.xero.com/s/article/Export-invoices-and-bills';

const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

const OutboundConsignmentBulkUploadModal = observer(
  ({ contentContainerStyle, visible, onDismiss, onSubmit }) => {
    const [stagedOcs, setStagedOcs] = React.useState([]);
    const [ocErrors, setOcErrors] = React.useState({});
    const { t } = useTranslation();
    const onUploadCSV = async (data) => {
      const mappedOcs = OutboundOrderStore.MapCsvUploadedOutboundConsignments(data);
      setStagedOcs(mappedOcs);
      setOcErrors(await OutboundOrderStore.ValidateCsvUploadedOutboundConsignments(mappedOcs));
    };
    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          {stagedOcs && stagedOcs.length == 0 ? (
            <>
              {/* Force to read the first sheet */}
              <CsvUploader onDismiss={onDismiss} onSubmit={onUploadCSV} sheetIndices={[0]} />
              <Button
                appearance="ghost"
                style={styles.button}
                onPress={() =>
                  Linking.openURL(
                    require('../../spreadsheetTemplates/OutboundConsignmentTemplate.xlsx'),
                  )
                }>
                {t('inventory.outbound.downloadTemplate')}
              </Button>
            </>
          ) : (
            <OutboundConsignmentStaging
              ocs={stagedOcs}
              onClearOcs={() => setStagedOcs([])}
              errors={ocErrors}
              onSubmit={() => onSubmit(stagedOcs)}
            />
          )}
        </Modal>
      </Portal>
    );
  },
);

const XeroCsvUploadModal = observer(({ contentContainerStyle, visible, onDismiss }) => {
  const [orders, setOrders] = React.useState([]);
  const { t } = useTranslation();
  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={() => {
          onDismiss();
          setOrders([]);
        }}
        contentContainerStyle={contentContainerStyle}>
        {orders && orders.length === 0 ? (
          <>
            <CsvUploader
              onDismiss={onDismiss}
              sheetIndices={[0]}
              onSubmit={async (data) => {
                const orders = xeroInvoice2Orders(data);
                setOrders(orders);
              }}
            />
            <Button
              onPress={() => {
                Linking.openURL(XERO_EXPORT_INSTRUCTION_URL);
              }}
              appearance="ghost"
              status="info"
              accessoryLeft={(props) => <Icon {...props} name="external-link-outline" />}>
              {t('inventory.outbound.instructions')}
            </Button>
          </>
        ) : (
          <ImportedOrderDispatchStaging orders={orders} setOrders={setOrders} />
        )}
      </Modal>
    </Portal>
  );
});

export default observer(() => {
  const navigation = useNavigation();
  const isWmsManager = useIsWmsManager();
  const orgId = useOrgId();
  const { t } = useTranslation();

  const isUserWarehouseAccountAdmin = React.useMemo(() => {
    const orgIds = outboundOrderTableStore.warehouseAccountStore.warehouseAccounts
      .filter((w) => w.masterAccountAdminOrganisationId)
      .map((w) => w.masterAccountAdminOrganisationId);
    return orgIds.includes(orgId);
  }, [orgId]);

  const isFocused = useIsFocused();
  // Refetch data when screen is focused

  const displayKeys = React.useMemo(() => {
    let result = outboundOrderTableStore.DISPLAY_KEYS;
    if (!isWmsManager) {
      result = result.filter((k) => k !== 'pickingTaskType');
    }
    if (!isUserWarehouseAccountAdmin) {
      result = result.filter((k) => k !== 'organisationName');
    }

    return result;
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      outboundOrderTableStore.organisationStore.setOrgId(orgId);
      outboundOrderTableStore.fetchItems();
      outboundOrderTableStore.fetchTotalStatuses();
    }
    return () => {
      outboundOrderTableStore.resetFilterParams();
    };
  }, [isFocused]);

  const getDuplicatedConsignmentValue = () => {
    let oc = outboundOrderTableStore.getDuplicatedOutbound();

    if (oc) {
      return {
        ...oc,
        warehouseAccountId: oc?.warehouseAccountId,
        warehouse: oc?.from_warehouse_code,
        courier: oc?.logistics_product_code,
        skus:
          oc?.outboundlist_sku.map((item) => ({
            qty: item.qty,
            warehouseSku: item.sku_code,
          })) || [],
        refNo: oc?.ref_no + '_' + generateNanoIdByCustomAlphabet(),
        note: oc?.remark || '',
        address: {
          country: oc?.country || '',
          name: oc?.last_name + ' ' + oc?.first_name,
          state: oc?.state || '',
          city: oc?.city || '',
          phone: oc?.phone || '',
          email: oc?.email || '',
          post_code: oc?.post_code || '',
          company: oc?.company || '',
          street: oc?.street || '',
        },
      };
    }
    return null;
  };

  return (
    <Background fullWidth={true}>
      <OutboundControl
        isUserWarehouseAccountAdmin={isUserWarehouseAccountAdmin}
        filterParams={outboundOrderTableStore.filterParams}
        indicators={getIndicators(outboundOrderTableStore.filterParams)}
        onSearchTermChange={outboundOrderTableStore.setSearchTerm}
        onPressAdvancedSearch={outboundOrderTableStore.openSearch}
        onFilterClear={() => {
          outboundOrderTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          outboundOrderTableStore.setFilterParams({
            ...outboundOrderTableStore.filterParams,
            ...params,
          });
        }}
        sortOptions={outboundOrderTableStore.sortOption}
        onSortChange={outboundOrderTableStore.setSortOption}
        countStatuses={outboundOrderTableStore.countStatuses}
        setCurrentPage={outboundOrderTableStore.setCurrentPage}
        disableSearch={outboundOrderTableStore.loading}
        requireButtonClickToSearch={outboundOrderTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: outboundOrderTableStore.error,
            indeterminate: outboundOrderTableStore.loading,
          }}>
          <Table
            heightOffset={300}
            items={outboundOrderTableStore.items}
            getId={getId}
            displayKeys={displayKeys}
            titleByKey={
              isUserWarehouseAccountAdmin
                ? outboundOrderTableStore.TITLE_BY_KEY
                : reject((k) => k === 'organisationName', outboundOrderTableStore.TITLE_BY_KEY)
            }
            formatterByKey={outboundOrderTableStore.FORMATTER_BY_KEY}
            pagingPrefixText={'Up to '}
            pagingSuffixText={' outbounds per service provider'}
            styleByKey={{
              organisationName: { minWidth: 150 },
              consignment_no: { minWidth: 150, marginRight: 10 },
              create_time: { marginHorizontal: 10 },
              shipping_no: { minWidth: 150 },
              logistics_product_code: { minWidth: 150 },
              pickingTaskType: { marginHorizontal: 10 },
            }}
            rowOnClick={(item) => {
              navigation.navigate('OutboundDetailScreen', {
                id: item.consignment_no,
                warehouseAccountId: item.warehouseAccountId,
              });
            }}
            sort="single"
            isFabVisible={isFocused}
            currentPage={outboundOrderTableStore.currentPage}
            onPageChange={(page) => {
              outboundOrderTableStore.setCurrentPage(page);
            }}
            totalItemNum={outboundOrderTableStore.total}
            itemsPerPage={outboundOrderTableStore.pageSize}
            setPageSize={outboundOrderTableStore.setPageSize}
            fabActions={(selectedIds) => {
              const actions = [];
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'plus',
                  label: t('inventory.outbound.add'),
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      outboundOrderTableStore.openConsignmentForm();
                    } else {
                      outboundOrderTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        outboundOrderTableStore.openConsignmentForm();
                      });
                      outboundOrderTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
                actions.push({
                  icon: 'download-outline',
                  label: t('inventory.outbound.exportAll'),
                  onPress: async () => {
                    await outboundOrderTableStore.fetchItems(true);
                    const ocs = outboundOrderTableStore.exportedItems.map(
                      ({ outboundlist_sku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = ocs?.length > 0 ? Object.keys(ocs[0]) : [];
                    exportCsv(ocs, csvHeaders, 'ocOrders.csv');
                  },
                });
                actions.push({
                  icon: 'file-upload-outline',
                  label: t('inventory.outbound.uploadBatch'),
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      outboundOrderTableStore.openCsvModal();
                    } else {
                      outboundOrderTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        outboundOrderTableStore.openCsvModal();
                      });
                      outboundOrderTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
                actions.push({
                  icon: 'file-import-outline',
                  label: t('inventory.outbound.importXero'),
                  onPress: outboundOrderTableStore.openXeroCsvModal,
                });
              }

              if (selectedIds.size === 1) {
                actions.push({
                  icon: 'content-copy',
                  label: t('inventory.outbound.duplicate'),
                  onPress: async () => {
                    try {
                      outboundOrderTableStore.setDuplicatedOutboundId([...selectedIds][0]);

                      warehouseAccountStore.setSelectedWarehouseAccount(
                        await warehouseAccountStore.getWarehouseAccountById(
                          outboundOrderTableStore.getDuplicatedOutbound()?.warehouseAccountId,
                        ),
                      );

                      outboundOrderTableStore.openConsignmentForm();
                    } catch (err) {
                      unifiedAlert(err.message);
                      console.log(err.message);
                    }
                  },
                });
              }
              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'download-outline',
                  label: t(
                    `inventory.outbound.exportSelected.${
                      selectedIds.size > 1 ? 'multiple' : 'single'
                    }`,
                  ),
                  onPress: () => {
                    const ocs = selectedOcs(outboundOrderTableStore.items, selectedIds);
                    const exportedOcs = ocs.map(
                      ({ outboundlist_sku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = exportedOcs?.length > 0 ? Object.keys(exportedOcs[0]) : [];
                    exportCsv(exportedOcs, csvHeaders, 'ocOrders.csv');
                  },
                });
                if (
                  all(
                    (item) => CANCELLABLE_STATUS.includes(item.status),
                    selectedOcs(outboundOrderTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'cancel',
                    label: t(
                      `inventory.outbound.cancelSelected.${
                        selectedIds.size > 1 ? 'multiple' : 'single'
                      }`,
                    ),
                    onPress: () => {
                      outboundOrderTableStore.ordersToCancel = selectedIds;
                      outboundOrderTableStore.openCancelDialogue();
                    },
                  });
                }
              }
              // add a button to create a picking wave task for the selected orders
              if (
                selectedIds.size > 0 &&
                all(
                  (item) => OC_STATUS_CODE.Preparing === item.status,
                  selectedOcs(outboundOrderTableStore.items, selectedIds),
                )
              ) {
                actions.push({
                  icon: 'tray-plus',
                  label: t('inventory.outbound.createPickingWave'),
                  onPress: async () => {
                    const ocs = selectedOcs(outboundOrderTableStore.items, selectedIds);
                    const multipleWarehouseSelected =
                      new Set(ocs.map((oc) => oc.warehouseAccountId)) > 0 ||
                      new Set(ocs.map((oc) => oc.from_warehouse_code)) > 0;
                    if (multipleWarehouseSelected) {
                      unifiedAlert(t('inventory.outbound.multipleWarehouses'));
                      return;
                    }
                    try {
                      await outboundOrderTableStore.createDraftPickingTask(
                        [...selectedIds],
                        ocs[0].warehouseAccountId,
                      );
                    } catch (e) {
                      unifiedAlert(e.message);
                      return;
                    }
                    outboundOrderTableStore.ordersToCreatePickingWaveTask = selectedIds;
                    outboundOrderTableStore.openCreatePickingWaveTaskDialogue();
                  },
                });
              }
              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>
      <Portal>
        <Modal
          visible={outboundOrderTableStore.searchOpen}
          onDismiss={outboundOrderTableStore.closeSearch}
          contentContainerStyle={styles.modalStyle}>
          <OcSearchForm
            filterParams={outboundOrderTableStore.filterParams}
            items={outboundOrderTableStore.items}
            onDismiss={outboundOrderTableStore.closeSearch}
            onSubmit={(filterParams) => {
              outboundOrderTableStore.setFilterParams(filterParams);
              outboundOrderTableStore.closeSearch();
            }}
          />
        </Modal>
      </Portal>

      <Portal>
        <Modal
          visible={outboundOrderTableStore.consignmentFormOpen}
          onDismiss={outboundOrderTableStore.closeConsignmentForm}
          contentContainerStyle={styles.modalStyle}>
          <CreateOutboundForm
            initialConsignment={getDuplicatedConsignmentValue()}
            onDismiss={outboundOrderTableStore.closeConsignmentForm}
            warehouseAccountId={
              warehouseAccountStore.selectedWarehouseAccount?.id ||
              getDuplicatedConsignmentValue()?.warehouseAccountId
            }
            onSubmit={() => {
              outboundOrderTableStore.closeConsignmentForm();
              // Refetch ICs after 2 seconds
              setTimeout(() => outboundOrderTableStore.fetchItems(), 2000);
            }}
          />
        </Modal>
      </Portal>

      <Portal>
        <Modal
          visible={outboundOrderTableStore.cancelDialogueOpen}
          onDismiss={outboundOrderTableStore.closeCancelDialogue}
          contentContainerStyle={styles.modalStyle}>
          <Dialogue
            onSubmit={async () => {
              await Promise.all(
                selectedOcs(
                  outboundOrderTableStore.items,
                  outboundOrderTableStore.ordersToCancel,
                ).map((oc) =>
                  outboundOrderTableStore.dataStore.cancelItem(
                    oc.warehouseAccountId,
                    oc.consignment_no,
                  ),
                ),
              );
              // Refetch ICs after 2 second
              setTimeout(() => outboundOrderTableStore.fetchItems(), 2000);
              outboundOrderTableStore.closeCancelDialogue();
            }}
            onDismiss={outboundOrderTableStore.closeCancelDialogue}>
            <Text>{t('inventory.outbound.cancel')}</Text>
            <List
              data={selectedOcs(
                outboundOrderTableStore.items,
                outboundOrderTableStore.ordersToCancel,
              )}
              renderItem={({ item }) => <ListItem title={`${item.consignment_no}`} />}
            />
          </Dialogue>
        </Modal>
      </Portal>

      <OutboundConsignmentBulkUploadModal
        contentContainerStyle={styles.modalStyle}
        visible={outboundOrderTableStore.csvModalOpen}
        onDismiss={outboundOrderTableStore.closeCsvModal}
        onSubmit={async (outboundConsignments) => {
          try {
            await outboundOrderStore.addItems(
              warehouseAccountStore.selectedWarehouseAccount.id,
              outboundConsignments.map(({ refNo, ...oc }) => ({ ref_no: refNo, ...oc })),
            );
            unifiedAlert('Successfully uploaded and created the outbound consignments.');
          } catch (e) {
            const messageView = (
              <View
                style={{
                  width: 'fit-content',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                }}>
                <Card style={styles.card}>
                  <Card.Content
                    style={{
                      maxHeight: '70vh',
                      whiteSpace: 'pre-line',
                      overflowY: 'auto',
                    }}>
                    <Text>{e.message}</Text>
                  </Card.Content>
                  <Card.Actions>
                    <Button
                      size="small"
                      appearance="outline"
                      onPress={() => {
                        let blobFile = new Blob([e.message], {
                          type: 'text/plain;charset=utf-8',
                        });
                        saveAs(blobFile, 'oc_upload_results.txt');
                      }}>
                      Download
                    </Button>
                  </Card.Actions>
                </Card>
              </View>
            );
            const modalId = ModalService.show(messageView, {
              onBackdropPress: () => ModalService.hide(modalId),
              backdropStyle: {
                backgroundColor: ezTheme.backdropModalColor,
              },
            });
          }
          setTimeout(() => {
            outboundOrderTableStore.resetFilterParams();
          }, 2000);
          outboundOrderTableStore.closeCsvModal();
        }}
      />

      <XeroCsvUploadModal
        contentContainerStyle={styles.modalStyle}
        visible={outboundOrderTableStore.xeroCsvModalOpen}
        onDismiss={outboundOrderTableStore.closeXeroCsvModal}
      />
      <WarehouseAccountSelectionModal
        visible={outboundOrderTableStore.warehouseSelectionVisible}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          outboundOrderTableStore.onWarehouseSelectionSubmit(warehouseAccount);
          outboundOrderTableStore.setWarehouseSelectionVisible(false);
        }}
        onCancel={() => outboundOrderTableStore.setWarehouseSelectionVisible(false)}
      />

      <CreatePickingWaveTaskModal
        visible={outboundOrderTableStore.createPickingWaveTaskModalOpen}
        draftPickingTask={outboundOrderTableStore.draftPickingTask}
        onCancel={outboundOrderTableStore.closeCreatePickingWaveTaskDialogue}
        onSubmit={async () => {
          try {
            await outboundOrderTableStore.createPickingTask(
              outboundOrderTableStore.draftPickingTask.ocIds,
              outboundOrderTableStore.draftPickingTask.warehouseAccountId,
            );
            outboundOrderTableStore.closeCreatePickingWaveTaskDialogue();
            outboundOrderTableStore.fetchItems();
          } catch (e) {
            unifiedAlert(e.message);
            return;
          }
        }}
      />
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: {
    backgroundColor: 'white',
    margin: 20,
    alignSelf: 'center',
    minWidth: 500,
  },
  dialogueStyle: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
});

const getIndicators = (params) => {
  const dateFormatter = (date) => format(date, 'd MMM y');
  let indicators = [];

  if (params.consignmentNumber) {
    indicators.push(`consignment no.: ${params.consignmentNumber}`);
  } else if (params.refNumber) {
    indicators.push(`ref no.: ${params.refNumber}`);
  } else if (params.fourpxTrackingNumber) {
    indicators.push(`ref no.: ${params.ref_no}`);
  } else if (params.shippingNumber) {
    indicators.push(`tracking no.: ${params.shippingNumber}`);
  } else {
    if (params.status) {
      indicators.push(`status: ${OC_STATUS_MAP[params.status]}`);
    }
    if (params.sku_code) {
      indicators.push(`SKu: ${params.sku_code}`);
    }
    if (params.from_warehouse_code) {
      indicators.push(
        `from warehouse: ${warehousesStore.getWarehouseNameEn(
          params.from_warehouse_code,
          warehouseAccountStore.selectedWarehouseAccount,
        )}`,
      );
    }
    if (params.create_time_start && params.create_time_end) {
      indicators.push(
        `orders from ${dateFormatter(params.create_time_start)} to ${dateFormatter(
          params.create_time_end,
        )}`,
      );
    } else if (params.create_time_start) {
      indicators.push(`orders from ${dateFormatter(params.create_time_start)}`);
    } else if (params.create_time_end) {
      indicators.push(`orders by ${dateFormatter(params.create_time_end)}`);
    }

    if (params.complete_time_start && params.complete_time_end) {
      indicators.push(
        `orders completed from ${dateFormatter(params.complete_time_start)} to ${dateFormatter(
          params.complete_time_end,
        )}`,
      );
    } else if (params.complete_time_start) {
      indicators.push(`orders completed after ${dateFormatter(params.complete_time_start)}`);
    } else if (params.complete_time_end) {
      indicators.push(`orders completed by ${dateFormatter(params.complete_time_end)}`);
    }
  }

  return indicators;
};
const xeroInvoice2Orders = (data) => {
  return Object.values(groupBy(prop('InvoiceNumber'), data)).map((o) => ({
    createdAt: o[0].InvoiceDate,
    id: o[0].InvoiceNumber,
    storeName: 'Xero',
    provider: 'Xero',
    number: o[0].InvoiceNumber,
    fulfillmentStatus: null,
    financialStatus: null,
    shippingAddress: {
      address1: o[0].SAAddressLine1,
      address2: o[0].SAAddressLine2,
      name: o[0].ContactName,
      phone: null,
      province: o[0].SARegion,
      provinceCode: o[0].SARegion,
      zip: `${o[0].SAPostalCode}`,
      city: o[0].SACity,
      countryCode: o[0].SACountry,
      company: null,
    },
    email: o[0].EmailAddress,
    currency: o[0].Currency,
    price: o[0].Total,
    platformId: o[0].InvoiceNumber,
    status: ORDER_STATUSES.open,
    lineItems: o.map((l) => ({
      id: l.InventoryItemCode || l.Description,
      storeId: 'Xero',
      platformId: l.InventoryItemCode || l.Description,
      name: l.Description,
      sku: l.InventoryItemCode,
      quantity: l.Quantity,
      fulfillableQuantity: l.Quantity,
      pricePaid: l.Total,
      unitPrice: l.LineAmount,
    })),
  }));
};
