import { useIsFocused } from '@react-navigation/core';
import { Modal } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import AddressBookListControl from '../../components/AddressBookListControl';
import Background from '../../components/Background';
import { AddressForm } from '../../components/AddressBook/AddressForm';
import Table from '../../components/Table';
import { UIStatusWrapper } from '../../components/ui-status';
import { unifiedAlert } from '../../core/utils/utils';
import { addressBookStore, addressBookTableStore as tableStore } from '../../store';
import { ezTheme } from '../../core/theme';
import { useTranslation } from 'react-i18next';

const TYPE = {
  selectAddressBook: 'selectAddressBook',
  normal: 'normal',
};

export default observer(({ type, onSelect }) => {
  const { t } = useTranslation();
  const getId = (item) => item.id;
  useEffect(() => {
    tableStore.fetchItems();
  }, []);
  const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;
  const onAddNew = () => {
    setDataAddress({});
    openAddress();
  };

  const onDelete = async (ids) => {
    try {
      if ((Array.isArray(ids) && ids.length > 0) === false) {
        unifiedAlert(t('shipments.addressBookList.noDataFoundToDelete'));
        return;
      }
      const result = await addressBookStore.deleteData(ids);
      if (result) {
        unifiedAlert(t('shipments.addressBookList.deletedAddressBook') + ` ${ids.join(', ')}`);
        await tableStore.fetchItems();
      } else {
        unifiedAlert(t('shipments.addressBookList.failedToDeleteAddressBook'));
      }
    } catch (error) {
      unifiedAlert(error);
    }
  };

  const renderFabActions = (selectedIds) => {
    const actions = [];
    actions.push({
      icon: 'plus',
      label: t('shipments.addressBookList.addAddressBook'),
      onPress: onAddNew,
    });
    if (selectedIds.size > 0) {
      actions.push({
        icon: 'delete',
        label: t('shipments.addressBookList.deleteSelectedAddressBook'),
        onPress: () => {
          onDelete(Array.from(selectedIds));
        },
      });
    }
    return actions;
  };

  const [addressVisible, setAddressVisible] = useState(false);
  const [dataAddress, setDataAddress] = useState({});

  const closeAddress = () => {
    setAddressVisible(false);
  };

  const openAddress = () => {
    setAddressVisible(true);
  };

  const onSubmit = async (data) => {
    try {
      const { id } = await addressBookStore.saveData(data);
      unifiedAlert(t('shipments.addressBookList.addressBookSaved') + ` ${id}`);
      closeAddress();
      await tableStore.fetchItems();
    } catch (error) {
      unifiedAlert(error);
    }
  };

  const onRowClick = (item) => {
    let row = JSON.parse(JSON.stringify(item));
    delete row['__typename'];
    if (type === TYPE.selectAddressBook) {
      onSelect(row);
    } else {
      setDataAddress(row);
      openAddress();
    }
  };

  const onPageChange = (page) => {
    tableStore.setCurrentPage(page);
  };

  const renderModalAddress = () => {
    return (
      <Modal
        visible={addressVisible}
        onBackdropPress={closeAddress}
        backdropStyle={styles.backdropStyle}>
        <Card style={styles.modalContent}>
          <AddressForm
            value={dataAddress}
            onCancel={closeAddress}
            onSubmit={onSubmit}></AddressForm>
        </Card>
      </Modal>
    );
  };

  return (
    <Background fullWidth={true}>
      <AddressBookListControl
        filterParams={tableStore.filterParams}
        indicators={getIndicators(tableStore.filterParams)}
        onSearchTermChange={tableStore.setSearchTerm}
        onFilterClear={() => {
          tableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          tableStore.setFilterParams({
            ...tableStore.filterParams,
            ...params,
          });
        }}
        sortOptions={tableStore.sortOption}
        onSortChange={tableStore.setSortOption}
        disableSearch={tableStore.loading}
        requireButtonClickToSearch={tableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: tableStore.error,
            indeterminate: tableStore.loading,
          }}>
          <Table
            heightOffset={300}
            items={tableStore.items}
            getId={getId}
            displayKeys={tableStore.DISPLAY_KEYS}
            titleByKey={tableStore.TITLE_BY_KEY}
            formatterByKey={tableStore.FORMATTER_BY_KEY}
            rowOnClick={onRowClick}
            sort="single"
            currentPage={tableStore.currentPage}
            onPageChange={onPageChange}
            totalItemNum={tableStore.total}
            itemsPerPage={tableStore.pageSize}
            setPageSize={tableStore.setPageSize}
            isFabVisible={type === TYPE.selectAddressBook ? undefined : useIsFocused()}
            fabActions={type === TYPE.selectAddressBook ? undefined : renderFabActions}></Table>
        </UIStatusWrapper>
      </Card>
      {renderModalAddress()}
    </Background>
  );
});
const styles = StyleSheet.create({
  backdropStyle: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modalContent: {
    flex: 1,
    maxHeight: 500,
  },
});

const getIndicators = (params) => {
  const indicators = [];
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      indicators.push(`${key}: ${value}`);
    }
  });

  return indicators;
};
