import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import Background from 'src/components/Background';
import { gql, useQuery } from '@apollo/client';
import { useIsMobile } from '../../core/responsive.utils';
import LineItemList from 'src/components/LineItemList';
import OutboundConsignmentList from 'src/components/OutboundConsignmentList';
import AdditionalTrackingList from './AdditionalTrackingList';
import OrderShippingDetailsForm from './OrderShippingDetailsForm';
import OrderCommentsList from './OrderCommentsList';
import { UIStatusWrapper } from '../../components/ui-status';
import {
  countryStateCityCurrencyStore,
  orderTableStore,
  outboundOrderStore,
  storeStore,
  warehouseAccountStore,
} from '../../store';
import { observer } from 'mobx-react';
import { Text } from '@ui-kitten/components';
import CreateOutboundForm from '../../components/CreateOutboundForm';
import { OrderDispatchStore } from '../../store/OrderDispatchStore';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';

const OUTBOUND_CONSIGNMENTS = gql`
  query outbounds($warehouseAccountIds: [ID!]!, $consignmentNumbers: [ID!]!) {
    outbounds(warehouseAccountIds: $warehouseAccountIds, consignmentNumbers: $consignmentNumbers) {
      consignment_type
      consignment_no
      ref_no
      logistics_product_code
      logistics_provider
      shipping_no
      status
      total_volume
      total_weight
      create_time
      complete_time
      remark
      state
      city
      district
      post_code
      street
      house_number
      company
      last_name
      first_name
      phone
      email
      outboundlist_sku {
        sku_name
        sku_code
        qty
      }
    }
  }
`;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
  },

  desktopContainer: {
    flexDirection: 'row',
  },

  card: {
    marginHorizontal: '0.4em',
  },

  desktopCard: {
    minWidth: '300px',
    width: '20%',
  },

  mobileCard: {
    width: '90%',
    marginBottom: '1em',
  },
  link: {
    color: 'blue',
  },
});

export const OrderDetailScreen = observer(({ navigation, route }) => {
  const { t } = useTranslation();
  const storeId = route?.params?.storeId;
  const id = route?.params?.id;

  // if fails to find storeId or id, display the error
  if (!storeId || !id) {
    return (
      <Background fullWidth={true}>
        <Text>{t('order.orderDetailScreen.error.notFound')}</Text>
        <Text style={[styles.link]} onPress={() => navigation.navigate('OrderListScreen')}>
          {t('order.orderDetailScreen.returnToList')}
        </Text>
      </Background>
    );
  }

  const decodedStoreId = (storeId && decodeURIComponent(storeId)) || null;

  const decodedId = (id && decodeURIComponent(id)) || null;
  const consignmentNumbers = Array.isArray(route?.params?.consignmentNumbers)
    ? route?.params?.consignmentNumbers
    : route?.params?.consignmentNumbers?.split(',');

  if (id !== decodedId || storeId !== decodedStoreId) {
    navigation.setParams({ id: decodedId, storeId: decodedStoreId, consignmentNumbers });
    return null;
  }

  const { loading: loadingConsignments, data: consignmentsData } = useQuery(OUTBOUND_CONSIGNMENTS, {
    variables: {
      warehouseAccountIds: warehouseAccountStore.warehouseAccountIds,
      consignmentNumbers,
    },
    skip: !(consignmentNumbers?.length > 0),
  });

  const orderDispatchStore = useMemo(
    () =>
      new OrderDispatchStore(
        orderTableStore.client,
        outboundOrderStore,
        storeStore,
        [id],
        [storeId],
        warehouseAccountStore,
        countryStateCityCurrencyStore,
      ),
    [order, outboundOrderStore, orderTableStore],
  );

  const order = useMemo(() => orderDispatchStore.orders[0], [orderDispatchStore.orders]);
  const isMobile = useIsMobile();

  const windowHeight = useWindowDimensions().height;

  const Container = isMobile ? ScrollView : View;

  const [isAddingTracking, setIsAddingTracking] = useState(false);

  const startAddingTracking = useCallback(() => setIsAddingTracking(true), [setIsAddingTracking]);

  const stopAddingTracking = useCallback(() => setIsAddingTracking(false), [setIsAddingTracking]);

  const [selectedConsignment, setSelectedConsignment] = useState(null);

  const [warehouseSelectionVisible, setWarehouseSelectionVisible] = useState(false);
  const [ocVisible, setOcVisible] = useState(false);

  return (
    <Background fullWidth={true}>
      <UIStatusWrapper
        status={{
          empty: !order,
          indeterminate: loadingConsignments || orderDispatchStore.loadingOrders,
        }}>
        <Container
          style={[
            styles.container,
            isMobile ? null : styles.desktopContainer,
            { maxHeight: windowHeight - 120, overflowY: 'auto' },
          ]}>
          <OrderShippingDetailsForm
            order={order}
            navigation={navigation}
            onFulfillOrder={() => {
              if (warehouseAccountStore.selectedWarehouseAccount) {
                setOcVisible(true);
              } else {
                orderDispatchStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                  warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                  setOcVisible(true);
                });
                setWarehouseSelectionVisible(true);
              }
            }}
            onSelfCollectOrder={() => {
              if (warehouseAccountStore.selectedWarehouseAccount) {
                navigation.navigate('OrderDispatchScreen', {
                  ids: JSON.stringify([
                    {
                      orderId: order.id,
                      storeId: order.storeId,
                      orderNumber: order.displayId,
                    },
                  ]),
                  localPickup: true,
                  warehouseAccountId: warehouseAccountStore.selectedWarehouseAccount.id,
                });
              } else {
                orderDispatchStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                  warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                  navigation.navigate('OrderDispatchScreen', {
                    ids: JSON.stringify([
                      {
                        orderId: order.id,
                        storeId: order.storeId,
                        orderNumber: order.displayId,
                      },
                    ]),
                    localPickup: true,
                    warehouseAccountId: warehouseAccount.id,
                  });
                });
                setWarehouseSelectionVisible(true);
              }
            }}
          />

          <LineItemList
            order={order}
            refetchOrder={() => orderDispatchStore.fetchOrders()}
            startAddingTracking={startAddingTracking}
            stopAddingTracking={stopAddingTracking}
            selectedConsignment={selectedConsignment}
            hasMatchingConsignments={
              consignmentsData?.outbounds?.consignments &&
              consignmentsData.outbounds.consignments.length > 0
            }
          />
          {consignmentsData?.outbounds?.consignments &&
          consignmentsData.outbounds.consignments.length > 0?.outbounds?.length > 0 ? (
            <OutboundConsignmentList
              consignments={consignmentsData.outbounds.consignments}
              setSelectedConsignment={setSelectedConsignment}
              selectedConsignment={selectedConsignment}
              consignmentSelectable={isAddingTracking}
              storeId={order.storeId}
            />
          ) : null}

          {orderDispatchStore && ocVisible ? (
            <CreateOutboundForm
              storeId={storeId}
              orderId={order?.id}
              onDismiss={() => setOcVisible(false)}
              onSubmit={() => setOcVisible(false)}
              initialConsignment={orderDispatchStore.mergedOutboundConsignments?.[0]}
              warehouseAccountId={
                warehouseAccountStore.selectedWarehouseAccount &&
                warehouseAccountStore.selectedWarehouseAccount.id
              }
              style={{ maxHeight: windowHeight - 250, overflowY: 'auto' }}
              onReinitializationRequested={(ocs) => {
                orderDispatchStore.setOutboundConsignmentSettingByIndex(0, {
                  ...orderDispatchStore.outboundConsignmentSettings[0],
                  warehouse: ocs[0].warehouse,
                });
              }}
            />
          ) : null}
          <WarehouseAccountSelectionModal
            visible={warehouseSelectionVisible}
            warehouseAccounts={warehouseAccountStore.warehouseAccounts}
            loading={warehouseAccountStore.loading}
            error={warehouseAccountStore.error}
            onSubmit={(warehouseAccount) => {
              orderDispatchStore.onWarehouseSelectionSubmit(warehouseAccount);
              setWarehouseSelectionVisible(false);
            }}
            onCancel={() => setWarehouseSelectionVisible(false)}
          />
          <View
            style={[
              styles.card,
              isMobile ? styles.mobileCard : styles.desktopCard,
              { maxHeight: windowHeight - 250, overflowY: 'auto' },
            ]}>
            <AdditionalTrackingList order={order} />
            <OrderCommentsList order={order} />
          </View>
        </Container>
      </UIStatusWrapper>
    </Background>
  );
});
