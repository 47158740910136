import * as React from 'react';
import { StyleSheet, Linking } from 'react-native';
import { Card, Modal } from 'react-native-paper';
import { Portal } from 'react-native-paper';
import { observer } from 'mobx-react';
import { Button, Divider, Layout, List, ListItem, ModalService, Text } from '@ui-kitten/components';
import Table from '../../components/Table';
import Background from '../../components/Background';
import Dialogue from '../../components/Dialogue';
import { PACKAGING_MATERIAL_MAP, PACKAGING_TYPE_MAP } from '@ezom/library/lib/cjs/constants';
import exportCsv from '../../core/csv';
import { UIStatusWrapper } from '../../components/ui-status';
import { useIsFocused } from '@react-navigation/core';
import {
  organisationStore,
  packagingMaterialTableStore,
  warehouseAccountStore,
  warehousesStore,
} from '../../store';
import { merge } from 'ramda';
import PackagingMaterialTableControl from '../../components/PackagingMaterialTableControl';
import { unifiedAlert } from '../../core/utils/utils';
import { Formik } from 'formik';
import Select from '../../components/Select';
import { TextInputField } from '../../components/input/TextInputField';
import Autocomplete from '../../components/Autocomplete';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { View } from 'react-native';
import * as Yup from 'yup';
import { ezTheme } from '../../core/theme';
import CsvUploader from '../../components/CsvUploader';
import { useTranslation } from 'react-i18next';

const getId = (item) => item.id;
const selectedItems = (items, selectedIds) => items.filter((item) => selectedIds.has(getId(item)));
const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

const getIndicators = (params) => {
  const { t } = useTranslation();
  let indicators = [];

  if (params.material) {
    indicators.push(t('inventory.packagingMaterial.filters.material', { value: params.material }));
  }
  if (params.type) {
    indicators.push(t('inventory.packagingMaterial.filters.type', { value: params.type }));
  }

  return indicators;
};

const renderPackagingMaterialFormElement = (
  warehouses,
  organisations,
  initialValues,
  onCancel,
  onSubmit,
) => {
  const { t } = useTranslation();
  return (
    <View
      style={{
        width: 'fit-content',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90%',
        backgroundColor: 'white',
        padding: 20,
      }}>
      <Text category="h5">{t('inventory.packagingMaterial.title')}</Text>
      <Divider style={{ marginVertical: 10 }} />
      <Formik
        initialValues={merge(
          {
            material: '',
            type: '',
            weight: '',
            length: '',
            width: '',
            height: '',
            thickness: '',
            quantity: '',
            price: 1.0,
            barcode: '',
            warehouse_code: '',
            organisationId: '',
          },
          initialValues,
        )}
        isInitialValid={false}
        validationSchema={Yup.object({
          material: Yup.string().required(t('inventory.packagingMaterial.materialIsRequired')),
          type: Yup.string().required(t('inventory.packagingMaterial.typeIsRequired')),
          weight: Yup.number()
            .required()
            .min(0, t('inventory.packagingMaterial.validation.number.tooSmall'))
            .max(
              Number.MAX_SAFE_INTEGER,
              t('inventory.packagingMaterial.validation.number.tooLarge'),
            ),
          length: Yup.number()
            .required()
            .min(0, t('inventory.packagingMaterial.validation.number.tooSmall'))
            .max(
              Number.MAX_SAFE_INTEGER,
              t('inventory.packagingMaterial.validation.number.tooLarge'),
            ),
          width: Yup.number()
            .required()
            .min(0, t('inventory.packagingMaterial.validation.number.tooSmall'))
            .max(
              Number.MAX_SAFE_INTEGER,
              t('inventory.packagingMaterial.validation.number.tooLarge'),
            ),
          height: Yup.number()
            .required()
            .min(0, t('inventory.packagingMaterial.validation.number.tooSmall'))
            .max(
              Number.MAX_SAFE_INTEGER,
              t('inventory.packagingMaterial.validation.number.tooLarge'),
            ),
          thickness: Yup.number()
            .required()
            .min(0, t('inventory.packagingMaterial.validation.number.tooSmall'))
            .max(
              Number.MAX_SAFE_INTEGER,
              t('inventory.packagingMaterial.validation.number.tooLarge'),
            ),
          quantity: Yup.number()
            .integer()
            .required()
            .min(0, t('inventory.packagingMaterial.validation.number.tooSmall'))
            .max(
              Number.MAX_SAFE_INTEGER,
              t('inventory.packagingMaterial.validation.number.tooLarge'),
            ),
          price: Yup.number()
            .required()
            .min(0, t('inventory.packagingMaterial.validation.number.mustBeGreaterThan0')),
          barcode: Yup.string()
            .nullable()
            .min(3, t('inventory.packagingMaterial.validation.string.mustBeLongerThan3Characters'))
            .matches(/[a-zA-Z0-9]+/, ({ label }) =>
              t('inventory.packagingMaterial.validation.string.onlyAllowLettersAndNumber', {
                label,
              }),
            ),
          warehouse_code: warehouses
            ? Yup.string()
                .required()
                .oneOf((warehouses || []).map((w) => w.warehouse_code))
            : undefined,
          organisationId: organisations
            ? Yup.string().oneOf((organisations || []).map((org) => org.id))
            : undefined,
        })}
        onSubmit={onSubmit}>
        {(props) => {
          return (
            <>
              <View>
                <Select
                  name="material"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.material')}
                  options={Object.entries(PACKAGING_MATERIAL_MAP).map(([key, val]) => ({
                    key,
                    val,
                  }))}
                  value={props.values.material}
                  onChange={({ key }) => {
                    props.setFieldValue('material', key);
                  }}
                  {...props}
                />
                <Select
                  name="type"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.type')}
                  value={props.values.type}
                  options={Object.entries(PACKAGING_TYPE_MAP).map(([key, val]) => ({
                    key,
                    val,
                  }))}
                  onChange={({ key }) => {
                    props.setFieldValue('type', key);
                  }}
                  {...props}
                />
                <TextInputField
                  name="weight"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.weight')}
                  {...props}
                />
                <TextInputField
                  name="length"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.length')}
                  {...props}
                />
                <TextInputField
                  name="width"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.width')}
                  {...props}
                />
                <TextInputField
                  name="height"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.height')}
                  {...props}
                />
                <TextInputField
                  name="thickness"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.thickness')}
                  {...props}
                />
                <TextInputField
                  name="quantity"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.quantity')}
                  {...props}
                />
                <TextInputField
                  name="price"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.price')}
                  {...props}
                />
                <TextInputField
                  autoFocus
                  placeholder={t('inventory.packagingMaterial.fields.barcode.placeholder')}
                  name="barcode"
                  style={styles.field}
                  label={t('inventory.packagingMaterial.fields.barcode.label')}
                  {...props}
                />
                {warehouses ? (
                  <Autocomplete
                    label={t('inventory.packagingMaterial.fields.warehouse.label')}
                    defaultValue={
                      warehouses.find((w) => w.warehouse_code === props.values.warehouse_code)
                        ?.warehouse_name_en || props.values.warehouse_code
                    }
                    error={props.errors.warehouse_code}
                    touched={props.touched.warehouse_code}
                    placeholder={t('inventory.packagingMaterial.fields.warehouse.placeholder')}
                    style={styles.field}
                    options={warehouses}
                    getValue={(w) => w.warehouse_code}
                    getTitle={(w) => w.warehouse_name_en}
                    onSelect={(val) => {
                      props.setFieldValue('warehouse_code', val);
                    }}
                    onBlur={props.handleBlur('warehouse_code')}
                  />
                ) : null}
                {organisations ? (
                  <Autocomplete
                    label={t('inventory.packagingMaterial.fields.ownerOrganisation.label')}
                    defaultValue={
                      organisations.find((org) => org.id === props.values.organisationId)?.name ||
                      props.values.organisationId
                    }
                    error={props.errors.organisationId}
                    touched={props.touched.organisationId}
                    placeholder={t(
                      'inventory.packagingMaterial.fields.ownerOrganisation.placeholder',
                    )}
                    style={styles.field}
                    options={organisations}
                    getValue={(org) => org.id}
                    getTitle={(org) => org.name}
                    onSelect={(val) => {
                      props.setFieldValue('organisationId', val);
                    }}
                    onBlur={props.handleBlur('organisationId')}
                  />
                ) : null}
              </View>
              <Layout style={styles.desktopButtonContainer}>
                <Button
                  status="primary"
                  loading={props.isSubmitting}
                  onPress={props.handleSubmit}
                  disabled={props.isSubmitting || !props.isValid}
                  style={styles.button}>
                  {t('inventory.packagingMaterial.buttons.submit')}
                </Button>
                <Button
                  status="basic"
                  loading={props.isSubmitting}
                  onPress={onCancel}
                  disabled={props.isSubmitting}
                  style={styles.button}>
                  {t('inventory.packagingMaterial.buttons.cancel')}
                </Button>
                <Button
                  status="danger"
                  appearance="ghost"
                  disabled={props.isSubmitting}
                  onPress={props.handleReset}
                  style={styles.button}>
                  {t('inventory.packagingMaterial.buttons.reset')}
                </Button>
              </Layout>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

const PackagingMaterialBulkUploadModal = observer(
  ({ contentContainerStyle, visible, onDismiss, onSubmit }) => {
    const onUploadCSV = async (data) => {
      const organisations = organisationStore.orgNameItems;
      const warehouses =
        warehousesStore.warehousesByAccountId[warehouseAccountStore.selectedWarehouseAccount.id];

      if (!warehouses?.length) {
        unifiedAlert(
          'No warehouses available for the selected service provider. Please select a different service provider.',
        );
        return;
      }

      if (!organisations?.length) {
        unifiedAlert('No organisations available. Please ensure you have access to organisations.');
        return;
      }

      const validationSchema = Yup.object({
        material: Yup.string().required('Material is required'),
        type: Yup.string().required('Type is required'),
        weight: Yup.number()
          .required()
          .min(0, 'Too small')
          .max(Number.MAX_SAFE_INTEGER, 'Too large'),
        length: Yup.number()
          .required()
          .min(0, 'Too small')
          .max(Number.MAX_SAFE_INTEGER, 'Too large'),
        width: Yup.number()
          .required()
          .min(0, 'Too small')
          .max(Number.MAX_SAFE_INTEGER, 'Too large'),
        height: Yup.number()
          .required()
          .min(0, 'Too small')
          .max(Number.MAX_SAFE_INTEGER, 'Too large'),
        thickness: Yup.number()
          .required()
          .min(0, 'Too small')
          .max(Number.MAX_SAFE_INTEGER, 'Too large'),
        quantity: Yup.number()
          .integer()
          .required()
          .min(0, 'Too small')
          .max(Number.MAX_SAFE_INTEGER, 'Too large'),
        price: Yup.number().required().min(0, 'Must be greater than 0'),
        barcode: Yup.string()
          .nullable()
          .min(3, 'Must be longer than 3 characters')
          .matches(/[a-zA-Z0-9]+/, ({ label }) => `${label} only allow letters and number.`),
        'warehouse code': warehouses
          ? Yup.string()
              .required()
              .oneOf((warehouses || []).map((w) => w.warehouse_code))
          : undefined,
        'organisation id': organisations
          ? Yup.string().oneOf((organisations || []).map((org) => org.id))
          : undefined,
      });

      const validatedData = await Promise.all(
        data.map(async (row, index) => {
          try {
            await validationSchema.validate(row);
            return {
              material: row.material,
              type: row.type,
              weight: parseFloat(row.weight) * 1000, // Convert to grams
              length: parseFloat(row.length),
              width: parseFloat(row.width),
              height: parseFloat(row.height),
              thickness: parseFloat(row.thickness),
              quantity: parseInt(row.quantity),
              price: parseFloat(row.price),
              barcode: row.barcode,
              warehouse_code: row['warehouse code'],
              organisationId: parseInt(row['organisation id']),
              warehouseAccountId: warehouseAccountStore.selectedWarehouseAccount?.id,
            };
          } catch (error) {
            alert(`Row ${index + 1}: ${error.message}`);
          }
        }),
      );

      await onSubmit(validatedData);
    };

    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          <CsvUploader onDismiss={onDismiss} onSubmit={onUploadCSV} sheetIndices={[0]} />
          <Button
            appearance="ghost"
            style={styles.button}
            onPress={() =>
              Linking.openURL(require('../../spreadsheetTemplates/PackagingMaterialTemplate.xlsx'))
            }>
            Download Template
          </Button>
        </Modal>
      </Portal>
    );
  },
);

export default observer(({ navigation }) => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  // Refetch data when screen is focused
  React.useEffect(() => {
    if (isFocused) {
      packagingMaterialTableStore.fetchItems();
    }
  }, [isFocused]);

  let packaingMaterialModalId = null;
  const hidePackagingMaterialModal = () => ModalService.hide(packaingMaterialModalId);

  const showAddPackagingMaterialModal = React.useCallback(
    (warehouseAccount) => {
      const warehouses = warehousesStore.warehousesByAccountId[warehouseAccount.id] || [];
      const contentElement = renderPackagingMaterialFormElement(
        warehouses,
        organisationStore.orgNameItems,
        {},
        hidePackagingMaterialModal,
        async (values) => {
          try {
            await packagingMaterialTableStore.addItem({
              ...values,
              weight: parseFloat(values.weight) * 1000,
              length: parseFloat(values.length),
              width: parseFloat(values.width),
              height: parseFloat(values.height),
              thickness: parseFloat(values.thickness),
              quantity: parseInt(values.quantity),
              price: parseInt(values.price),
              warehouseAccountId: warehouseAccount.id,
            });
            hidePackagingMaterialModal();
            setTimeout(() => packagingMaterialTableStore.fetchItems(), 1000);
          } catch (e) {
            unifiedAlert(e.message);
          }
        },
      );
      packaingMaterialModalId = ModalService.show(contentElement, {
        onBackdropPress: hidePackagingMaterialModal,
        backdropStyle: {
          backgroundColor: ezTheme.backdropModalColor,
        },
      });
    },
    [warehousesStore.warehousesByAccountId, organisationStore.orgNameItems],
  );

  const showUpdatePackagingMaterialModal = React.useCallback(
    (item) => {
      const contentElement = renderPackagingMaterialFormElement(
        null,
        null,
        item,
        hidePackagingMaterialModal,
        async (values) => {
          try {
            await packagingMaterialTableStore.updateItem({
              ...values,
              weight: parseFloat(values.weight),
              length: parseFloat(values.length),
              width: parseFloat(values.width),
              height: parseFloat(values.height),
              thickness: parseFloat(values.thickness),
              quantity: parseInt(values.quantity),
              price: parseInt(values.price),
              warehouseAccountId: item.warehouseAccountId,
            });
            hidePackagingMaterialModal();
            setTimeout(() => packagingMaterialTableStore.fetchItems(), 1000);
          } catch (e) {
            unifiedAlert(e.message);
          }
        },
      );
      packaingMaterialModalId = ModalService.show(contentElement, {
        onBackdropPress: hidePackagingMaterialModal,
        backdropStyle: {
          backgroundColor: ezTheme.backdropModalColor,
        },
      });
    },
    [warehousesStore.warehousesByAccountId, organisationStore.orgNameItems],
  );

  return (
    <Background fullWidth={true}>
      <PackagingMaterialTableControl
        filterParams={packagingMaterialTableStore.filterParams}
        initialSearchTerm={packagingMaterialTableStore.searchTerm}
        indicators={getIndicators(packagingMaterialTableStore.filterParams)}
        onFilterClear={() => {
          packagingMaterialTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          packagingMaterialTableStore.setFilterParams({
            ...packagingMaterialTableStore.filterParams,
            ...params,
          });
        }}
        disableSearch={packagingMaterialTableStore.loading}
        requireButtonClickToSearch={packagingMaterialTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: packagingMaterialTableStore.error,
            indeterminate: packagingMaterialTableStore.loading,
          }}>
          <Table
            styleByKey={{ id: { minWidth: 180 } }}
            items={packagingMaterialTableStore.items}
            getId={getId}
            displayKeys={packagingMaterialTableStore.DISPLAY_KEYS}
            sort="single"
            defaultSortOption={packagingMaterialTableStore.sortOption}
            onSortChange={packagingMaterialTableStore.setSortOption}
            heightOffset={300}
            titleByKey={packagingMaterialTableStore.TITLE_BY_KEY}
            formatterByKey={packagingMaterialTableStore.FORMATTER_BY_KEY}
            isFabVisible={useIsFocused()}
            currentPage={packagingMaterialTableStore.currentPage}
            onPageChange={(page) => {
              packagingMaterialTableStore.setCurrentPage(page);
            }}
            totalItemNum={packagingMaterialTableStore.total}
            itemsPerPage={packagingMaterialTableStore.pageSize}
            fabActions={(selectedIds) => {
              const actions = [];
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'file-upload-outline',
                  label: t('inventory.packagingMaterial.actions.addBatch'),
                  onPress: () => packagingMaterialTableStore.openCsvModal(),
                });
                actions.push({
                  icon: 'plus',
                  label: t('inventory.packagingMaterial.actions.add'),
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      showAddPackagingMaterialModal(warehouseAccountStore.selectedWarehouseAccount);
                    } else {
                      packagingMaterialTableStore.setOnWarehouseSelectionSubmit(
                        (warehouseAccount) => {
                          showAddPackagingMaterialModal(warehouseAccount);
                        },
                      );
                      packagingMaterialTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
                actions.push({
                  icon: 'download-outline',
                  label: t('inventory.packagingMaterial.actions.export.all'),
                  onPress: () => {
                    const ics = packagingMaterialTableStore.items.map(
                      ({ __typename, ...fields }) => ({
                        ...fields,
                      }),
                    );
                    const csvHeaders = ics?.length > 0 ? Object.keys(ics[0]) : [];
                    exportCsv(ics, csvHeaders, 'packaging_materials.csv');
                  },
                });
              }

              if (selectedIds.size === 1) {
                actions.push({
                  icon: 'pencil',
                  label: t('inventory.packagingMaterial.actions.update'),
                  onPress: () => {
                    showUpdatePackagingMaterialModal(
                      selectedItems(packagingMaterialTableStore.items, selectedIds)[0],
                    );
                  },
                });
              }

              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'download-outline',
                  label: t(`inventory.packagingMaterial.actions.export.selected.${selectedIds.size > 1 ? 'multiple' : 'single'}`),
                  onPress: () => {
                    const ics = selectedItems(packagingMaterialTableStore.items, selectedIds);
                    const exportedIcs = ics.map(({ __typename, ...fields }) => ({
                      ...fields,
                    }));
                    const csvHeaders = exportedIcs?.length > 0 ? Object.keys(exportedIcs[0]) : [];
                    exportCsv(exportedIcs, csvHeaders, 'packaging_materials.csv');
                  },
                });

                actions.push({
                  icon: 'trash-can-outline',
                  label: t(`inventory.packagingMaterial.actions.delete.${selectedIds.size > 1 ? 'multiple' : 'single'}`),
                  onPress: () => {
                    packagingMaterialTableStore.itemsToDelete = selectedIds;
                    packagingMaterialTableStore.openDeleteDialogue();
                  },
                });
              }

              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>

      <Portal>
        <Modal
          visible={packagingMaterialTableStore.deleteDialogueOpen}
          onDismiss={packagingMaterialTableStore.closeDeleteDialogue}
          contentContainerStyle={styles.modalStyle}>
          <Dialogue
            onSubmit={async () => {
              try {
                await Promise.all(
                  selectedItems(
                    packagingMaterialTableStore.items,
                    packagingMaterialTableStore.itemsToDelete,
                  ).map((task) =>
                    packagingMaterialTableStore.deleteItem(task.warehouseAccountId, task.id),
                  ),
                );
              } catch (e) {
                unifiedAlert(e.message);
              }
              setTimeout(() => packagingMaterialTableStore.fetchItems(), 1000);
              packagingMaterialTableStore.closeDeleteDialogue();
            }}
            onDismiss={packagingMaterialTableStore.closeDeleteDialogue}>
            <Text>{t('inventory.packagingMaterial.messages.deleteConfirmation')}</Text>
            <List
              data={selectedItems(
                packagingMaterialTableStore.items,
                packagingMaterialTableStore.itemsToDelete,
              )}
              renderItem={({ item }) => <ListItem title={`${item.id}`} />}
            />
          </Dialogue>
        </Modal>
      </Portal>

      <PackagingMaterialBulkUploadModal
        contentContainerStyle={styles.modalStyle}
        visible={packagingMaterialTableStore.csvModalOpen}
        onDismiss={packagingMaterialTableStore.closeCsvModal}
        onSubmit={async (packagingMaterials) => {
          const results = await Promise.allSettled(
            packagingMaterials.map(async (material, index) => {
              await new Promise((res) => setTimeout(res, 1000 * index));
              await packagingMaterialTableStore.addItem(material);
            }),
          );

          unifiedAlert(
            results
              .map((r, i) => ({
                message: `Material ${i + 1}: ${r.status === 'rejected' ? r.reason : 'created.'}`,
                ...r,
              }))
              .map((r) => r.message)
              .join('\n'),
          );

          setTimeout(() => packagingMaterialTableStore.fetchItems(), 500);
          packagingMaterialTableStore.closeCsvModal();
        }}
      />

      <WarehouseAccountSelectionModal
        visible={packagingMaterialTableStore.warehouseSelectionVisible}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
          packagingMaterialTableStore.onWarehouseSelectionSubmit(warehouseAccount);
          packagingMaterialTableStore.setWarehouseSelectionVisible(false);
        }}
        onCancel={() => packagingMaterialTableStore.setWarehouseSelectionVisible(false)}
      />
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', margin: 20 },
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
  formContainer: {
    overflowY: 'auto',
  },
});
