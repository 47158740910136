import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StripePaymentButton } from './StripePaymentButton';

export default function CheckoutForm({ clientSecret: clientSecretFromParent }) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecretFromURL = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecretFromURL) {
      return;
    } else if (clientSecretFromParent && clientSecretFromURL !== clientSecretFromParent) {
      // user started another payment intent. Ignore the client secret from URL.
      return;
    }

    stripe.retrievePaymentIntent(clientSecretFromURL).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage(t('userAccount.checkout.paymentSucceeded'));
          break;
        case 'processing':
          setMessage(t('userAccount.checkout.paymentProcessing'));
          break;
        case 'requires_payment_method':
          setMessage(t('userAccount.checkout.paymentFailed'));
          break;
        default:
          setMessage(t('userAccount.checkout.paymentError'));
          break;
      }
    });
  }, [stripe, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/a/account-balance`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage(t('userAccount.checkout.unexpectedError'));
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id="payment-form">
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      />

      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <StripePaymentButton
        title={t('userAccount.checkout.payNow')}
        loading={isLoading}
        disabled={isLoading || !stripe || !elements}
        onPress={handleSubmit}
        style={{ marginTop: '2em' }}
      />

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
