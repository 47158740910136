import { ScrollView, StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import BatchTrackingShipmentSelection from './BatchTrackingShipmentSelection';
import { ezTheme } from '../../core/theme';
import React, { memo } from 'react';

const BatchTrackingShipmentSelectionModal = ({ onSubmit, onClose, visible }) => {
  return (
    <Portal>
      <Modal
        style={styles.container}
        visible={visible}
        onDismiss={onClose}
        dismissable={true}
        contentContainerStyle={styles.modalContent}>
        <ScrollView style={{ height: '100%', width: '100%' }}>
          <BatchTrackingShipmentSelection onSubmit={onSubmit} onClose={onClose} />
        </ScrollView>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {},
  backdropStyle: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    width: '80%',
    height: '90%',
    borderRadius: 5,
    marginHorizontal: 'auto',
  },
});

export default memo(BatchTrackingShipmentSelectionModal);
