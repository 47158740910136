import React, { memo } from 'react';
import Background from 'src/components/Background';
import Logo from 'src/components/Logo';
import Header from 'src/components/Header';
import Button from 'src/components/Button';
import Paragraph from 'src/components/Paragraph';
import { useKeycloak } from '@react-keycloak/web';
import { Linking } from 'react-native';
import { useTranslation } from 'react-i18next';

const HomeScreen = ({ navigation }) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const sloganHighlightedCharacter = (
    <Paragraph>
      <strong>E</strong>a<strong>z</strong>y <strong>O</strong>rder <strong>M</strong>anagement
    </Paragraph>
  );

  return (
    <Background>
      <Logo />
      <Header>EzOM</Header>
      {sloganHighlightedCharacter}
      <Button
        mode="contained"
        onPress={() => {
          const url = keycloak.createLoginUrl();

          Linking.canOpenURL(url).then((supported) => {
            if (supported) {
              Linking.openURL(url);
            } else {
              console.log(t('auth.home.DoNotknowOpenURI') + url);
            }
          });
        }}>
        {t('auth.home.signInOrSignUp')}
      </Button>
      <small>
        <a href="https://hello.ezom.app">{t('auth.home.aboutUs')}</a>
      </small>
    </Background>
  );
};

export default memo(HomeScreen);
