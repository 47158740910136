import { useMutation } from '@apollo/client';
import { Card as KittenCard } from '@ui-kitten/components/ui/card/card.component';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { StyleSheet } from 'react-native';
import * as Yup from 'yup';
import { TextInputField } from '../../components/input/TextInputField';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';
import { Header } from './shared';
import { StripePaymentButton } from './StripePaymentButton';
// import { initStripe } from '@stripe/stripe-react-native';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import CheckoutForm from './CheckoutForm';

const INITIATE_TRANSACTION = gql`
  mutation InitiateTransaction($amount: Float!) {
    initiateTransaction(amount: $amount) {
      clientSecret
      charge
    }
  }
`;

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
});

const stripePromise = loadStripe(process.env.STRIPE_API_PUBLIC_KEY);

const INITIAL_AMOUNT = 1000;
export const InitiateTransaction = () => {
  const { t } = useTranslation();
  const [initiateTransaction, initiateTransactionResult] = useMutation(INITIATE_TRANSACTION);
  const [status, setStatus] = React.useState(new UIStatus());
  const [clientSecret, setClientSecret] = React.useState();
  const [chargeAmount, setChargeAmount] = React.useState();

  return (
    <KittenCard
      style={styles.card}
      header={(props) => (
        <Header
          {...props}
          heading={
            clientSecret
              ? t('userAccount.initiateTransaction.chargeAmount', { amount: chargeAmount })
              : t('userAccount.initiateTransaction.heading')
          }
        />
      )}>
      <UIStatusWrapper
        status={
          initiateTransactionResult.error
            ? status.setError(initiateTransactionResult.error)
            : initiateTransactionResult.data && status
            ? status.setIndeterminate(false)
            : status
        }
        loadingDataMsg={t('userAccount.initiateTransaction.loading')}>
        {clientSecret ? (
          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: 'stripe',
              },
            }}
            stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        ) : (
          <Formik
            onSubmit={(values, resetForm) => {
              setStatus((p) => p.setIndeterminate(true));

              initiateTransaction({
                variables: {
                  amount: Number(values.amount),
                },
              }).then(({ data }) => {
                setClientSecret(data.initiateTransaction.clientSecret);
                setChargeAmount(data.initiateTransaction.charge);
              });
            }}
            validationSchema={Yup.object({
              amount: Yup.number().integer().min(process.env.MIN_PAYMENT_AMOUNT).required(),
            })}
            initialValues={{
              amount: INITIAL_AMOUNT,
            }}
            enableReinitialize>
            {(formProps) => {
              return (
                <React.Fragment>
                  <TextInputField
                    name="amount"
                    label={t('userAccount.initiateTransaction.amountLabel')}
                    style={styles.field}
                    {...formProps}
                  />

                  <p>
                    {t('userAccount.initiateTransaction.feeDescription', {
                      feePercent: process.env.STRIPE_FEE_PERCENT,
                      feeFixed: process.env.STRIPE_FEE_FIXED,
                    })}
                  </p>

                  <StripePaymentButton
                    title={t('userAccount.initiateTransaction.initiateButton')}
                    onPress={() => formProps.handleSubmit()}
                    style={{ marginTop: '2em' }}
                  />
                </React.Fragment>
              );
            }}
          </Formik>
        )}
      </UIStatusWrapper>
    </KittenCard>
  );
};
