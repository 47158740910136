import { gql, useQuery } from '@apollo/client';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { Layout } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { flatten, isNil, merge } from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { Avatar, Card, Divider, FAB, Text } from 'react-native-paper';
import Background from 'src/components/Background';
import InlineTable from '../../components/InlineTable';
import SkuInfoEditModal from '../../components/SkuInfoEditModal';
import { UIStatusWrapper } from '../../components/ui-status';
import { useIsMobile } from '../../core/responsive.utils';
import { paperNativeTheme } from '../../core/theme';
import { formatInventoryLocation, unifiedAlert, useIsWmsManager } from '../../core/utils/utils';
import { inventoryStore } from '../../store';

const SKU_QUERY = gql`
  query inventories($warehouseAccountIds: [ID!]!, $skuCodes: [String!]) {
    inventories(warehouseAccountIds: $warehouseAccountIds, skuCodes: $skuCodes) {
      inventories {
        warehouseAccountId
        product_code
        uom
        sku_id
        sku_name
        sku_code
        warehouse_code
        saleable_stock
        pending_stock
        available_stock
        onway_stock
        customer_code
        logistics_package
        width
        length
        height
        weight
        remark
        uom
        declare_country_list {
          export_country
          export_port
          export_declare
          export_declare_value
          export_currency
          country
          import_port
          import_declare
          hs_code
          export_hscode
          declare_value
          currency
          risk
        }
        batches {
          batch_no
          available_stock
          pending_stock
          onway_stock
          stockAge {
            warehouse_code
            stock_quality
            consignment_no
            warehouse_stock
            pending_stock
            batch_no
            inventory_age
            putaway_time
          }
        }
      }
    }
  }
`;

const WMS_MANAGER_SKU_QUERY = gql`
  query inventories($warehouseAccountIds: [ID!]!, $skuCodes: [String!]) {
    inventories(warehouseAccountIds: $warehouseAccountIds, skuCodes: $skuCodes) {
      inventories {
        warehouseAccountId
        product_code
        uom
        sku_id
        sku_name
        sku_code
        warehouse_code
        saleable_stock
        pending_stock
        available_stock
        onway_stock
        customer_code
        logistics_package
        width
        length
        height
        weight
        remark
        uom
        declare_country_list {
          export_country
          export_port
          export_declare
          export_declare_value
          export_currency
          country
          import_port
          import_declare
          hs_code
          export_hscode
          declare_value
          currency
          risk
        }
        batches {
          batch_no
          available_stock
          pending_stock
          onway_stock
          stockAge {
            warehouse_code
            stock_quality
            consignment_no
            warehouse_stock
            pending_stock
            batch_no
            inventory_age
            putaway_time
            location_aisle
            location_unit
            location_shelf
            location_section
            container_id
          }
        }
      }
    }
  }
`;

const SkuDetailScreen = observer(({ navigation, route }) => {
  const { t } = useTranslation();

  const keyInfos = [
    { label: t('inventory.skuDetail.skuId'), key: 'sku_id' },
    { label: t('inventory.skuDetail.skuCode'), key: 'sku_code' },
    { label: t('inventory.skuDetail.skuName'), key: 'sku_name' },
    { label: t('inventory.skuDetail.productCode'), key: 'product_code' },
    { label: t('inventory.skuDetail.length'), key: 'length' },
    { label: t('inventory.skuDetail.width'), key: 'width' },
    { label: t('inventory.skuDetail.height'), key: 'height' },
    { label: t('inventory.skuDetail.uom'), key: 'uom' },
    { label: t('inventory.skuDetail.weight'), key: 'weight', format: weightGramToKg(true) },
    { label: t('inventory.skuDetail.warehouseCode'), key: 'warehouse_code' },
    { label: t('inventory.skuDetail.includeBattery'), key: 'include_battery' },
    { label: t('inventory.skuDetail.note'), key: 'remark' },
    { label: t('inventory.skuDetail.saleableStock'), key: 'saleable_stock' },
    { label: t('inventory.skuDetail.pendingStock'), key: 'pending_stock' },
    { label: t('inventory.skuDetail.availableStock'), key: 'available_stock' },
    { label: t('inventory.skuDetail.onwayStock'), key: 'onway_stock' },
  ];

  const otherInfos = [
    { label: t('inventory.skuDetail.appearance'), key: 'appearance' },
    { label: t('inventory.skuDetail.wrapping'), key: 'wrapping' },
    { label: t('inventory.skuDetail.characteristic'), key: 'characteristic' },
    {
      label: t('inventory.skuDetail.logisticsPackage'),
      key: 'logistics_package',
      format: (v) => (v ? 'Yes' : 'No'),
    },
  ];

  const decodedId = (route?.params?.id && decodeURIComponent(route?.params?.id)) || null;
  const [detail, setDetail] = useState(null);
  if (route.params.id !== decodedId) {
    navigation.setParams({ id: decodedId });
    return null;
  }

  const { id, warehouseAccountId } = route.params || {};
  const isWmsManager = useIsWmsManager();
  const { loading, error, refetch } = useQuery(isWmsManager ? WMS_MANAGER_SKU_QUERY : SKU_QUERY, {
    variables: {
      warehouseAccountIds: [warehouseAccountId],
      skuCodes: [id],
    },
    // The next two lines are required to make sure onComplted is triggered on refetch
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDetail(data.inventories?.inventories?.[0]);
    },
  });
  const stockAgeColumnByKey = merge(
    {
      batch_no: { title: 'Batch number' },
      putaway_time: {
        title: 'Putaway time',
        formatter: (key, item) => new Date(Number(item[key])).toLocaleString(),
      },
      inventory_age: { title: 'Age (days)' },
      stock_quality: { title: 'Quality' },
      warehouse_stock: { title: 'Available stock' },
      pending_stock: { title: 'Pending stock' },
      warehouse_code: { title: 'Warehouse' },
    },
    isWmsManager
      ? {
          location_aisle: {
            title: 'Location',
            formatter: (_, item) => formatInventoryLocation(item),
          },
          container_id: {
            title: 'Container',
          },
        }
      : {},
  );

  const isMobile = useIsMobile();

  const [skuInfoEditModalVisible, setSkuInfoEditModalVisible] = React.useState(false);

  const [fabOpen, setFabOpen] = React.useState(false);

  const fabActions = React.useMemo(() => {
    const actions = [
      {
        icon: 'file-edit-outline',
        label: t('inventory.skuDetail.updateSkuInfo'),
        onPress: () => setSkuInfoEditModalVisible(true),
      },
    ];
    return actions;
  }, [t]);

  return (
    <Background fullWidth={true}>
      <UIStatusWrapper
        status={{
          error,
          empty: !detail,
          indeterminate: loading,
        }}>
        <ScrollView style={styles.container}>
          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title={t('inventory.skuDetail.commonInfo')}
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {keyInfos.slice(0, Math.ceil(keyInfos.length / 2)).map(({ key, label, format }) => (
                  <RowDetail label={label} value={detail && detail[key]} format={format} />
                ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {keyInfos.slice(Math.ceil(keyInfos.length / 2)).map(({ key, label, format }) => (
                  <RowDetail label={label} value={detail && detail[key]} format={format} />
                ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title={t('inventory.skuDetail.otherInfo')}
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {otherInfos
                  .slice(0, Math.ceil(otherInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {otherInfos
                  .slice(Math.ceil(otherInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
            </Card.Content>
          </Card>
          {detail?.declare_country_list ? (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              <Card.Title
                title={t('inventory.skuDetail.importationInfo')}
                left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
              />
              <Divider />
              <Card.Content style={styles.content}>
                <InlineTable
                  items={detail.declare_country_list}
                  columnByKey={{
                    country: { title: t('inventory.skuDetail.exportTo') },
                    currency: { title: t('inventory.skuDetail.importDeclareCurrency') },
                    declare_value: { title: t('inventory.skuDetail.importDeclareValue') },
                    import_declare: { title: t('inventory.skuDetail.importDeclaration') },
                    hs_code: { title: t('inventory.skuDetail.importHsCode') },
                    export_country: { title: t('inventory.skuDetail.exportFrom') },
                    export_currency: { title: t('inventory.skuDetail.exportDeclareCurrency') },
                    export_declare_value: { title: t('inventory.skuDetail.exportDeclareValue') },
                    export_declare: { title: t('inventory.skuDetail.exportDeclaration') },
                    export_hscode: { title: t('inventory.skuDetail.exportHsCode') },
                  }}
                />
              </Card.Content>
            </Card>
          ) : null}
          {detail?.batches ? (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              <Card.Title
                title={t('inventory.skuDetail.batchInfo')}
                left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
              />
              <Divider />
              <Card.Content style={styles.content}>
                <InlineTable
                  items={detail.batches}
                  columnByKey={{
                    batch_no: { title: t('inventory.skuDetail.batchNumber') },
                    available_stock: { title: t('inventory.skuDetail.availableStock') },
                    pending_stock: { title: t('inventory.skuDetail.pendingStock') },
                    onway_stock: { title: t('inventory.skuDetail.onwayStock') },
                  }}
                />
              </Card.Content>
            </Card>
          ) : null}
          {detail?.batches[0]?.stockAge ? (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              <Card.Title
                title={t('inventory.skuDetail.stockAge')}
                left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
              />
              <Divider />
              <Card.Content style={styles.content}>
                <InlineTable
                  items={flatten(detail.batches.map((b) => b.stockAge))}
                  columnByKey={stockAgeColumnByKey}
                />
              </Card.Content>
            </Card>
          ) : null}
        </ScrollView>
        <FAB.Group
          visible={true}
          isFabVisible={true}
          fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
          open={fabOpen}
          icon={fabOpen ? 'chevron-down' : 'chevron-up'}
          actions={fabActions}
          onStateChange={({ open }) => setFabOpen(open)}
        />
        <SkuInfoEditModal
          visible={skuInfoEditModalVisible}
          setVisible={setSkuInfoEditModalVisible}
          isWmsManager={isWmsManager}
          sku={detail}
          onSubmit={async (values) => {
            try {
              await inventoryStore.modifySkuInfo({
                warehouseAccountId: detail.warehouseAccountId,
                skuId: detail.sku_id,
                ...(isWmsManager && {
                  length: values.length,
                  width: values.width,
                  height: values.height,
                  weight: values.weight,
                  product_code: values.product_code,
                  uom: values.uom,
                }),
                declare_country_list: values.declare_country_list,
              });
              setSkuInfoEditModalVisible(false);
              refetch();
            } catch (e) {
              unifiedAlert(t('inventory.skuDetail.error') + e.message);
            }
          }}
        />
      </UIStatusWrapper>
    </Background>
  );
});

const RowDetail = ({ label, value, format }) => (
  <Layout style={styles.desktopRow}>
    <Text
      style={[
        styles.cell,
        {
          width: '33%',
        },
      ]}>
      <strong>
        <i>{label}</i>
      </strong>
    </Text>
    <Text
      style={[
        styles.cell,
        {
          width: '66%',
          color: isNil(value) && '#DDD',
        },
      ]}>
      {isNil(value) ? 'N/A' : (format && format(value)) || value}
    </Text>
  </Layout>
);

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
    marginVertical: '1em',
  },

  desktopCard: {
    marginHorizontal: '0.8em',
  },

  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  menuListIcons: {
    margin: 0,
    alignItems: 'start',
  },

  desktopRow: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: '1em',
    justifyContent: 'space-between',
    minHeight: '20px',
  },

  mobileRow: {
    flex: 1,
    flexDirection: 'col',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginVertical: '1em',
  },

  cell: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: '0.2em',
  },

  desktopColumn: {
    width: '50%',
    flex: 1,
    flexDirection: 'column',
  },

  mobileColumn: {
    width: '100%',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  container: {
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'auto',
  },
});

export default SkuDetailScreen;
